import { FC } from "react";

import classes from "./ProjectDealStages.module.scss";
import { CheckCirleIconPurple } from 'components/Icons/CheckCirleIconPurple';
import { PurpleTimeIcon } from 'components/Icons/PurpleTimeIcon';
import { PurpleEmptyIcon } from 'components/Icons/PurpleEmptyIcon';

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import {CancelledIcon} from "../../../components/Icons/CancelledIcon";
import {PausedIcon} from "../../../components/Icons/PausedIcon";
import {useTranslation} from "react-i18next";
import {DEAL_STAGES} from "../../../constants/dealStatuses";
import { DownloadIcon } from "components/Icons/DownloadIcon";

interface Props {
    project: any
}

export const ProjectsDealStagesContainer: FC<Props> = observer(({ project }) => {
  const projectAllStage = project?.deal_stages;
  const { t, i18n } = useTranslation();

  const stageArray = [
        {'name': t('Project Analysis'), 'help_text': t('We are analyzing your project to offer the best solution and pricing')},
        {'name': t('Briefing'), 'help_text': t('Provide a briefing meeting for discussing project details')},
        {'name': t('Proposal creation'), 'help_text': t('We are working on a commercial proposal')},
        {'name': t('Proposal presented'), 'help_text': t('Presented the commercial proposal')},
        {'name': t('Negotiations'), 'help_text': t('Negotiating terms and conditions with the client')},
        {'name': t('Signing agreement'), 'help_text': t('Signing the agreement')},
        {'name': t('Agreement signed'), 'help_text': t('The agreement with the client has been signed')},
        {'name': t('Account assignment'), 'help_text': t('Assigning the project to the account team')},
        {'name': t('Onboarding'), 'help_text': t('Onboarding process')},
        {'name': t('Preparation for launch'), 'help_text': t('Preparing for the project launch')},
        {'name': t('Launched'), 'help_text': t('The project has been successfully launched')},
        {'name': t('Production'), 'help_text': t('The project has successfully started')},
        // {'name': t('Paused'), 'help_text': t('Project on pause')},
        // {'name': t('Cancelled'), 'help_text': t('Project cancelled')}
      ]

  const orderedStages = stageArray.map((stage: any) => {
    const findStage = projectAllStage.find((projectStage: any) => {
      return stage?.name.toLowerCase() === projectStage.name.toLowerCase()
    });
    if (findStage) {
        return findStage;
    } else {
        return stage
    }
  })

  const {PAUSED, CANCELLED, COMPLETED} = DEAL_STAGES

  // добавляем стадии паузы или отмены на место где была последняя активная стадия
  const pausedStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === PAUSED.toLowerCase())
  const cancelledStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === CANCELLED.toLowerCase())
  const completedStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === COMPLETED.toLowerCase())
  let lastIndex = -1;
  for (let i = 0; i < orderedStages.length; i++) {
    if (orderedStages[i].id !== undefined) {
        lastIndex = i;
    }
  }

  if (pausedStage) {
    orderedStages.splice(lastIndex + 1, 0, pausedStage);
  }

  if (cancelledStage) {
      let index = pausedStage ? 2 : 1
      orderedStages.splice(lastIndex + index, 0, cancelledStage);
  }

  if (completedStage) {
    orderedStages.splice(lastIndex + 1, 0, completedStage);
  }

  const formatDate = (dateString: string) => {
      const options: any = { year: 'numeric', month: 'short', day: '2-digit' };
      const date: Date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
  };

  const getStepIcon = (stage: any, stageType: string = '', id: number=0, planEndDate: string='', factEndDate: string='') => {
    const stageName = stage?.name;
    const helpText = stage?.description ? stage.description : stageArray.find((obj: any) => obj.name === stageName)?.help_text
    const projectFilePath = project.files.find((el: any) => el.deal_stage === id)?.file;
    const fileName = projectFilePath?.match(/\/files\/(.+)/)[1];
    const shortFileName = fileName?.length > 20 ? fileName?.slice(0, 20) + '...' : fileName

    const additionalStageIcon = (stageType: string, isTimeIcon?: boolean) => {
      const type = stageType.toLowerCase()
      if (type === PAUSED) {
        return <PausedIcon />
      } else if (type === CANCELLED) {
        return <CancelledIcon />
      } else if (isTimeIcon) {
        return <PurpleTimeIcon />
      } else {
        return <CheckCirleIconPurple />
      }
    }

    if (planEndDate && factEndDate) {
      return (
        <div className={classes.step}>
          <div className={clsx(classes.name, classes.past)}>
            <span className={classes.step__date}>{formatDate(factEndDate)}</span>
              {additionalStageIcon(stageType)}
            <div className={classes.step__block}>
              <span>{stageName}</span>
              <p>
                {helpText}
              </p>
                {projectFilePath && (
                  <a href={process.env.REACT_APP_API + projectFilePath} target='_blank' className={classes.map__link} title={fileName}>
                    {shortFileName}
                    <DownloadIcon />
                  </a>
              )}
            </div>
          </div>
        </div>
      )
    } else if (planEndDate && !factEndDate) {
      return (
        <div className={clsx(classes.step, classes.step_empty)}>
          <div className={clsx(classes.name, classes.past)}>
            <span className={classes.step__date}>{formatDate(planEndDate)}</span>
             {additionalStageIcon(stageType, true)}
            <div className={classes.step__block}>
              <span>{stageName}</span>
              <p>
                {helpText}
              </p>
                {projectFilePath && (
                  <a href={process.env.REACT_APP_API + projectFilePath} target='_blank' className={classes.map__link} title={fileName}>
                    {shortFileName}
                  </a>
              )}
            </div>
          </div>
        </div>
      )
    } else {
      return (
          <div className={clsx(classes.step, classes.step_empty)}>
            <div className={clsx(classes.name, classes.past)}>
            <span className={classes.step__date}></span>
               <PurpleEmptyIcon />
            <div className={classes.step__block}>
              <span>{stageName}</span>
              <p>
                {helpText}
              </p>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
      <div className={classes.map}>
        {orderedStages.map((el: any) => {
          if (el.id){
            return getStepIcon(el, el.type, el.id, el.plan_end_date, el.fact_end_date)
          } else {
            // return el.name !== 'Paused' && el.name !== 'Cancelled' ? getStepIcon(el.name) : null
            return getStepIcon(el)
          }
        })}
      </div>
  )


});
