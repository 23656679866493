import React, { useState } from "react";
import classes from './NeedConfirmPage.module.scss';
import {Type} from 'components/Type';
import {Button} from 'components/Button';
import letter from 'img/NeedConfirm/letter.png'
import {useUserStore} from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import {ShowNotification} from "../../tools/showNotification";

export const NeedConfirmPageContainer = () => {

    const [isSend, setIsSend] = useState(false)

    const {t} = useTranslation();

    const userStore = useUserStore()

    const onSendRegistrationCompleteEmail = async (e: any) => {
        e.preventDefault()
        if (!isSend) {
            setIsSend(true)
            const user = await userStore.loadUser()
            if (!user.email_confirmed) {
                userStore.sendRegistrationCompleteEmail()
                ShowNotification({type: "success", children: t("Email successfully sent. It may take up to 2 minutes to deliver")});
            } else {
                ShowNotification({type: "error", children: t("Your email is already confirmed")});
            }
            setIsSend(false)
        }
    }

    return (
        <div className={classes.wrap}>
            <div className={classes.inner}>
                <div className={classes.title}>
                    <Type tag="h2" size="lg" className={classes.text}>
                        {t("Please, confirm your email to get access to Platform")}
                    </Type>
                </div>
                <div className={classes.subtitle}>
                    <Type tag="h3" size="subtitle" className={classes.text}>
                        {t("If you haven’t received a confirmation email, request it here")}
                    </Type>
                </div>
                <div className={classes.button}>
                    <Button size='middle' theme='primary' onClick={onSendRegistrationCompleteEmail}>
                        {t("Send link")}
                    </Button>
                </div>
            </div>
            <div className={classes.img}>
                <img src={letter} alt="Box" className={classes.desktop}/>
            </div>
        </div>
    );
}