export const dealStatuses = [
  {
    id: 0,
    slug: 'CREATED',
    step: 1,
    name: 'Created',
    about: 'Sales manager is going to contact you shortly',
  },
  {
    id: 1,
    slug: 'ANALYSIS',
    step: 2,
    name: 'Source brief',
    about: 'We are analyzing your source to offer the best solution and pricing',
  },
  {
    id: 2,
    slug: 'PROPOSAL',
    step: 3,
    name: 'Proposal',
    about: 'We are working on a commercial proposal',
  },
  {
    id: 3,
    slug: 'DECISION',
    step: 4,
    name: 'Decision making',
    about: 'We are waiting for your closing decision',
  },
  {
    id: 4,
    slug: 'CLOSING',
    step: 5,
    name: 'Closing',
    about: 'We are preparing agreements for signing',
  },
  {
    id: 5,
    slug: 'PRODUCTION',
    step: 6,
    name: 'Production',
    about: 'The source has successfully started',
  },
  {
    id: 6,
    slug: 'CANCELLED',
    step: 0,
    name: 'Cancelled',
    about: 'Unfortunately, the deal was lost',
  }
]

export const DEAL_STAGES = {
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  PAUSED: 'paused',
  ALL: 'all',
  ACTIVE: 'active'
}

export type DealStageType = 'completed' | 'cancelled' | 'paused' | 'all' | 'active'
