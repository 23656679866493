import * as React from "react";
import { SVGProps } from "react";

export const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="6"
    height="11"
    viewBox="0 0 6 11"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.279245L0 1.33189C0 1.40347 0.0260417 1.47084 0.0687501 1.51295L4.14792 5.50038L0.0687501 9.48782C0.0260417 9.52993 0 9.59729 0 9.66887L0 10.7215C0 10.8128 0.0770835 10.8661 0.132292 10.8128L5.19583 5.8639C5.37917 5.68425 5.37917 5.31652 5.19583 5.13827L0.132292 0.189418C0.0770835 0.134681 0 0.188015 0 0.279245V0.279245Z"
      
    />
  </svg>
);
