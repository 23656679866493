import { FC, Suspense, useEffect } from 'react';
import { useLocation, Outlet } from "react-router-dom";
import { toast } from "react-toastify";

export interface AuthRouteProps {
  children?: any;
}

export const AuthRoute: FC<AuthRouteProps> = () => {

    const location = useLocation();

    useEffect(() => {
        toast.dismiss();
    }, [location])

    return (
        <Suspense fallback={<Outlet/>}>
          <Outlet/>
        </Suspense>
    )
}