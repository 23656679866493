import React, { FC, useState } from "react";
import classes from './Notice.module.scss';
import clsx from "clsx";
import { CheckCircleIcon } from "components/Icons/CheckCirleIcon";
import { UnionIcon } from "components/Icons/UnionIcon";
import { InfoIcon } from "components/Icons/InfoIcon";

interface Props {
  type: string;
  children: string;
  noticeClass?: any;
  noticeIconClass?: any;
  childrenClass?: any;
}

export const Notice: FC<Props> = ({
  type, children, noticeClass, childrenClass, noticeIconClass
}) => {

  return (
   <div className={clsx(classes.notice, classes[type], noticeClass)}>
    <div className={clsx(classes.icon, noticeIconClass)}>
      {type === 'success' && <CheckCircleIcon fill={'#00C853'} width={'100%'} height={'100%'}/>}
      {type === 'warning' && <InfoIcon fill={'#F3CA37'} width={'100%'} height={'100%'}/>}
      {type === 'error' && <UnionIcon fill={'#F5222D'} width={'100%'} height={'100%'}/>}
    </div>
    <span className={clsx(classes.text, childrenClass)}>{children}</span>
   </div>
  );
};
