export interface IPagination {
  currentPage: number;
  totalPages: number;
  showPagesNearby?: number;
  simbol?: number | string;
}

export const pagination = ({
  currentPage,
  totalPages,
  showPagesNearby = 2,
  simbol = '...',
}: IPagination): (number | string)[] => {
  let range: (number | string)[] = [];

  if (totalPages <= 7) {
    for (let i = 1; i <= totalPages; i++) {
      range.push(i);
    }
  } else {
    if (currentPage === 1 || currentPage === totalPages) {
      range = [1, 2, simbol, Math.ceil(totalPages / 2), simbol, totalPages - 1, totalPages]
    } else if (currentPage === Math.ceil(totalPages / 2)) {
      range = [1, simbol, currentPage - 1, currentPage, currentPage + 1, simbol, totalPages]
    } else if (currentPage > 3 && currentPage < totalPages - 2) {
      range = [1, simbol, currentPage - 1, currentPage, currentPage + 1, simbol, totalPages]
    } else if (currentPage >= 2 && currentPage <= 3) {
      range = [1, 2, 3, 4, simbol, totalPages - 1, totalPages]
    } else if (currentPage >= totalPages - 2 && currentPage <= totalPages - 1) {
      range = [1, 2, simbol, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]
    }
  }

  return range;
};
