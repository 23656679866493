import { useCookies } from "react-cookie";


const useCookieConsent = (cookieGroupVarname: string) => {
    const [cookies] = useCookies();
    if (Object.hasOwn(cookies, 'cookie_consent') == false) {
        return false
    }
    else {
        const cookieStr = cookies.cookie_consent
        const cookieGroups = cookieStr.split('|')
        for (const group of cookieGroups) {
            const splittedGroup = group.split('=')
            const groupName = splittedGroup[0]
            const groupValue = splittedGroup[1]
            if (groupName === cookieGroupVarname) {
                if (groupValue === '-1') {
                    return false
                }
                else {
                    return true
                }
            }
        }
        return false
    }
}

export default useCookieConsent;