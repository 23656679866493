import * as React from "react"
import { SVGProps } from "react"

export const GiftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.188 6.055h-2.883c.265-.418.422-.914.422-1.446A2.692 2.692 0 0 0 10 2.84a2.692 2.692 0 0 0-4.727 1.77c0 .532.155 1.028.422 1.446H2.812a.624.624 0 0 0-.624.625v3.906c0 .086.07.156.156.156h.781v6.719c0 .346.28.625.625.625h12.5c.346 0 .625-.28.625-.625v-6.719h.781c.086 0 .157-.07.157-.156V6.68a.624.624 0 0 0-.625-.625Zm-6.524-1.446c0-.754.613-1.367 1.367-1.367s1.367.613 1.367 1.367-.613 1.368-1.367 1.368h-1.367V4.609ZM7.97 3.242c.754 0 1.367.613 1.367 1.367v1.368H7.969a1.369 1.369 0 0 1-1.367-1.368c0-.754.613-1.367 1.367-1.367ZM3.516 9.414V7.383h5.82v2.031h-5.82Zm.937 1.328h4.883v6.016H4.453v-6.016Zm11.094 6.016h-4.883v-6.016h4.883v6.016Zm.937-7.344h-5.82V7.383h5.82v2.031Z"
    />
  </svg>
)
