import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import clsx from 'classnames';

import classes from './Card.module.scss';
import { ProjectsDealStagesContainer } from '../DealStage';
import FormatPrice from '../../../components/FormatPriceAndCurrency';
import { DEAL_STAGES } from '../../../constants/dealStatuses';
import ArrowUpRight from '../../../components/Icons/ArrowUpRight';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { API_URL } from '../../../services/http';
import i18n from 'i18n/i18n';
import { toJS } from 'mobx';

interface Props {
  project: any;
}

export const ProjectCard: FC<Props> = ({ project }) => {
  const { t } = useTranslation();

  const active_tags = [
    'ACTIVE',
    'CREATED',
    'ANALYSIS',
    'PROPOSAL',
    'DECISION',
    'CLOSING',
    'PRODUCTION',
    t('Cancelled'),
    t('Paused'),
    t('Completed'),
  ];

  const { COMPLETED, CANCELLED, PAUSED } = DEAL_STAGES;
  const isTablet = useWindowWidth().isMediaTablet;

  const status = String(project?.status).toLowerCase();
  const firstCapitalStatus =
    project?.status?.charAt(0)?.toUpperCase() +
    project?.status?.slice(1)?.toLowerCase();
  let tagClass = '';
  let tagText = '';
  const source =
    project?.source?.name &&
    (project?.source?.name?.includes('http')
      ? project?.source?.name
      : `https://${project?.source?.name}`);

  switch (status) {
    case PAUSED:
      tagClass = classes.card__tag_yellow;
      break;
    case COMPLETED:
      tagClass = classes.card__tag_green;
      break;
    case CANCELLED:
      tagClass = classes.card__tag_red;
      break;
    default:
      tagClass = active_tags?.includes(project?.status)
        ? classes.card__tag_purple
        : '';
      break;
  }

  tagText = t(firstCapitalStatus).toUpperCase();

  const shortSource =
    source?.length > (isTablet ? 35 : 60)
      ? source?.slice(0, isTablet ? 35 : 60) + '...'
      : source;

  return (
    <div className={classes.card}>
      <div className={classes.card__top_mobile}>
        {tagClass && (
          <div className={clsx(classes.card__tag, tagClass)}>{tagText}</div>
        )}
        <NavLink to={`/projects/${project.id}`} className={classes.card__link}>
          <h3 className={classes.card__header}>
            {project[`name_${i18n.language}`] ?? project.name}
          </h3>
        </NavLink>
        <div className={classes.card__top__bottom}>
          <div className={classes.card__id}>ID {project.number}</div>
          {source && (
            <span className={classes.card__top__bottom__separate}></span>
          )}
          {source && (
            <NavLink
              to={source}
              target='_blank'
              className={classes.card__source__mobile}
            >
              {shortSource}
              <ArrowUpRight
                className={classes.card__arrowIcon}
                fill='#8362F3'
              />
            </NavLink>
          )}
        </div>
      </div>
      <div className={classes.card__description}>
        <div className={classes.card__top}>
          <NavLink
            to={`/projects/${project.id}`}
            className={classes.card__link}
          >
            <h3 className={classes.card__header}>{project[`name_${i18n.language}`] ?? project.name}</h3>
          </NavLink>
          <div className={classes.card__tags}>
            {tagClass && (
              <div className={clsx(classes.card__tag, tagClass)}>{tagText}</div>
            )}
            <div className={classes.card__id}>ID {project.number}</div>
          </div>
        </div>
        {source && (
          <div>
            <NavLink
              className={classes.card__source}
              to={source}
              target='_blank'
            >
              {shortSource}
              <ArrowUpRight
                className={classes.card__arrowIcon}
                fill='#8362F3'
              />
            </NavLink>
          </div>
        )}
      </div>

      <div className={classes.card__grid}>
        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>{t('Stages')}</div>

          <div className={classes.map}>
            <div className={classes.step}>
              <ProjectsDealStagesContainer project={project}/>
            </div>
          </div>
        </div>


        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            {t('Project cost')}
          </div>
          <div className={classes.card__grid__card__coast}>
            {project.cost?.reduce((currentValue: number, coast: any) => {
              const isFree = project.solution.is_free;
              return (
                <FormatPrice
                  currency={project.currency.symbol}
                  price={isFree ? 0 : Number(coast.cost) + currentValue}
                  withMinus={false}
                />
              );
            }, 0)}
          </div>
          <div className={classes.card__grid__card__text}>
            {t(
              'Please note! The project cost is subject to change based on work scope and ad budget'
            )}
          </div>
        </div>
        <div
          className={clsx(
            classes.card__grid__card,
            classes.card__grid__card_manager
          )}
        >
          {project.manager ? (
            <>
              {!project.manager.image ? (
                <img
                  src={`${API_URL}/static/core/img/default_manager_image.png`}
                  alt=''
                  className={classes.card__grid__card__img}
                />
              ) : (
                <img
                  src={project.manager.image}
                  alt=''
                  className={classes.card__grid__card__img}
                />
              )}
              <div className={classes.card__grid__card_manager_header}>
                <h4 className={classes.card__grid__card__title}>
                  {project.manager.name}
                </h4>
                <div className={classes.card__grid__card__header}>
                  {t('Your personal manager')}
                </div>
              </div>
            </>
          ) : (
            <div className={classes.card__grid__card_manager_header}>
              <img
                src={`${API_URL}/static/core/img/default_manager_image.png`}
                alt=''
                className={classes.card__grid__card__img}
              />
              <p className={classes.card__grid__card__par}>
                {t('Assigning manager')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
