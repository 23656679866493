import clsx from "clsx";
import { Button } from "components/Button";
import { FireIcon } from "components/Icons/FireIcon";
import { GiftIcon } from "components/Icons/GiftIcon";
import { SpeakerIcon } from "components/Icons/SpeakerIcon";
import PercentIcon from "components/Icons/PercentIcon";
import { ThunderIcon } from "components/Icons/ThunderIcon";
import { Modal } from "components/Modal";
import { Pagination } from "components/Pagination";
import request from "img/Marketplace/request.png";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { FormRequest } from "./FormRequest";
import { ProductCard } from "./ProductCard";
import classes from "./ProductList.module.scss";
import { useTranslation } from "react-i18next";
import { marketPlaceStore } from "../../../stores/marketPlaceStore";
import {
  defaultSortParams,
  options,
  optionsRu,
  queryParamsHandler,
} from "../utils";
import Lottie from "lottie-react";
import loader from "../../../components/PageLoader/loader.json";
import { TopFilterProps } from "../types";
import { userStore } from "../../../stores/userStore";
import Select from "../../../components/SelectComponent";
import ProductListSkeleton from "components/Skeleton/ProductListSkeleton/ProductListSkeleton";
import { RUSSIAN_LOCALE } from "utils";

interface Props {
  isLoading: boolean;
  defaultBudget: number | null;
  defaultPriceMin: number;
  data: any;
  setIsFree: (val: boolean) => void;
  setIsNew: (val: boolean) => void;
  setIsHot: (val: boolean) => void;
  setIsSale: (val: boolean) => void;
  setIsTopUp: (val: boolean) => void;
  hotDeals?: any;
  nameSource?: string;
  defaultCurrentPage: number;
  pageSize: number;
  topFilters: TopFilterProps;
  isFavorites?: boolean;
  defaultServiceTypes?: number[] | string[];
  defaultBusinessTypes?: number[] | string[];
  setPageSize: (val: number) => void;
  setCurrentPage: (val: number) => void;
  setProductsToShow?: (val: any) => void;
  enableHints?: boolean;
  sortSelect?: any;
  setSortSelect?: (val: any) => void;
  openSelect?: boolean;
  setOpenSelect?: (val: boolean) => void;
  defaultChunkSize?: number;
  setMoreButtonClicked: Dispatch<SetStateAction<boolean>>;
  moreButtonClicked: boolean;
  setPriceMin: Dispatch<SetStateAction<number>>;
  clearBusinessTypes?: boolean;
  isServicesLength: boolean;
  isFirstRender: boolean;
}

export const ProductList: FC<Props> = ({
  isLoading,
  data,
  defaultBudget,
  defaultPriceMin,
  setIsFree,
  setIsNew,
  setIsHot,
  setIsSale,
  setIsTopUp,
  hotDeals,
  nameSource,
  defaultCurrentPage,
  setCurrentPage,
  pageSize,
  defaultServiceTypes,
  defaultBusinessTypes,
  setPageSize,
  topFilters,
  isFavorites,
  enableHints,
  sortSelect,
  setSortSelect,
  openSelect,
  setOpenSelect,
  defaultChunkSize = 8,
  setMoreButtonClicked,
  moreButtonClicked,
  setPriceMin,
  clearBusinessTypes,
  setProductsToShow,
  isServicesLength,
  isFirstRender,
}) => {
  const [products, setProducts] = useState(data);
  const [chunks, setChunks] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [showMore, setShowMore] = useState(0);
  const { t } = useTranslation();
  const count = marketPlaceStore?.count;
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [favouriteServices, setFavoriteServices] = useState(
    marketPlaceStore?.isFavoriteServices,
  );

  const {
    defaultIsFree,
    defaultIsNew,
    defaultIsHot,
    defaultIsSale,
    defaultIsTopUp,
  } = topFilters;
  const { is_free, is_new, is_hot, is_sale, is_topup } =
    marketPlaceStore.isTopServices || {};
  const {
    is_free_favorite,
    is_hot_favorite,
    is_new_favorite,
    is_sale_favorite,
    is_topup_favorite,
  } = favouriteServices || {};
  const { sortingParams } = marketPlaceStore || {};
  const isFavoriteChanged = isFavorites && marketPlaceStore?.favoriteCount;

  const {
    savedCurrentPage,
    savedChunkSize,
    savedServiceTypes,
    savedBusinessTypes,
    isFavoritesPage,
    savedBudget,
    savedPriceMin,
    savedSortParams,
    isFree,
    isNew,
    isHot,
    isSale,
    isTopUp,
  } = (!isFavorites && sortingParams) || {};

  const currentPage = (!isFavorites && savedCurrentPage) || defaultCurrentPage;
  const chunkSize = (!isFavorites && savedChunkSize) || defaultChunkSize;
  const serviceTypes = savedServiceTypes || defaultServiceTypes;
  const businessTypes = savedBusinessTypes || defaultBusinessTypes;
  const budget = savedBudget || defaultBudget;
  const price_min = savedPriceMin || defaultPriceMin;
  const sortParams = savedSortParams || defaultSortParams(sortSelect);

  const freeFilter =
    isFavoritesPage || isFavorites ? is_free_favorite : is_free;
  const newFilter = isFavoritesPage || isFavorites ? is_new_favorite : is_new;
  const hotFilter = isFavoritesPage || isFavorites ? is_hot_favorite : is_hot;
  const saleFilter =
    isFavoritesPage || isFavorites ? is_sale_favorite : is_sale;
  const topUpFilter =
    isFavoritesPage || isFavorites ? is_topup_favorite : is_topup;

  useEffect(() => {
    if (typeof window !== "undefined" && !moreButtonClicked) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setFavoriteServices(marketPlaceStore?.isFavoriteServices);
  }, []);

  const handleClickMore = () => {
    setMoreButtonClicked(true);
    setDisableButton(isLoadingServicePages);
    if (nextPage) {
      const params = queryParamsHandler({
        page: currentPage + 1,
        page_size: chunkSize,
        serviceTypes: serviceTypes?.length && serviceTypes,
        businessTypes: businessTypes?.length && businessTypes,
        isFavorites: isFavoritesPage || isFavorites,
        budget: budget && budget,
        price_min: price_min && price_min,
        order: sortParams,
        isFree: isFree || defaultIsFree,
        isNew: isNew || defaultIsNew,
        isHot: isHot || defaultIsHot,
        isSale: isSale || defaultIsSale,
        isTopUp: isTopUp || defaultIsTopUp,
      });

      marketPlaceStore.loadServices(params, products).then(services => {
        setProducts([...products, ...services]);
        setNextPage(marketPlaceStore?.nextPage);
        setPrevPage(marketPlaceStore?.prevPage);
        setCurrentPage(currentPage + 1);
      });
    }
  };

  useEffect(() => {
    const moreCount = currentPage * (chunkSize + 1) - 1;
    if (currentPage === 1) {
      setPageSize(chunkSize);
    } else {
      setPageSize(moreCount);
    }
  }, [currentPage]);

  function changeChunks() {
    let newArray: any = [];
    if (!isLoading) {
      for (let i = 0; i < count; i += chunkSize) {
        const chunk = products.slice(i, i + chunkSize);
        newArray = [...newArray, chunk];
      }
    }
    return newArray;
  }

  useEffect(() => {
    setChunks(changeChunks());
  }, [products, showMore, isLoading]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setProducts(data);
  }, [data]);

  const { isLoadingServicePages } = marketPlaceStore;
  const { currency, language, country } = userStore.user || {};

  const params = queryParamsHandler({
    page: currentPage,
    page_size: chunkSize,
    serviceTypes: serviceTypes?.length && serviceTypes,
    businessTypes: businessTypes?.length && businessTypes,
    isFavorites: isFavoritesPage || isFavorites,
    budget: budget && budget,
    price_min: price_min && price_min,
    order: sortParams,
    isFree: isFree || defaultIsFree,
    isNew: isNew || defaultIsNew,
    isHot: isHot || defaultIsHot,
    isSale: isSale || defaultIsSale,
    isTopUp: isTopUp || defaultIsTopUp,
    clearBusinessTypes: clearBusinessTypes ? clearBusinessTypes : false,
  });

  useEffect(() => {
    if (
      currentPage !== defaultCurrentPage ||
      price_min !== defaultPriceMin ||
      defaultSortParams(sortSelect) !== sortParams
    )
      return;

    !moreButtonClicked &&
      !userStore.localeChanged &&
      marketPlaceStore.loadServices(params).then((services: any) => {
        setProducts(services);
        setNextPage(marketPlaceStore?.nextPage);
        setPrevPage(marketPlaceStore?.prevPage);
      });
  }, [
    params,
    // moreButtonClicked,
    currentPage,
    currency?.name,
    language?.id,
    isFavoriteChanged,
    sortSelect?.id,
  ]);

  useEffect(() => {
    if (userStore.localeChanged) {
      marketPlaceStore.loadServices(params).then(services => {
        setProducts(services);
        setNextPage(marketPlaceStore?.nextPage);
        setPrevPage(marketPlaceStore?.prevPage);
      });
      setCurrentPage(1);
    }
    userStore.setLocaleChanged(false);
  }, [currency?.name, language?.id, country.id]);

  const handleNextPrevPage = (isNext: boolean) => {
    setMoreButtonClicked(false);
    if (isNext && nextPage) {
      setCurrentPage(currentPage + 1);
    } else if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const checkFilter = (filter?: boolean, defaultFilter?: boolean) => {
    if (isFavorites) {
      return defaultFilter;
    } else return filter || defaultFilter;
  };

  const isLoadingServices = marketPlaceStore.isLoadingServicePages;

  if (
    (isLoading && data.length === 0) ||
    isLoadingServices ||
    !isServicesLength ||
    isFirstRender
  )
    return <ProductListSkeleton />;

  return (
    <div className={classes.wrapper}>
      <div className={classes.sorting}>
        <div className={classes.left}>
          {freeFilter && (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (isLoadingServices) return;

                setPriceMin(0);
                setShowMore(0);
                setIsFree(!(isFree || defaultIsFree));
                setMoreButtonClicked(false);
                !showMore && setCurrentPage(1);
              }}
              className={clsx(
                classes.filter,
                !checkFilter(isFree, defaultIsFree) && classes.free,
                checkFilter(isFree, defaultIsFree) && classes["is-free"],
              )}
            >
              <GiftIcon /> {t("Free")}
            </div>
          )}

          {newFilter && (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (isLoadingServices) return;

                setPriceMin(0);
                setShowMore(0);
                setIsNew(!(isNew || defaultIsNew));
                setMoreButtonClicked(false);
                !showMore && setCurrentPage(1);
              }}
              className={clsx(
                classes.filter,
                !checkFilter(isNew, defaultIsNew) && classes.new,
                checkFilter(isNew, defaultIsNew) && classes["is-new"],
              )}
            >
              <ThunderIcon /> {t("New")}
            </div>
          )}

          {hotFilter && (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (isLoadingServices) return;

                setPriceMin(0);
                setShowMore(0);
                setIsHot(!(isHot || defaultIsHot));
                setMoreButtonClicked(false);
                !showMore && setCurrentPage(1);
              }}
              className={clsx(
                classes.filter,
                !checkFilter(isHot, defaultIsHot) && classes.hot,
                checkFilter(isHot, defaultIsHot) && classes["is-hot"],
              )}
            >
              <FireIcon /> {t("Hot deal")}
            </div>
          )}

          {saleFilter && (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (isLoadingServices) return;

                setPriceMin(0);
                setShowMore(0);
                setIsSale(!(isSale || defaultIsSale));
                setMoreButtonClicked(false);
                !showMore && setCurrentPage(1);
              }}
              className={clsx(
                classes.filter,
                !checkFilter(isSale, defaultIsSale) && classes.sale,
                checkFilter(isSale, defaultIsSale) && classes["is-sale"],
              )}
            >
              <PercentIcon /> {t("Sale")}
            </div>
          )}

          {topUpFilter && (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (isLoadingServices) return;

                setPriceMin(0);
                setShowMore(0);
                setIsTopUp(!(isTopUp || defaultIsTopUp));
                setMoreButtonClicked(false);
                !showMore && setCurrentPage(1);
              }}
              className={clsx(
                classes.filter,
                !checkFilter(isTopUp, defaultIsTopUp) && classes.topup,
                checkFilter(isTopUp, defaultIsTopUp) && classes["is-topup"],
              )}
            >
              <SpeakerIcon /> {t("Top up")}
            </div>
          )}
        </div>

        <Select
          setSelect={setSortSelect}
          select={sortSelect}
          options={RUSSIAN_LOCALE ? optionsRu(t) : options(t)}
          title={RUSSIAN_LOCALE ? undefined : t("Sort by")}
          open={openSelect}
          setOpen={setOpenSelect}
          onSelect={() => {
            setMoreButtonClicked(false);
            !showMore && setCurrentPage(1);
          }}
        />
      </div>

      <div className={classes.list}>
        {
          <>
            {products?.map(
              (item: any, index: number) =>
                item.is_active && (
                  <ProductCard
                    key={`${item.id}-${index}`}
                    data={item}
                    hotDeals={hotDeals}
                    nameSource={nameSource}
                  />
                ),
            )}

            <div className={classes["request-card"]}>
              <div className={classes.top}>
                <img src={request} alt="" className={classes.image} />
                <div className={classes.title}>
                  {t("Looking for something else")}?
                </div>
                <div className={classes.text}>
                  {t(
                    "Tell us what solution you are looking for and we will send a proposal for you",
                  )}
                </div>
              </div>
              <div className={classes.bottom}>
                <Button
                  theme="light"
                  className={classes.button}
                  onClick={handleShowModal}
                >
                  {t("Send request_productList")}
                </Button>
              </div>
              <Modal
                title={""}
                isOpen={showModal}
                onClose={handleCloseModal}
                isBasicHeader={false}
              >
                <div className={classes.form}>
                  <FormRequest onClose={handleCloseModal} />
                </div>
              </Modal>
            </div>
          </>
        }
      </div>

      {enableHints && (
        <div className={classes.pagination}>
          {chunks.length !== currentPage && nextPage && (
            <Button
              onClick={handleClickMore}
              theme="light"
              className={classes.button}
              disabled={disableButton}
            >
              {t("See more")}
            </Button>
          )}

          {(nextPage || prevPage) && (
            <Pagination
              currentPage={currentPage + showMore / chunkSize}
              totalPages={chunks.length}
              setPageNumber={setCurrentPage}
              nextPage={() => handleNextPrevPage(true)}
              prevPage={() => handleNextPrevPage(false)}
              extraAction={() => {
                setShowMore(0);
                setMoreButtonClicked(false);
              }}
            />
          )}
        </div>
      )}

      {!enableHints &&
        products?.length > 0 &&
        (isLoadingServicePages ? (
          <div className={classes.loaderWrapper}>
            <Lottie animationData={loader} />
          </div>
        ) : (
          <div className={classes.pagination}>
            {chunks.length !== currentPage && nextPage && (
              <Button
                onClick={handleClickMore}
                theme="light"
                className={classes.button}
                disabled={disableButton}
              >
                {t("See more")}
              </Button>
            )}

            {(nextPage || prevPage) && (
              <Pagination
                currentPage={currentPage + showMore / chunkSize}
                totalPages={chunks.length}
                setPageNumber={setCurrentPage}
                nextPage={() => handleNextPrevPage(true)}
                prevPage={() => handleNextPrevPage(false)}
                extraAction={() => {
                  setShowMore(0);
                  setMoreButtonClicked(false);
                }}
              />
            )}
          </div>
        ))}
    </div>
  );
};
