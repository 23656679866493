
import { useUserStore } from "../contexts/UserContext";

export const useHotDeals = (t: any) => {
  const userStore = useUserStore();

  const getHotDealText = (hotDeal: any, test?: any) => {

    let hotDealText = "Hot Deal";
    if (hotDeal?.cost_change_type === 'GIFT') {
      hotDealText = `${Number(hotDeal?.cost_change_value) - 1}+1=${Number(hotDeal?.cost_change_value) - 1}`;
    } else if (hotDeal?.cost_change_type === 'PERC') {
      hotDealText = `${t('SAVE')} ${~~hotDeal?.cost_change_value}%`;
    } else if (hotDeal?.cost_change_type === 'MONEY') {
      let hotDealAmount = Math.floor(Number(test?.cost)) - Math.floor(Number(test?.cost_with_discounts));
      hotDealText = `${hotDealAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`

    }
    return hotDealText
 
  }

  return {
    getHotDealText
  }
}
