import React, { FC, ReactNode } from "react";
import classes from "./Button.module.scss";
import clsx from "clsx";

export interface ButtonProps {
  theme?: "default" | "primary" | "text" | "icon-default" | "icon-primary" | "light";
  size?: "small" | "middle" | "big";
  children?: ReactNode;
  onClick?: (val: any) => void;
  disabled?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  label?: string;
}

export const Button: FC<ButtonProps> = ({
  className: additionalClassName,
  size,
  theme,
  children,
  onClick,
  disabled,
  label,
  type
}) => {
  const className = clsx(
    classes.btn,
    theme && classes[theme],
    size && classes[size],
    additionalClassName
  );

  return (
    <>
      <button
        className={className}
        disabled={disabled}
        onClick={onClick}
        type={type}
        aria-label={label}
      >
        {children}
      </button>
    </>
  );
};