import { FC, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Button } from 'components/Button';
import { HeartIcon } from 'components/Icons/HeartIcon';
import { FilledHeartIcon } from 'components/Icons/FilledHeartIcon';
import useFavorites from 'hooks/useFavorites';

import { WhiteFireIcon } from 'components/Icons/WhiteFireIcon';
import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon';
import { TooltipContainer } from 'components/Tooltip/TooltipContainer';
import { InfoIcon } from 'components/Icons/InfoIcon';
import classes from './ProductCard.module.scss';
import { observer } from 'mobx-react-lite';
import { useHotDeals } from 'hooks/useHotDeals';

import { useTranslation } from 'react-i18next';
import { useUserStore } from 'contexts/UserContext';
import AddToCartButton from '../../../../components/Button/AddToCartButton';
import FormatPrice from '../../../../components/FormatPriceAndCurrency';
import PriceText from '../../../../components/PriceText';
import { toJS } from 'mobx';

interface Props {
  data: any;
  isHit?: boolean;
  isSmall?: boolean;
  hotDeals?: {
    activeDeals: boolean;
    featureDeals: boolean;
  };
  nameSource?: string;
}

export const ProductCard: FC<Props> = observer(
  ({ data, isHit = false, isSmall = false, hotDeals }) => {
    const [isFavorite, setIsFavorite] = useState(data?.is_favorite);
    const userStore = useUserStore();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { getHotDealText } = useHotDeals(t);
    const [hotDealDiscountText, setHotDealDiscountText] = useState('');

    const changeFavorites = useFavorites(
      data,
      isFavorite,
      setIsFavorite,
      navigate
    );

    const deals = data.discounts.find((item: any) => item.type === 'HOT_DEAL');

    const dateDeal = {
      dayEnd: 0,
      monthEnd: '',
      yearEnd: 0,
      active: false,
    };

    if (deals) {
      const utcDate = new Date(`${deals.deal_end_date}`);
      dateDeal.dayEnd = utcDate.getDate();
      dateDeal.monthEnd = utcDate.toLocaleString(i18n.language, {
        month: 'long',
      });
      dateDeal.yearEnd = utcDate.getFullYear();
      dateDeal.active = true;
    }

    const checkDiscount =
      data?.has_active_discounts &&
      Number(data.cost) !== Number(data.cost_with_discounts)
        ? Number(data.cost_with_discounts)
        : Number(data.cost);

    const checkCurrency = () => {
      return (
        userStore.user.currency.char_code === 'IDR' ||
        userStore.user.currency.char_code === 'INR'
      );
    };

    useEffect(() => {
      setHotDealDiscountText(getHotDealText(deals, data));
    }, [deals, data]);

    const formattedPrice = (price?: number | string, withMinus?: boolean) => (
      <FormatPrice
        currency={userStore.user?.currency?.symbol}
        price={price}
        withMinus={withMinus}
      />
    );

    return (
      <div
        className={clsx(
          classes.wrapper,
          isHit && classes.hit,
          isSmall && classes.small,
          hotDeals &&
            hotDeals.activeDeals &&
            !hotDeals.featureDeals &&
            deals &&
            classes.hit
        )}
      >
        <NavLink to={`/solutions/${data.slug}`} className={classes.link} />
        <div className={classes.top}>
          <div className={classes.head}>
            <div className={classes.tags}>
              {hotDeals &&
                hotDeals.activeDeals &&
                !hotDeals.featureDeals &&
                deals && (
                  <div className={clsx(classes.tag, classes['hit-tag'])}>
                    <WhiteFireIcon />
                    <span className={classes.tagInner}>
                      {deals.cost_change_type === 'MONEY' &&
                        formattedPrice(undefined, true)}
                      {hotDealDiscountText}
                    </span>
                  </div>
                )}
              {data?.is_new && (
                <div className={clsx(classes.tag, classes.new)}>{t('NEW')}</div>
              )}
              {data?.is_free && (
                <div className={clsx(classes.tag, classes.free)}>
                  {t('FREE')}
                </div>
              )}
              <div className={classes.tag}>
                {data.product[`name_${i18n.language}`] ?? data.product.name}
              </div>
            </div> 
          </div>
          <div className={classes.inner}>

            <div className={classes.title}>
              {data[`name_${i18n.language}`] ?? data.name}
            </div>
            {!isSmall && (
              <div className={classes.text}>
                {data[`short_description_${i18n.language}`] ??
                  data.short_description}
              </div>

            )}

            {dateDeal.active &&
              !isSmall &&
              hotDeals &&
              !hotDeals.featureDeals &&
              deals && (
                <div>
                  <div className={classes.tooltipWrap}>
                    <span className={classes.closeTimeInner}>
                      {t('Closing time')}:{' '}
                      <span className={classes.closeTime}>
                        {dateDeal.dayEnd} {dateDeal.monthEnd} {dateDeal.yearEnd}{' '}
                      </span>
                      <div className={classes.closeTimeTooltip}>
                        <TooltipContainer
                          text={t(
                            'Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled'
                          )}
                          position={'top'}
                          className={classes.tooltipContainer}
                          classNameTooltip={classes.tooltip}
                        >
                          <InfoIcon fill='#fff' width='18px' height='18px' />
                        </TooltipContainer>
                      </div>
                    </span>
                  </div>
                </div>
              )}
          </div>
          <div className={classes.read}>
            <div className={classes.readText}>{t('Read more')} </div>
            <div className={classes.readIcon}>
              <ArrowRightIcon fill='#8362F3' />
            </div>
          </div>
        </div>
        <div className={classes.bottom}>
          {!data?.is_free ? (
            <div className={classes.price}>
              <span className={classes.price__tips}>
                <PriceText data={data} t={t} />
              </span>
              <div className={classes.price__wrapper}>
                <span
                  className={clsx(classes.price__currency, checkCurrency())}
                >
                  {formattedPrice(checkDiscount)}
                </span>
                {data?.has_active_discounts &&
                  Number(data?.cost) !== Number(data?.cost_with_discounts) && (
                    <span
                      className={clsx(
                        classes?.price__discount,
                        checkCurrency() && classes['price__discount--small']
                      )}
                    >
                      {formattedPrice(data?.cost)}
                    </span>
                  )}
              </div>
            </div>
          ) : (
            <div className={classes['price-free']}>
              <span>{t('Free')}</span>
            </div>
          )}
          {
            <div className={classes['mobile-buttons']}>
              <AddToCartButton data={data} />
              <div className={classes.favored}>
                <Button
                  className={classes['mobile-button']}
                  theme='icon-default'
                  onClick={changeFavorites}
                >
                  {isFavorite ? <FilledHeartIcon /> : <HeartIcon />}
                </Button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
);
