import * as React from "react"
import { SVGProps } from "react"

export const CardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M20.3571 4.95H3.64286C3.26014 4.95 2.95 5.25628 2.95 5.63636V18.3636C2.95 18.7437 3.26014 19.05 3.64286 19.05H20.3571C20.7399 19.05 21.05 18.7437 21.05 18.3636V5.63636C21.05 5.25628 20.7399 4.95 20.3571 4.95ZM4.49643 6.48182H19.5036V8.76818H4.49643V6.48182ZM19.5036 17.5182H4.49643V10.6182H19.5036V17.5182ZM14.7924 16.3455H18.1071C18.2227 16.3455 18.3179 16.252 18.3179 16.1364V14.7045C18.3179 14.589 18.2227 14.4955 18.1071 14.4955H14.7924C14.6769 14.4955 14.5817 14.589 14.5817 14.7045V16.1364C14.5817 16.252 14.6769 16.3455 14.7924 16.3455Z" stroke-width="0.1" />
  </svg>
);