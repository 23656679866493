import * as React from "react"
import { SVGProps } from "react"

export const PurpleArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.137 7.46501C12.5052 7.46501 12.8037 7.76348 12.8037 8.13167C12.8037 8.49986 12.5052 8.79834 12.137 8.79834H5.74659L8.41831 11.4701C8.67866 11.7304 8.67866 12.1525 8.41831 12.4129C8.15796 12.6732 7.73585 12.6732 7.4755 12.4129L3.19458 8.13195L7.47551 3.85102C7.73586 3.59067 8.15797 3.59067 8.41831 3.85102C8.67866 4.11137 8.67866 4.53348 8.41831 4.79383L5.74714 7.465L12.137 7.46501Z"/>
  </svg>
)