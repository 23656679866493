import Lottie from "lottie-react";
import { FC } from 'react';
import classes from './PageLoader.module.scss';
import loader from "./loader.json";

export interface PageLoaderProps {
    children?: any;
}

export const PageLoader: FC<PageLoaderProps> = ({ children }) => {
  return (
      <div className={classes.loaderWrapper}>
        <div className={classes['page-loader']}>
          <Lottie animationData={loader} />
        </div>
        {children}
      </div>
    )
}

