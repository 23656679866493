export const langSelector = (name: string, i18n: any) => {
	return `${name}_${i18n.language}`?.length ? `${name}_${i18n.language}` : name
};

export const getLangName = (parent: any, child: string, i18n: any) => {
	return parent?.[langSelector(child, i18n)] || parent?.[child]
}

//Делает подсчет и выводит правильное окончание. Как пример нужно передать слова 1 - "книга", 2 - "книги", 5 - "книг"
export const declOfNum = (
	num: number,
	titles: [string, string, string],
  ): string => {
	const cases = [2, 0, 1, 1, 1, 2];
	return titles[
	  num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
	];
  };
  