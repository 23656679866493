import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import classes from './ProjectGoals.module.scss';
import { useTranslation } from 'react-i18next';
import User from 'services/user';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../../stores/userStore';
import _ from 'lodash';
import { pushToDataLayer } from '../../../tools/analytics';
import {getLangName, langSelector} from "../../../tools/utils";
import i18n from 'i18n/i18n';

interface Props {
  project: any;
  updateProject: any;
  tabsId?: string;
}

interface SystemGoal {
  name: string;
  id?: number;
}

export const ProjectGoalsContainer: FC<Props> = observer(
  ({ project, updateProject, tabsId }) => {
    const { t, i18n } = useTranslation();
    const editableDivRef = useRef<HTMLInputElement>(null);
    const [projectGoals, setProjectGoals] = useState<SystemGoal[]>(
      project.goals
    );
    const [systemProjectGoals, setSystemProjectGoal] = useState<SystemGoal[]>(
      project.project_system_goals
    );
    const [addAnotherGoal, setAddAnotherGoal] = useState<boolean>(false);
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [newGoal, setNewGoal] = useState<SystemGoal | null>(null);
    const [projectId, setProjectId] = useState<number>();
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const userLocale = userStore.user?.language?.lang_code;
    const maxLength = 40;
    const goalName = (goal: any) => getLangName(goal, 'name',i18n )

    useEffect(() => {
      if (newGoal) {
        setProjectId(project.id);
      }
      if (!newGoal) {
        setIsFocused(false);
      }
    }, [newGoal, project.id, projectId]);

    useEffect(() => {
      userStore.loadProjectData(project?.id);
    }, []);

    // Функция для сохранения очередности при смене локали и добавлении новых целей
    /*const updateGoals = (updatedGoals: SystemGoal[]) => {
    const updatedGoalsMap = new Map(updatedGoals.map(goal => [goal.id, goal]));

    const newProjectGoals = projectGoals?.map(goal => {
      if (updatedGoalsMap.has(goal?.id)) {
        return updatedGoalsMap.get(goal?.id);
      }
      return goal;
    });

    updatedGoals.forEach(updatedGoal => {
      if (!projectGoals.some(goal => goal.id === updatedGoal.id)) {
        newProjectGoals.push(updatedGoal);
      }
    });

    setProjectGoals(newProjectGoals as SystemGoal[])
  };*/

    useEffect(() => {
      updateProject().then((project: any) => {
        setSystemProjectGoal(project?.data?.project_system_goals);
        setProjectGoals(project?.data?.goals);
      });
    }, [userLocale]);

    const handleAddAnotherGoal = () => {
      handleGoal(project.id, newGoal);
      setNewGoal(null);
      setAddAnotherGoal(false);
      setIsFocused(false);
      setProjectId(undefined);
    };

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        handleAddAnotherGoal();
      }
    };

    const icon = () => (
      <svg
        onClick={() => {
          setNewGoal(null);
          setAddAnotherGoal(false);
          setIsFocused(false);
          setProjectId(undefined);
        }}
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M8.00016 3.33301V12.6663M3.3335 7.99967H12.6668'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
    const handleNonInputClick = (event: any) => {
      if (event.target.closest(`#${tabsId}`)) return;
      if (
        !event.target.closest('input') &&
        !event.target.closest('svg') &&
        projectId &&
        isFocused
      ) {
        event.preventDefault();
        handleAddAnotherGoal();
      } else if (
        !event.target.closest('input') &&
        !event.target.closest('svg') &&
        !isFocused &&
        !goalName(newGoal)
      ) {
        event.preventDefault();
        setNewGoal(null);
        setAddAnotherGoal(false);
        setProjectId(undefined);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleNonInputClick);
      return () => {
        document.removeEventListener('click', handleNonInputClick);
      };
    }, [newGoal, projectId, isFocused, handleNonInputClick]);

    const checkGoals = (goalsArray: SystemGoal[], goal: SystemGoal) =>
      _.findIndex(goalsArray, (o) => {
        return _.isMatch(o, goal);
      }) > -1;

    const handleGoal = async (
      projectId: number,
      goal: any,
      removed: boolean = false
    ) => {
      setIsClicked(true);
      const finalGoal = goal?.goal ? goal.goal : goal;
      try {
        if (removed) {
          User.deleteGoal(projectId, finalGoal?.id)
            .then(async (data) => {
              setSystemProjectGoal(data.project_system_goals);
              setProjectGoals(data.goals);
            })
            .catch(() => {
              updateProject();
            });
        }
        if (!removed && !checkGoals(projectGoals, finalGoal)) {
          User.updateProject(projectId, finalGoal?.name).then((data) => {
            setProjectGoals(data?.goals);
            setSystemProjectGoal(data?.project_system_goals);
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        updateProject();
      }
      setTimeout(() => setIsClicked(false), 500);
    };
    /*
  const updateProjectGoals = async (goal: SystemGoal, removed: boolean) => {
    if (!removed && !checkGoals(projectGoals, goal)){
        setProjectGoals((projectGoals: SystemGoal[]) => [...projectGoals, goal]);
      } else {
        const updatedProjectGoals = projectGoals.filter((projectGoal: SystemGoal) => projectGoal?.id !== goal?.id)
        setProjectGoals(updatedProjectGoals)

        if (checkGoals(AllSystemGoals, goal)){
          setSystemProjectGoal([...systemProjectGoals, goal])
        }
        return
      }

      if (checkGoals(systemProjectGoals, goal)){
        const updatedSystemGoals = systemProjectGoals.filter((systemGoal: SystemGoal) => systemGoal?.id !== goal?.id);
        setSystemProjectGoal(updatedSystemGoals);
      }
  };*/

    const getGoalChoice = (goals: SystemGoal[]) => {
      return goals.map((goal: any) => (
        <div
          className={classes.goals__item}
          onClick={() => {
            if (!isClicked) {
              handleGoal(project.id, goal);
              pushToDataLayer('addGoals', 'buttonClick', 'GAForms');
            }
          }}
        >
          <div>{goalName(goal)}</div>
          {icon()}
        </div>
      ));
    };
  //item[langSelector('name', i18n)]
    const getProjectGoals = (goals: any) => {
      return goals?.map((goal: any) => {
        const finalGoal = goal?.goal ? goal.goal : goal;
        return (
          <div
            className={clsx(classes.goals__item, classes.goals__item_active)}
            onClick={() => {
              handleGoal(project.id, goal, true);
              setIsClicked(true);
            }}
          >
            <div>{goalName(finalGoal)}</div>
            <span className={classes.goalLength}>
              {goalName(goal)?.length}/{maxLength}
            </span>
            {icon()}
          </div>
        );
      });
    };

    useEffect(() => {
      if (addAnotherGoal && editableDivRef.current) {
        editableDivRef.current.focus();
      }
    }, [addAnotherGoal]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value.length <= maxLength) {
        setNewGoal({ name: value });
        e.target.style.width = `${e.target.scrollWidth}px`;
      }
    };

    return (
      <div className={classes.goals}>
        <div className={classes.goals__main}>
          {getProjectGoals(projectGoals)}

          {addAnotherGoal && (
            <div
              className={clsx(classes.goals__item, classes.goals__item_active)}
              onKeyDown={handleKeyDown}
            >
              <input
                ref={editableDivRef}
                className={classes.input}
                value={goalName(newGoal)}
                maxLength={40}
                onChange={handleChange}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  !goalName(newGoal) && setIsFocused(false);
                }}
              />
              <span className={classes.goalLength}>
                {goalName(newGoal)?.length || 0}/{maxLength}
              </span>
              {icon()}
            </div>
          )}
          {projectGoals?.length > 0 && (
            <div
              className={clsx(classes.goals__item, classes.goals__item_add)}
              onClick={() => setAddAnotherGoal(true)}
            >
              <span>{t('Add another…')}</span>
              {icon()}
            </div>
          )}
          {projectGoals?.length <= 0 && !addAnotherGoal && (
            <div className={clsx(classes.goals__empty_block)}>
              <p className={clsx(classes.goals__empty_text)}>
                {t('Add goals so they appear here')}
              </p>
              <div
                className={clsx(classes.goals__empty_btn)}
                onClick={() => {
                  setAddAnotherGoal(true);
                }}
              >
                {t('Add goal')}
                {icon()}
              </div>
            </div>
          )}
        </div>

        {systemProjectGoals?.length > 0 && (
          <div className={classes.goals__bottom_text}>
            {t('Perhaps your goals are among these')}
          </div>
        )}
        <div className={classes.goals__bottom}>
          {getGoalChoice(systemProjectGoals)}
        </div>
      </div>
    );
  }
);
