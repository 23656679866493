import React, {FC, useEffect} from 'react';
import {Route, Outlet, useNavigate, Navigate} from "react-router-dom";
import {useAuthStore} from "../contexts/AuthContext";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import { CookieNotice } from './CookieNotice';
import { useCookies } from "react-cookie";

export interface RequiredAuthProps {
}


export const RedirectAuth: FC<RequiredAuthProps> = observer(() => {
    const authStore = useAuthStore();
    const navigate = useNavigate()
    const [cookies] = useCookies(['cookie_consent', ]);

    useEffect(() => {
        if (toJS(authStore).isAuth) {
            navigate(`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`, {replace: true})
        }
    }, [authStore.isAuth])

    if (toJS(authStore).isAuth) {
        return <Navigate to={`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`}  replace />
    }

    return (
        <>
            <Outlet/>
            {!cookies.cookie_consent && <CookieNotice />}
        </>
    )
})
