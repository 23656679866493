import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './LocalPreferences.module.scss';
import { useWindowWidth } from 'hooks/useWindowWidth';

import { Grid, GridItem } from '../../Grid';
import { Button } from 'components/Button';
import { Notice } from 'components/Notice';
import { MultiselectComponent } from 'components/MultiselectComponent';
import { Controller, useForm } from 'react-hook-form';
import { toJS } from 'mobx';

interface Props {
  onClose: any;
  onSave: any;
  success: boolean;
  disabled: boolean;
  setIsDisabled?: any;
  onlySaveButton?: boolean;
  noticeText: string;
  countries: [];
  currencies: [];
  languages: any;
  currentLanguage: any;
  currentCountry: any;
  currentCurrency: any;
}

interface FormValues {
  currency: string;
  country: string;
  language: string;
}

export const LocalPreferencesForm: FC<Props> = ({
  onClose,
  onSave,
  success,
  disabled,
  setIsDisabled,
  onlySaveButton,
  noticeText,
  countries,
  currencies,
  languages,
  currentLanguage,
  currentCountry,
  currentCurrency,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<FormValues>();
  const { isMediaTablet } = useWindowWidth();
  const [selectedCountry, setSelectedCountry] = useState<any>(currentCountry);
  const [selectedCurrency, setSelectedCurrency] =
    useState<any>(currentCurrency);
  const [selectedLanguage, setSelectedLanguage] =
    useState<any>(currentLanguage);

  const handleSave = () => {
    let requestData = {
      language: selectedLanguage.lang_code,
      country: { id: selectedCountry?.id || currentCountry?.id },
      currency: { id: selectedCurrency?.id || currentCurrency?.id },
    };
    onSave(requestData);
  };

  useEffect(() => {
    if (setIsDisabled) {
      if (
        (selectedCountry || currentCountry) &&
        selectedCurrency &&
        selectedLanguage
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [selectedCountry, selectedCurrency, selectedLanguage]);

  return (
    <form
      action=''
      className={classes.form}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className={classes.inner}>
        {!isMediaTablet && !onlySaveButton && (
          <div className={classes.title}>{t('Local preferences')}</div>
        )}
        <div className={classes['notice-container']}>
          <Notice
            type='warning'
            noticeClass={classes.notice}
            noticeIconClass={classes['notice-icon']}
            childrenClass={classes['notice-text']}
          >
            {noticeText}
          </Notice>
        </div>
        <Grid direction='row' className={classes.box}>
          <GridItem col={{ default: 12 }} className={classes.item}>
            <div className={classes['item-label']}>{t('Currency')}</div>
            <Controller
              render={({ field }) => (
                <MultiselectComponent
                  data={currencies}
                  defaultSelected={currentCurrency?.id}
                  placeholder='currency'
                  setMultiselect={setSelectedCurrency}
                  search={false}
                />
              )}
              name='currency'
              control={control}
            />
          </GridItem>
          <GridItem col={{ default: 12 }} className={classes.item}>
            <div className={classes['item-label']}>{t('Country')}</div>
            <Controller
              render={({ field }) => (
                <MultiselectComponent
                  data={countries}
                  defaultSelected={currentCountry?.id}
                  placeholder='country'
                  setMultiselect={setSelectedCountry}
                  search={true}
                />
              )}
              name='country'
              control={control}
            />
          </GridItem>
          <GridItem col={{ default: 12 }} className={classes.item}>
            <div className={classes['item-label']}>{t('Language')}</div>
            <Controller
              render={() => (
                <MultiselectComponent
                  data={languages}
                  defaultSelected={currentLanguage?.id}
                  placeholder='language'
                  setMultiselect={setSelectedLanguage}
                  search={false}
                />
              )}
              name='language'
              control={control}
            />
          </GridItem>
        </Grid>
        <div className={classes.bottom}>
          {!onlySaveButton && !isMediaTablet && (
            <Button
              theme='light'
              type='button'
              size='middle'
              className={classes.btn}
              onClick={onClose}
            >
              {t('Back')}
            </Button>
          )}
          <Button
            theme='primary'
            type='submit'
            size='middle'
            disabled={disabled}
            className={classes.btn}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </form>
  );
};
