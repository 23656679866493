import api from "services/http";

interface Props {
  type: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  data?: any;
  isProtected?: boolean;
  access?: string;
  body?: any
}

export async function Request({
  type,
  url = '',
  data,
  isProtected = false,
  access
}: Props) {
  const options = {
    url,
    data: data,
    method: type
  };

  const response = await api.request(options)

  return response && (response.status < 400 && response.status >= 200) ? response.data : response

}