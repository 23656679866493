import React, { FC, useEffect, useState } from 'react';

import { Input } from 'components/Input';
import { Link } from 'components/Link';
import { Button } from '../../../../components/Button';

import classes from './StepOne.module.scss';
import { CheckIcon } from 'components/Icons/CheckIcon';
import { KrestIcon } from 'components/Icons/KrestIcon';
import { EyeIcon } from 'components/Icons/EyeIcon';
import { PhoneInputMask } from 'components/PhoneInput';
import clsx from 'clsx';
import { TooltipContainer } from 'components/Tooltip/TooltipContainer';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from 'i18n/i18n';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  lettersCheckReg,
  numbersCheckReg,
  RegistrationStepOneProps,
  RegistrationStepTwoProps,
  symbolsCheckReg,
} from '../../../Account/validationSchemas';
import Service from "../../../../services/service";
import {getLangName} from "../../../../tools/utils";

interface Props {
  password: string;
  setPassword: (val: string) => void;
  setStep: (val: number) => void;
  passwordErrorDescription: string;
  setBusinessTypes: (val: any) => void;
  setCompanySizes: (val: any) => void;
  control: Control<RegistrationStepOneProps | RegistrationStepTwoProps>;
  errors: FieldErrors<RegistrationStepOneProps>;
  isValid: boolean;
}

export const StepOne: FC<Props> = ({
  password,
  setPassword,
  setStep,
  passwordErrorDescription,
  setBusinessTypes,
  setCompanySizes,
  control,
  errors,
  isValid,
}) => {
  const [lengthCheck, setLengthCheck] = useState(false);
  const [numbersCheck, setNumbersCheck] = useState(false);
  const [symbolsCheck, setSymbolsCheck] = useState(false);
  const [letterCheck, setLetterCheck] = useState(false);
  const [showChecks, setShowCheks] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [invSymbCheck, setInvSymbCheck] = useState(false);

  const { t, i18n } = useTranslation();
  const getBusinessTypes = async () => {
    try {
      const response = await Service.getBusinessTypes();
      const data = response?.data?.results?.map((item: any) => {
        return {
          id: item.id,
          name: getLangName(item, 'name', i18n),
        };
      })
      setBusinessTypes(data);
    } catch (error) {
      setBusinessTypes([]);
    }
  };

  const companySizesList = [
    {
      id: 'MICRO',
      name: t('Micro Business'),
      description: t('1-10 employees'),
      size: 1,
    },
    {
      id: 'SMALL',
      name: t('Small Business'),
      description: t('11-50 employees'),
      size: 2,
    },
    {
      id: 'MEDIUM',
      name: t('Medium-Sized Business'),
      description: t('51-250 employees'),
      size: 3,
    },
    {
      id: 'LARGE',
      name: t('Large Business'),
      description: t('251-1000 employees'),
      size: 4,
    },
    {
      id: 'CORPORATION',
      name: t('Corporation'),
      description: t('more than 1000 employees'),
      size: 5,
    },
  ];

  useEffect(() => {
    setCompanySizes(companySizesList);
  }, [i18n.language]);

  useEffect(() => {
    if (numbersCheckReg.test(password)) {
      setNumbersCheck(true);
    } else {
      setNumbersCheck(false);
    }
    if (symbolsCheckReg.test(password)) {
      setSymbolsCheck(true);
    } else {
      setSymbolsCheck(false);
    }
    if (lettersCheckReg.test(password)) {
      setLetterCheck(true);
    } else {
      setLetterCheck(false);
    }
    if (password.length >= 8) {
      setLengthCheck(true);
    } else {
      setLengthCheck(false);
    }
    if (!password.includes('.')) {
      setInvSymbCheck(true);
    } else {
      setInvSymbCheck(false);
    }
  }, [password, setNumbersCheck, setSymbolsCheck, setLengthCheck]);

  return (
    <>
      <div className={classes['top-fields']}>
        <div className={classes.fullName}>
          <Controller
            render={({ field }) => (
              <Input
                label={t('Full Name')}
                labelPosition={'left'}
                type={'text'}
                placeholder={t('Oliver Wilson')}
                value={field.value}
                error={!!errors?.fullName}
                maxLength={50}
                className={classes.register_theme}
                onChange={field.onChange}
              />
            )}
            name='fullName'
            control={control}
          />
          {errors?.fullName && (
            <TooltipContainer
              text={errors.fullName?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill='#F5222D' width='18px' height='18px' />
            </TooltipContainer>
          )}
        </div>
        <div className={classes.email}>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input
                label={t('Email')}
                labelPosition={'left'}
                type={'text'}
                placeholder={t('Enter your email')}
                className={clsx(classes.email, classes.register_theme)}
                value={field.value}
                error={!!errors?.email}
                onChange={field.onChange}
              />
            )}
          />
          {errors?.email && (
            <TooltipContainer
              text={errors.email?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill='#F5222D' width='18px' height='18px' />
            </TooltipContainer>
          )}
        </div>
        <div className={classes.phone}>
          <Controller
            render={({ field }) => (
              <PhoneInputMask
                label={t('Mobile number')}
                labelPosition={'left'}
                type={'number'}
                placeholder={t('Enter your mobile')}
                className={classes.register_theme}
                value={field?.value}
                onChange={field.onChange}
                error={!!errors?.phone}
              />
            )}
            name='phone'
            control={control}
          />
          {errors?.phone && (
            <TooltipContainer
              text={errors.phone?.message}
              customClasses={'kit-ui-block'}
              position={'top'}
              className={classes.error}
            >
              <InfoIcon fill='#F5222D' width='18px' height='18px' />
            </TooltipContainer>
          )}
        </div>
      </div>
      <input
        style={{
          position: 'fixed',
          top: '-100px',
          left: '-100px',
          width: '5px',
        }}
        type='text'
        name='fakeusernameremembered'
      />
      <div className={classes['bottom-fields']}>
        <div className={classes['input-field']}>
          {showChecks && (
            <div
              className={clsx(
                classes['password-check'],
                !invSymbCheck && classes['slight-up']
              )}
            >
              <div className={classes.title}>{t('Password requirements')}</div>
              <div className={classes.checks}>
                <div className={classes.check}>
                  <div className={classes.icon}>
                    {password.length === 0 && <CheckIcon fill='#A09CAC' />}
                    {lengthCheck && password.length >= 1 ? (
                      <CheckIcon fill='#00C853' />
                    ) : (
                      password.length > 0 && <KrestIcon />
                    )}
                  </div>
                  <div
                    className={clsx(
                      classes.name,
                      lengthCheck && password.length >= 1
                        ? classes.correct
                        : password.length >= 1 && classes.wrong
                    )}
                  >
                    {t('At least 8 characters long')}
                  </div>
                </div>
                <div className={classes.check}>
                  <div className={classes.icon}>
                    {password.length === 0 && <CheckIcon fill='#A09CAC' />}
                    {numbersCheck && password.length >= 1 ? (
                      <CheckIcon fill='#00C853' />
                    ) : (
                      password.length >= 1 && <KrestIcon />
                    )}
                  </div>
                  <div
                    className={clsx(
                      classes.name,
                      numbersCheck && password.length >= 1
                        ? classes.correct
                        : password.length >= 1 && classes.wrong
                    )}
                  >
                    {t('Contains numbers')}
                  </div>
                </div>
                <div className={classes.check}>
                  <div className={classes.icon}>
                    {password.length === 0 && <CheckIcon fill='#A09CAC' />}
                    {symbolsCheck && password.length >= 1 ? (
                      <CheckIcon fill='#00C853' />
                    ) : (
                      password.length >= 1 && <KrestIcon />
                    )}
                  </div>
                  <div
                    className={clsx(
                      classes.name,
                      symbolsCheck && password.length >= 1 && classes.correct,
                      !(symbolsCheck && password.length >= 1) &&
                        password.length >= 1 &&
                        classes.wrong
                    )}
                  >
                    {t('Symbols ~!@#$%^&*()_+<>?￥¿¡·॰।')}
                  </div>
                </div>
                <div className={classes.check}>
                  <div className={classes.icon}>
                    {password.length === 0 && <CheckIcon fill='#A09CAC' />}
                    {letterCheck && password.length >= 1 ? (
                      <CheckIcon fill='#00C853' />
                    ) : (
                      password.length >= 1 && <KrestIcon />
                    )}
                  </div>
                  <div
                    className={clsx(
                      classes.name,
                      letterCheck && password.length >= 1 && classes.correct,
                      !(letterCheck && password.length >= 1) &&
                        password.length >= 1 &&
                        classes.wrong
                    )}
                  >
                    {t('Password must contain at least 1 letter')}
                  </div>
                </div>
                {!invSymbCheck && (
                  <div className={classes.check}>
                    <div className={classes.icon}>
                      <KrestIcon />
                    </div>
                    <div
                      className={clsx(
                        classes.name,
                        !(invSymbCheck && password.length > 1) &&
                          password.length > 0 &&
                          classes.wrong
                      )}
                    >
                      {t('Invalid symbol')}
                    </div>
                  </div>
                )}
                {(errors?.password?.type === 'server' ||
                  errors?.password?.message ===
                    t('Password must not contain spaces')) && (
                  <div className={classes.check}>
                    <div className={classes.icon}>
                      <KrestIcon />
                    </div>
                    <div className={clsx(classes.name, classes.wrong)}>
                      {errors.password?.message}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <Controller
            control={control}
            render={({ field }) => (
              <Input
                label={t('Password')}
                labelPosition={'left'}
                type={showPassword ? 'text' : 'password'}
                placeholder={t('Enter password')}
                value={field.value}
                error={!!errors?.password}
                onChange={(e) => {
                  setPassword(e);
                  field.onChange(e);
                }}
                className={clsx(classes.password, classes.register_theme)}
                onBlur={() => setShowCheks(false)}
                onFocus={() => setShowCheks(true)}
              />
            )}
            name='password'
          />
          <div
            role='button'
            tabIndex={-1}
            onClick={() => setShowPassword(!showPassword)}
            onKeyDown={() => {}}
            className={classes['show-password']}
          >
            <EyeIcon width='18px' height='14px' />
          </div>
        </div>
      </div>
      <div className={classes['checkbox-block']}>
        <Controller
          render={({ field }) => (
            <>
              <Checkbox onChange={field.onChange} checked={field.value} />
              <div className={classes.text}>
                <span>{t('I agree to KIT Global')} </span>
                <Link
                  className={classes.link}
                  href={`${process.env.REACT_APP_MAIN_SITE_URL}/${i18n.language !== defaultLanguage ? i18n.language + '/' : ''}terms`}
                  target={'_blank'}
                >
                  {t('terms of Service (with)')}
                </Link>
                <span>{t('and')}</span>
                <Link
                  className={classes.link}
                  href={`${process.env.REACT_APP_MAIN_SITE_URL}/${i18n.language !== defaultLanguage ? i18n.language + '/' : ''}privacy-policy`}
                  target={'_blank'}
                >
                  {t('privacy Policy (with)')}
                </Link>
              </div>
            </>
          )}
          name='iAgree'
          control={control}
        />
      </div>
      <Button
        className={classes.button}
        theme='light'
        disabled={!isValid}
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo(0, 0);
          getBusinessTypes();
          setCompanySizes(companySizesList);
          setStep(2);
        }}
      >
        {t('Continue')}
      </Button>
    </>
  );
};
