import {FC, useEffect, useState} from "react";

import classes from "./ProjectDealStages.module.scss";
import { CheckCirleIconPurple } from 'components/Icons/CheckCirleIconPurple';
import { PurpleTimeIcon } from 'components/Icons/PurpleTimeIcon';
import { PurpleEmptyIcon } from 'components/Icons/PurpleEmptyIcon';

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { CancelledIcon } from "../../../components/Icons/CancelledIcon";
import { PausedIcon } from "../../../components/Icons/PausedIcon";
import { DownloadIcon } from "components/Icons/DownloadIcon";
import {useTranslation} from "react-i18next";
import {DEAL_STAGES} from "../../../constants/dealStatuses";
import {Button} from "../../../components/Button";
import {useWindowWidth} from "../../../hooks/useWindowWidth";
import {NavLink} from "react-router-dom";
import {userStore} from "../../../stores/userStore";
import Lottie from "lottie-react";
import loader from "../../../components/PageLoader/loader.json";
import {TooltipContainer} from "../../../components/Tooltip/TooltipContainer";
import {toJS} from "mobx";


interface Props {
  project: any
}

export const ProjectsDealStagesContainer: FC<Props> = observer(({ project }) => {
  const { isMediaTablet } = useWindowWidth();
  const { t, i18n } = useTranslation();
  const { CANCELLED, PAUSED } = DEAL_STAGES
  const projectAllStage = project?.deal_stages;
  const pausedStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === PAUSED)
  const cancelledStage = projectAllStage.find((stage: any) => stage.type.toLowerCase() === CANCELLED)
  const [internalStageList, setInternalStageList] = useState<any>([])
  const userLocale = userStore.user?.language?.lang_code
  const isInnerDataLoading = userStore?.isInnerDataLoading

  const moreButton = (
    <div className={classes.buttonContainer}>
      <div className={clsx(classes[`${isMediaTablet ? 'mobileContent' : 'content'}`])}>
        <NavLink to={`/projects/${project.id}`}>
          <Button
            theme="light"
          >
            {t("See more")}
          </Button>
        </NavLink>
      </div>

    </div>
  )

  const stageArray = [
        {'name': t('Project Analysis'), 'help_text': t('We are analyzing your project to offer the best solution and pricing')},
        {'name': t('Briefing'), 'help_text': t('Provide a briefing meeting for discussing project details')},
        {'name': t('Proposal creation'), 'help_text': t('We are working on a commercial proposal')},
        {'name': t('Proposal presented'), 'help_text': t('Presented the commercial proposal')},
        {'name': t('Negotiations'), 'help_text': t('Negotiating terms and conditions with the client')},
        {'name': t('Signing agreement'), 'help_text': t('Signing the agreement')},
        {'name': t('Agreement signed'), 'help_text': t('The agreement with the client has been signed')},
        {'name': t('Account assignment'), 'help_text': t('Assigning the project to the account team')},
        {'name': t('Onboarding'), 'help_text': t('Onboarding process')},
        {'name': t('Preparation for launch'), 'help_text': t('Preparing for the project launch')},
        {'name': t('Launched'), 'help_text': t('The project has been successfully launched')},
        {'name': t('Production'), 'help_text': t('The project has successfully started')},
        // {'name': t('Paused'), 'help_text': t('Project on pause')},
        // {'name': t('Cancelled'), 'help_text': t('Project cancelled')}
      ]

  const orderStages = () => {
    const orderedStages = stageArray
      .map((stage: any) => {
        const findStage = projectAllStage.find((projectStage: any) => {
          return stage?.name.toLowerCase() === projectStage.name.toLowerCase();
        });
        if (findStage) {
          return { ...findStage };
        } else {
          return { ...stage, id: undefined };
        }
      });

    let lastIndex = -1;
    for (let i = 0; i < orderedStages.length; i++) {
      if (orderedStages[i].id !== undefined) {
        lastIndex = i;
      }
    }

    // Вставляем стадии "Paused" и "Cancelled" на соответствующие позиции
    if (pausedStage) {
      orderedStages.splice(lastIndex + 1, 0, pausedStage);
    }

    if (cancelledStage) {
      let index = pausedStage ? 2 : 1
      orderedStages.splice(lastIndex + index, 0, cancelledStage);
    }

    return orderedStages?.slice(0, isMediaTablet ? 5 : 6);
  };


  useEffect(() => {
    setInternalStageList(orderStages())
  }, [pausedStage, cancelledStage, userLocale, projectAllStage]);

  const formatDate = (dateString: string) => {
    const options: any = { year: 'numeric', month: 'short', day: '2-digit' };
    const date: any = dateString ? new Date(dateString): null;
    return date?.toLocaleDateString('en-US', options);
  };

  const getStepIcon = (stageName: string, type: string = '', id: number = 0, planEndDate: string = '', factEndDate: string = '') => {
    const projectFilePath = project.files.find((el: any) => el.deal_stage === id)?.file;
    const helpText = internalStageList.find((obj: any) => obj.name === stageName)?.help_text
    const fileName = projectFilePath?.match(/\/files\/(.+)/)[1];
    const shortFileName = fileName?.length > 20 ? fileName?.slice(0, 20) + '...' : fileName

    const stageType = type.toLowerCase();

    if (planEndDate && factEndDate) {
      return (
        <div className={classes.step}>
          <div className={clsx(classes.name, classes.past)}>
            {stageType === CANCELLED ? <CancelledIcon /> : stageType === PAUSED ? <PausedIcon /> : <CheckCirleIconPurple />}
            <div className={classes.name__main}>
              <span>{stageName}</span>
              {projectFilePath && (
                <a href={process.env.REACT_APP_API + projectFilePath} target='_blank' className={classes.map__link} title={fileName}>
                  <span>{shortFileName}</span>
                  <DownloadIcon />
                </a>
              )}
            </div>
          </div>
          <TooltipContainer
            text={t("Fact date")}
            className={classes.tooltipContainer}
            classNameTooltip={classes.tooltip}
            tooltipInner={classes.tooltipInner}
            position='bottom'
          >
            <div className={classes.date}>{formatDate(factEndDate)}</div>
          </TooltipContainer>
        </div>
      )
    } else if (planEndDate && !factEndDate) {
      return (
        <div className={clsx(classes.step, classes.empty)}>
          <div className={clsx(classes.name, classes.past)}>
            {stageType === CANCELLED ? (
              <CancelledIcon />
            ) : stageType === PAUSED ? (
              <PausedIcon />
            ) : (
              <PurpleTimeIcon/>
            )}
            <div className={classes.name__main}>
              <span>{stageName}</span>
              {projectFilePath && (
                <a
                  href={process.env.REACT_APP_API + projectFilePath}
                  target="_blank"
                  className={classes.map__link}
                  title={fileName}
                >
                  <span>{shortFileName}</span>
                  <DownloadIcon />
                </a>
              )}
            </div>
          </div>
          <TooltipContainer
            text={t("Plan date")}
            className={classes.tooltipContainer}
            classNameTooltip={classes.tooltip}
            tooltipInner={classes.tooltipInner}
            position="bottom"
          >
            <div className={classes.date}>
              {formatDate(planEndDate)}
            </div>
          </TooltipContainer>
        </div>
      );
    } else {
      return (
        <div className={clsx(classes.step, classes.empty)}>
          <div className={clsx(classes.name)}>
            <PurpleEmptyIcon />

            <div className={classes.name__main}>
              <span>{stageName}</span>
              {projectFilePath && (
                <a href={process.env.REACT_APP_API + projectFilePath} target='_blank' className={classes.map__link} title={fileName}>
                  <span>{shortFileName}</span>

                  <DownloadIcon />
                </a>
              )}
            </div>

          </div>
          <TooltipContainer
            text={t("Plan date")}
            className={classes.tooltipContainer}
            classNameTooltip={classes.tooltip}
            tooltipInner={classes.tooltipInner}
            position="bottom"
          >
            <div className={classes.date}>
              {formatDate(planEndDate)}
            </div>
          </TooltipContainer>
        </div>
      )
    }
  }

  return (
    <div className={classes.map}>
      {isInnerDataLoading ? (
        <div className={classes.loader}>
          <Lottie animationData={loader} />
        </div>
      ) : (
        <>
          {internalStageList?.map((el: any) => {
            if (el.id){
              return getStepIcon(el.name, el.type, el.id, el.plan_end_date, el.fact_end_date)
            } else {
              return getStepIcon(el.name)
            }
          })}
          {moreButton}
        </>
      )}
    </div>
  )
});
