import { AdminGradientIcon } from './AdminGradientIcon';
import { AdminGradientIconActive } from './AdminGradientIconActive';
import { ArrowDownIcon } from "./ArrowDownIcon";
import { ArrowIcon } from "./ArrowIcon";
import { ArrowPagination } from "./ArrowPagination";
import {ArrowRightIcon} from "./ArrowRightIcon";
import {BasketGradientIcon} from "./BasketGradientIcon";
import {CalendarGradientIcon} from "./CalendarGradientIcon";
import {EmptyIcon } from "./EmptyIcon";
import {InfoIcon} from "./InfoIcon";
import {HintIcon} from "./HintIcon";
import {MinusIcon} from "./MinusIcon";
import {PlusIcon} from "./PlusIcon";
import {CrossIcon} from "./CrossIcon";
import {CheckIcon} from "./CheckIcon";
import FilterMobileTableIcon from "./FilterMobileTableIcon";
import {ChartBarIcon} from "./ChartBarIcon";

export default {
	AdminGradientIcon,
	AdminGradientIconActive,
	ArrowDownIcon,
	ArrowIcon,
	ArrowPagination,
	ArrowRightIcon,
	BasketGradientIcon,
	CalendarGradientIcon,
	EmptyIcon,
	InfoIcon,
	HintIcon,
	MinusIcon,
	PlusIcon,
	CrossIcon,
	CheckIcon,
	FilterMobileTableIcon,
	ChartBarIcon
}