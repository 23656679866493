import styles from "./ProductListSkeleton.module.scss";

const ProductListSkeleton = () => {
  const items = Array.from({ length: 6 });

  return (
    <div className={styles.wrapper}>
      <ul className={styles.tags}>
        <li className={styles.tag} />
        <li className={styles.tag} />
        <li className={styles.tag} />

        <li className={styles.sort} />
      </ul>

      <ul className={styles.cards}>
        {items.map((_, index) => (
          <li key={index} className={styles.card}>
            <div className={styles["card-top"]}>
              <div className={styles["card-tag"]} />
              <div className={styles.title}>
                <div className={styles["title-item"]} />
                <div className={styles["title-item"]} />
              </div>
              <div className={styles.content}>
                <div className={styles["content-item"]} />
                <div className={styles["content-item"]} />
                <div className={styles["content-item"]} />
              </div>
            </div>
            <div className={styles.button} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductListSkeleton;
