import * as React from "react"
import { SVGProps } from "react"

export const ServicesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46269 7.46269V12.2687H10.2687V7.46269H5.46269ZM11.7313 12.2687V6.74179C11.7313 6.33214 11.3993 6 10.9896 6H4.74179C4.33214 6 4 6.33208 4 6.74179V19.2582C4 19.6679 4.33208 20 4.74179 20H17.2582C17.6679 20 18 19.6679 18 19.2582V13.0104C18 12.6008 17.6679 12.2687 17.2582 12.2687H11.7313ZM10.2687 13.7313H5.46269V18.5373H10.2687V13.7313ZM11.7313 18.5373V13.7313H16.5373V18.5373H11.7313Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3116 7.03769C14.0343 6.76041 14.0344 6.31086 14.3116 6.0336L17.833 2.51221C18.1103 2.23492 18.5598 2.23495 18.8371 2.51221L22.3585 6.0336C22.6358 6.31088 22.6358 6.76043 22.3585 7.03769L18.8371 10.5591C18.5598 10.8364 18.1103 10.8363 17.833 10.5591L14.3116 7.03769ZM15.7895 6.53564L18.3351 9.08123L20.8806 6.53564L18.3351 3.99006L15.7895 6.53564Z" />
  </svg>
);