import {makeAutoObservable, runInAction} from "mobx";
import BasketService from 'services/basket'
import {userStore} from "./userStore";
import _ from 'lodash';

interface addOrRemoveCartProps {
  serviceId: number,
  count?: number
  sourcePage?: string
}

interface createDealProps {
  serviceSourceIds: number[]
}

export interface cartProps {
  cart: any;
  deals: any[];
  selectedItems: any[];
  isLoading: boolean;
  addCart: (val: any) => void;
  loadCart: () => any[];
  getServiceCountInCart: (serviceId: number) => number;
  setIsLoading: (val: boolean) => void;
  getActiveCart: () => any;
  createDeal: ({}: createDealProps) => void;
  modalOpen: boolean;
  fixedButtonSize: number;
  isSelected: boolean;
}

export interface SelectedItemProps {
  service: { id: number };
  id: number;
}

class CartStore {
  cart = null as any
  deals = []
  isLoading = false
  selectedItems: any[] = [];
  selectedBPS: any[] = [];
  modalOpen = false
  fixedButtonSize = 0
  zIndex = 2
  isSelected = false

  constructor() {
    makeAutoObservable(this)
  }

  async handleSelectedItem(item: any) {
    this.setIsSelected(true);
    const index = this.selectedItems.findIndex((selectedItem: any) => selectedItem.service.id === item.service.id);
    if (index !== -1) {
      await BasketService.addToSelectedService({serviceId: item.service.id, select: false}).then(async (response) => {
        // let basket = await this.loadCart();
        this.selectedItems.splice(index, 1);
        this.selectedBPS = response.data.basket.basket_project_service?.map((item: any) => {
            if (item.selected) {
              return item;
            } else {
              return null
            }
          }
        ).filter((item: any) => item !== null);
        this.setIsSelected(false);
      });
    } else {
      await BasketService.addToSelectedService({serviceId: item.service.id, select: true}).then(async (response) => {
        // let basket = await this.loadCart();
        this.selectedItems.push(item);
        this.selectedBPS = response.data.basket.basket_project_service?.map((item: any) => {
            if (item.selected) {
              return item;
            } else {
              return null
            }
          }
        ).filter((item: any) => item !== null);
        this.setIsSelected(false);
      });
    }
  }

  async handleAllItemsInSelectedItem(data: any[], isChecked: boolean) {
    this.setIsSelected(true)
    const servicesIds: number[] = data.map(item => item.service.id);

    if (isChecked) {
      const response = await BasketService.addToSelectedService({serviceIds: servicesIds, select: true});

      data.forEach(item => {
        if (!this.selectedItems.some(selectedItem => selectedItem.service.id === item.service.id)) {
          this.selectedItems.push(item);
        }
      });

      this.selectedBPS = response.data.basket.basket_project_service?.filter((item: any) => item.selected) || [];
      this.setIsSelected(false)
    } else {
      const response = await BasketService.addToSelectedService({serviceIds: servicesIds, select: false});

      this.selectedItems = this.selectedItems.filter(selectedItem =>
        !data.some(item => item.service.id === selectedItem.service.id)
      );

      this.selectedBPS = response.data.basket.basket_project_service?.filter((item: any) => item.selected) || [];
      this.setIsSelected(false)
    }
  }


  handleRemoveSelected() {
    this.selectedItems.forEach(async ({service, count}) => {
      await this.removeFromCart({serviceId: service.id, count});
    });
  }

  // TODO need to refactor backend
  updateSelectedItems(projectService: any[]) {
    const projectServiceMap = new Map();

    for (const projectItem of projectService) {
      const serviceId = projectItem.service.id;
      if (!projectServiceMap.has(serviceId)) {
        projectServiceMap.set(serviceId, []);
      }
      projectServiceMap.get(serviceId).push(projectItem.id);
    }

    for (const selectedItem of this.selectedItems) {
      const serviceId = selectedItem.service.id;
      const updatedItems = projectServiceMap.get(serviceId) || [];
      selectedItem.updatedServices = updatedItems;
      selectedItem.count = updatedItems.length;
    }
  }

  addCart(cart: any) {
    if (cart.error) {
      this.cart = null
    } else {
      this.cart = cart
    }

  }

  addDeals(deals: any) {
    if (deals.error) {
      this.deals = []
    } else {
      this.deals = deals
    }
  }

  getServiceCountInCart(serviceId: number): number {
    const cart = this.getActiveCart() || {basket_project_service: []}
    let countInCart = 0
    if (cart) {
      cart?.basket_project_service?.forEach((service: any) => {
        if (service.service.id === serviceId) {
          countInCart += 1
        }
      });
    }
    return countInCart
  }

  getGroupedBasketProjectServices(cart: any) {
    const res = []
    const group = _.groupBy(cart.basket_project_service, (item) => item.service.id)
    for (const [serviceId, services] of Object.entries(group)) {
      const groupData = {...services[0]}
      groupData.count = services.length
      delete groupData.id
      res.push(groupData)
    }
    return res
  }


  private isServiceAlreadySelected(serviceId: number): boolean {
    return this.selectedItems.some(item => item.service.id === serviceId);
  }

  async getBasket() {
    const response = await BasketService.getBasket()
    if (response?.data?.error) {
      return []
    }


    const cart = response?.data || {}
    if (cart) {
      cart.basket_project_service_group = this.getGroupedBasketProjectServices(cart)
      for (const service of cart.basket_project_service_group) {
        if (service.selected && !this.isServiceAlreadySelected(service.service.id)) {
          this.selectedItems.push(service);
        }
      }
    }

    return cart
  }

  async loadCart() {
    this.setIsLoading(true)
    const basket = await this.getBasket()

    this.addCart(basket)
    if (this.selectedItems.length > 0) {
      this.updateSelectedItems(basket.basket_project_service);
    }
    this.setIsLoading(false)
    this.selectedBPS = basket.basket_project_service?.map((item: any) => {
        if (item.selected) {
          return item;
        } else {
          return null
        }
      }
    ).filter((item: any) => item !== null);
    return this.cart
  }

  setIsLoading(val: boolean) {
    this.isLoading = val;
  }

  setIsSelected(val: boolean) {
    this.isSelected = val;
  }

  getActiveCart(): null {
    return this.cart;
  }

  setFixedButtonSize(size: number, zIndex?: number) {
    this.fixedButtonSize = size
    if (zIndex) this.zIndex = zIndex
  }

  async addToCart({serviceId, count = 1}: addOrRemoveCartProps) {

    this.setIsLoading(true)

    const checkItemExists = (items: any[], id: number): boolean => {
      return items.some(item => item.service.id === id);
    };

    const select = checkItemExists(this.selectedItems, serviceId);

    await BasketService.addToBasket({
      serviceId: serviceId,
      sourcePage: window.location?.href,
      count,
      select: select
    })

    const res = await Promise.all([this.loadCart(), userStore.loadUser()])

    this.setIsLoading(false)

    return res[0]
  }

  async removeFromCart({serviceId, count = 1, sourcePage = ''}: addOrRemoveCartProps) {
    this.setIsLoading(true)


    const checkItemExists = (items: any[], id: number): boolean => {
      return items.some(item => item.service.id === id);
    };

    const select = checkItemExists(this.selectedItems, serviceId);

    await BasketService.removeFromBasket({
      serviceId: serviceId,
      count,
      sourcePage: window.location?.href,
      select: select
    })

    const res = await Promise.all([this.loadCart(), userStore.loadUser()])

    this.selectedItems = this.selectedItems.filter((item: any) => item.count >= 1);

    this.setIsLoading(false)

    return res[0]
  }

  async createDeal({serviceSourceIds}: createDealProps) {
    this.setIsLoading(true);
    try {
      await runInAction(async () => {
        this.selectedItems = this.selectedItems.filter((item: any) => {
          return !serviceSourceIds.some((id: any) => id !== item.service.id);
        });
      });
      await BasketService.createDeal({serviceSourceIds}).then((response) => {
        userStore.setProjects(response?.data?.projects)
      });
      await Promise.all([this.loadCart(), userStore.loadProjects()]);
    } catch (err: any) {
      this.setIsLoading(false);
      throw err;
    } finally {
      this.setIsLoading(false);
    }
  }

  updateCardCost(total: string | number, selectedService: any, discount: number | string) {
    this.selectedItems.forEach((item) => {
      if (item.service.id === selectedService.id) {
        item.total_cost = total
        item.total_cost_with_discounts = discount
        item.service = selectedService
      }
    })
  }

  clearStore() {
    this.modalOpen = false
    this.cart = null
    this.deals = []
    this.selectedItems = []
    this.isLoading = false
  }

}

export const cartStore = new CartStore()
