import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";

import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import { Grid, GridItem } from "components/Grid";

import { ApplyCoupon } from "api/applyCoupon";
import { WithdrawCoupon } from "api/withdrawCoupon";

import classes from "./Cart.module.scss";
import totalClasses from "./Total/Total.module.scss";
import headerClasses from "../../components/Header/Header.module.scss";
import { CartList } from "containers/Cart/CartList";
import { Total } from "./Total";
import { RecommendedService } from "containers/RecommendedService";
import { Button } from "components/Button";
import { EmptyCart } from "./EmptyCart";
import { useCartStore } from "contexts/CartContext";
import { Modal } from "components/Modal";
import { CheckCircleGradientItem } from "components/Icons/CheckCircleGradientItem";
import { Type } from "components/Type";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ShowNotification } from "tools/showNotification";
import { SelectedItemProps } from "../../stores/cartStore";
import { routers } from "../../constants/routers";
import { NavLink } from "react-router-dom";

import { Steps } from "intro.js-react";
import "../CardsContainer/IntroTooltip.css";
import "intro.js/introjs.css";
import { cartMessages, emptyCartMessages } from "./hints";
import {
  isOnboardingActive,
  setOnboardingOptions,
} from "../../tools/onboarding";
import { userStore } from "../../stores/userStore";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { LABELS } from "../../constants/onboarding";

import clsx from "clsx";
import FormatPrice from "../../components/FormatPriceAndCurrency";
import { useMarketPlaceStore } from "../../contexts/marketPlaceContext";
import { toJS } from "mobx";
import CartSkeleton from "components/Skeleton/CartSkeleton/CartSkeleton";

interface Props {}

export const CartContainer: FC<Props> = observer(() => {
  const cartStore = useCartStore();
  const marketPlaceStore = useMarketPlaceStore();
  const selectedItems = cartStore.selectedItems;
  const selectedBPS = cartStore.selectedBPS;

  const { t } = useTranslation();

  const activeCart = cartStore.getActiveCart();

  const count = activeCart?.services_count;
  const data = activeCart?.basket_project_service_group;
  const projectServices = activeCart?.basket_project_service || [];
  const allProducts = marketPlaceStore.services;

  const total = selectedBPS?.reduce((accumulator: number, item: any) => {
    const realCost = item.service?.is_free ? 0 : Number(item.total_cost);
    return accumulator + realCost;
  }, 0);

  const totalWithDiscounts = selectedBPS?.reduce(
    (accumulator: number, item: any) => {
      const realCost = item.service?.is_free
        ? 0
        : Number(item.total_cost_with_discounts);
      return accumulator + realCost;
    },
    0,
  );

  useEffect(() => {
    cartStore.loadCart();
  }, []);

  const couponDiscount = cartStore.cart?.basket_project_service?.reduce(
    (accumulator: number, item: any) => {
      const serviceIds = selectedItems.map((item: any) => item.service.id);
      if (serviceIds.includes(item.service.id)) {
        return Number(accumulator) + Number(item.total_coupon_discount_value);
      }
      return accumulator;
    },
    0,
  );

  const couponDiscountText =
    activeCart?.cost_change_type === "PERC"
      ? t("Discount {{percent}}%", {
          percent: ~~activeCart?.coupon_discount.cost_change_value,
        })
      : t("Discount");
  const isEmpty = !activeCart || !activeCart?.services_count;
  const cartId = activeCart?.id;
  const isMobile = useWindowWidth().isSmallLaptop;
  const isTablet = useWindowWidth().isMediaTablet;
  const enableHints = isOnboardingActive(userStore?.pagesToOnboard?.cart);
  const buttonRef = useRef<HTMLButtonElement>(null);

  let arrRecommendServices = projectServices?.map((item: any) => {
    return item.service.recommended_service;
  });

  const recommendedServicesIds = arrRecommendServices
    ? Array.from(new Set(arrRecommendServices.flat()))
    : [];

  const activeProductsIds = useMemo(
    () =>
      allProducts
        ?.filter(({ is_active, is_favorite }: any) => is_active)
        .map(({ id }: any) => id)
        .sort(() => Math.random() - 0.5)
        .slice(0, 5),
    [allProducts],
  );

  const activeIds = recommendedServicesIds?.length
    ? recommendedServicesIds
    : activeProductsIds;

  const [cookies, setCookies] = useCookies(["access", "refresh"]);
  const [coupon, setCoupon] = useState(activeCart?.coupon || "");
  const [couponError, setCouponError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showFixedButton, setShowFixedButton] = useState(false);

  const bottomMenu = document.querySelector(
    `.${headerClasses["header__bottom-menu"]}`,
  ) as HTMLElement;

  useEffect(() => {
    if (couponError) return;
    setErrorMessage("");
  }, [couponError]);

  const applyCouponHandler = async (coupon_input: string) => {
    try {
      const response = await ApplyCoupon({
        coupon: coupon_input,
        cartId: cartId,
        access: cookies.access,
        refresh: cookies.refresh,
        totalSelectedItemsAmount: Math.floor(totalWithDiscounts),
      });
      if (response?.updated === true) {
        setCoupon(coupon_input);
      } else {
        setCouponError(true);
      }
      cartStore.loadCart();
    } catch (error: any) {
      setErrorMessage(error.response.data?.coupon[0]);
      setCouponError(true);
    }
  };

  const withdrawCouponHandler = async (remove?: boolean) => {
    const response = await WithdrawCoupon({
      cartId: cartId,
      access: cookies.access,
      refresh: cookies.refresh,
    });
    if (response?.updated === true) {
      if (!remove && coupon) await applyCouponHandler(coupon);
      setCoupon("");
    } else {
      setCouponError(true);
    }
    cartStore.loadCart();
  };

  const onCloseDealModal = () => {
    cartStore.loadCart();
    setShowModal(false);
  };

  const selectedServiceIds = new Set(
    selectedItems?.map((item: SelectedItemProps) => item.service.id),
  );

  const projectServiceIds: string[] = [];

  for (const projectService of projectServices) {
    const serviceId = projectService.service.id;

    if (selectedServiceIds.has(serviceId)) {
      projectServiceIds.push(projectService.id);
    }
  }

  const onCreateDeal = async () => {
    try {
      await cartStore.createDeal({
        serviceSourceIds: projectServiceIds,
      });
      setShowModal(true);
      cartStore.loadCart();
      setCoupon("");
    } catch (err: any) {
      if (err.response?.data?.service_project_id) {
        ShowNotification({
          type: "error",
          children: err.response?.data?.service_project_id[0],
        });
      }
    }
  };

  const handleExit = useCallback(() => {
    setOnboardingOptions({ cart: true });
    userStore.enableOnboarding(true);
  }, []);

  const formattedPrice = (price?: number | string, withMinus?: boolean) => (
    <FormatPrice
      currency={userStore.user.currency?.symbol}
      price={price}
      withMinus={withMinus}
    />
  );
  const checkCurrency = () => {
    return (
      userStore.user.currency?.char_code === "IDR" ||
      userStore.user.currency?.char_code === "INR"
    );
  };

  const fixedBtnHeight = (height: number) => {
    cartStore.setFixedButtonSize(height);
  };

  const isInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const result =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    if (bottomMenu && selectedItems?.length && !result) {
      fixedBtnHeight(120);
    } else {
      fixedBtnHeight(56);
    }
    setShowFixedButton(!result);
  };

  useEffect(() => {
    if (!bottomMenu) return;
    if (selectedItems?.length) {
      setShowFixedButton(true);
      fixedBtnHeight(120);
    } else {
      setShowFixedButton(false);
      fixedBtnHeight(56);
    }
    window.addEventListener("scroll", () => {
      if (buttonRef?.current) {
        isInViewport(buttonRef.current as HTMLElement);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {
        if (buttonRef?.current) {
          isInViewport(buttonRef?.current as HTMLElement);
        }
      });
    };
  }, [buttonRef, bottomMenu, selectedItems?.length]);

  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const fixedButton = showFixedButton && (
    <div className={classes.fixedButton}>
      <span
        className={clsx(
          totalClasses.costBig,
          checkCurrency() && totalClasses["costBig--small"],
        )}
      >
        {formattedPrice(
          Number(total) !== Number(totalWithDiscounts)
            ? totalWithDiscounts
            : total,
        )}
      </span>
      <Button
        theme={"primary"}
        size={"big"}
        className={classes.button}
        disabled={!selectedItems?.length}
        onClick={onCreateDeal}
      >
        <span>{t("Start projects")}</span>
      </Button>
    </div>
  );

  return (
    <>
      <div className={classes.wrapper}>
        <TitleAndDescription pageName="Cart" />

        <Steps
          enabled={isMobile && enableHints}
          steps={!isEmpty ? cartMessages(t) : emptyCartMessages(t)}
          options={{
            tooltipClass: "customTooltip",
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onComplete={() => {
            handleExit();
            window.location.href = "/projects";
          }}
          onExit={handleExit}
        />

        {!isEmpty && data ? (
          <div className={classes.container}>
            {data && (
              <div className={classes.title}>
                {t("Cart")}
                <span>{count}</span>
              </div>
            )}

            <Grid direction="row">
              <GridItem col={{ default: 12, xsup: 8 }} className={classes.left}>
                {data && (
                  <CartList
                    data={data}
                    cartId={cartId}
                    selectedItems={selectedItems}
                  />
                )}
              </GridItem>

              <GridItem
                col={{ default: 12, xsup: 4 }}
                className={classes.right}
              >
                <div
                  className={clsx(
                    classes.orderDetails,
                    !enableHints && classes.sticky,
                  )}
                >
                  {data && (
                    <Total
                      total={total}
                      totalWithDiscounts={totalWithDiscounts}
                      count={selectedItems?.length}
                      createDeals={onCreateDeal}
                      applyCouponHandler={applyCouponHandler}
                      setCoupon={setCoupon}
                      coupon={coupon}
                      couponDiscount={couponDiscount}
                      couponDiscountText={couponDiscountText}
                      couponError={couponError}
                      setCouponError={setCouponError}
                      errorMessage={errorMessage}
                      withdrawCouponHandler={withdrawCouponHandler}
                      selectedItems={selectedItems}
                      buttonRef={buttonRef}
                    />
                  )}
                </div>
              </GridItem>
            </Grid>

            {activeIds?.length ? (
              <div className={classes.recommended}>
                <RecommendedService
                  ids={activeIds}
                  title={t("Others usually choose")}
                  nameSource="Cart"
                />
              </div>
            ) : null}
          </div>
        ) : cartStore.isLoading ? (
          <CartSkeleton />
        ) : (
          <EmptyCart />
        )}

        <Modal
          title={""}
          isOpen={showModal}
          onClose={() => onCloseDealModal()}
          className={classes.modal}
        >
          <div className={classes.gradient}>
            <div className={classes.innerDeals}>
              <div className={classes.iconDeals}>
                <CheckCircleGradientItem />
              </div>
              <h2 className={classes.titleDeals}>{t("Projects created")}</h2>
              <div className={classes.textDeals}>
                <Type size="simple" tag="p" className={classes.textItemDeals}>
                  {t(
                    "Congratulations, your Projects have been created! We'll get in touch within 2 working days.",
                  )}
                </Type>
              </div>
              <div className={classes.buttonModalWrap}>
                <NavLink to={routers.projects}>
                  <Button
                    theme="primary"
                    size="big"
                    className={classes.buttonModal}
                    onClick={() => onCloseDealModal()}
                  >
                    <span>{t("Go to projects")}</span>
                  </Button>
                </NavLink>
              </div>
            </div>
          </div>
        </Modal>

        <div className={classes.hint}>
          <div className={classes.hint__wrapper}>
            <div className={classes.hint__text}>
              {t(
                "You’ll get the final price after consultation with a manager",
              )}
            </div>

            <Button className={classes["hint__btn"]} theme="default">
              {t("I understood")}
            </Button>
          </div>
        </div>
      </div>
      {isTablet && selectedItems?.length && fixedButton}
    </>
  );
});
