import React from "react";
import { NavLink } from "react-router-dom";
import classes from './Logo.module.scss';
import LogoIcon from "../../img/LogoIcon";

export const Logo = () => {
  return (
    <NavLink to={'/'} className={classes.logo}>
      <LogoIcon />
    </NavLink>
  );
}