import React, { FC } from 'react';
import { LoginContainer } from 'containers/Login';
import '../styles/main.scss';


// todo add localization feature
export const Login: FC = () => {
  return (
    <>
      <LoginContainer />
    </>
  );
}