import { FC, useRef, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import { Request } from "tools/request";
import { Type } from "components/Type";
import { Grid, GridItem } from "components/Grid";
import { PhoneInputMask } from "components/PhoneInput";
import { TooltipContainer } from "components/Tooltip/TooltipContainer";
import { InfoIcon } from "components/Icons/InfoIcon";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import { useDisableScrolling } from "hooks/useDisableScrolling";
import { ShowNotification } from "tools/showNotification";

import classes from "./Personal.module.scss";
import {personalSchema} from "../validationSchemas";

interface Props {
  defaultValue: {
    fullName: string;
    email: string;
    phone: string | "";
  };
  getParams: any;
}

interface FormInputs {
  fullName: string;
  email?: string;
  phone: string;
}

export const Personal: FC<Props> = ({ defaultValue, getParams }) => {
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(personalSchema),
    mode: 'onChange',
    defaultValues: {
      fullName: defaultValue.fullName,
      email: defaultValue.email,
      phone: defaultValue.phone
    }
  });

  const [cookies, setCookies] = useCookies(["access", "refresh"]);
  const formRef = useRef(null);

  const [fullName, setFullName] = useState<string>(defaultValue.fullName);
  const [phone, setPhone] = useState<string | null>(defaultValue.phone);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const email = defaultValue?.email

  // useDisableScrolling();

  const onSubmit = async (e: any) => {
    let formValues = new FormData();

    formValues.append("full_name", fullName);
    formValues.append("email", email);
    formValues.append("phone_number", "+" + phone);

    try {
      const result = await Request({
        type: "PUT",
        url: `${process.env.REACT_APP_API}/api/v1/users/`,
        isProtected: true,
        access: cookies.access,
        data: formValues,
      });
      if (result.status !== 400) {
        getParams();

        setDisableSubmit(false);
        ShowNotification({type: 'success', children: t("Profile successfully changed")})
      }
    } catch (e: any) {
      getParams();
      let result_data = e.response.data
      if ("phone_number" in result_data){
        setDisableSubmit(true);
      } else {
        setDisableSubmit(true);
        ShowNotification({type: 'error', children: t("Something went wrong")})
      }
    }
  }

  useEffect(() => {
    if (
      fullName !== "" &&
      !errors.fullName &&
      phone &&
      phone.length > 10
    ) {
      if (
        fullName !== defaultValue.fullName ||
        phone !== defaultValue.phone
      ) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    } else {
      setDisableSubmit(true);
    }
  }, [errors?.fullName, fullName, phone]);

  const getLink = (language: string) => {
    if (language === 'en') {
      return 'https://kit.global/privacy-policy/'
    } else if (language === 'es') {
      return 'https://kit.global/es/privacy-policy/'
    }
    return '';
  }

  return (
    <>
      <TitleAndDescription pageName="Account/personal" />
      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={"h2"} className={classes.title}>
            {t("Personal information")}
          </Type>
        </div>
        <p className={classes['info']}>
          {t("By providing Us with Your contact information You further agree on and accept the terms of our")}
          {" "}
          <a className={classes['info__link']} href={getLink(i18n.language)} target="_blank">{t("Privacy Policy")}</a>
        </p>
        <form className={classes.inner} ref={formRef} onSubmit={handleSubmit(onSubmit)} >
          <div className={classes.box}>
            <Grid direction={"row"} className={classes.row}>
              <GridItem col={{ default: 12 }} className={classes.item}>
                <div className={classes.field}>
                  <Controller
                    name='fullName'
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t("Full name")}
                        labelPosition={"left"}
                        type={"text"}
                        placeholder={t("Enter your name")}
                        value={field.value}
                        error={!!errors.fullName}
                        maxLength={50}
                        onChange={(e) => {
                          field.onChange(e)
                          setFullName(e)
                        }}
                      />
                    )}
                  />
                  {errors.fullName && (
                    <TooltipContainer
                      text={t("Invalid name")}
                      customClasses={"kit-ui-block"}
                      position={"top"}
                      className={classes.error}
                    >
                      <InfoIcon fill="#F5222D" width="18px" height="18px" />
                    </TooltipContainer>
                  )}
                </div>
              </GridItem>
            </Grid>
            <Grid direction={"row"} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes.email}>
                  <Controller
                    name='email'
                    control={control}
                    render={() => (
                      <Input
                        label={t("Email")}
                        labelPosition={"left"}
                        type={"text"}
                        placeholder={t("Enter your email")}
                        className={classes.email}
                        value={email}
                        disabled
                      />
                    )}
                  />
                </div>
              </GridItem>
            </Grid>
            <Grid direction={"row"} className={classes.row}>
              <GridItem col={{default: 12}}>
                <div className={classes.field}>
                  <Controller
                    name='phone'
                    control={control}
                    render={({ field }) => (
                      <PhoneInputMask
                        label={t("Mobile number")}
                        labelPosition={"left"}
                        type={"number"}
                        placeholder={t("Enter your mobile")}
                        value={field.value}
                        error={!!errors.phone}
                        onChange={(e) => {
                          setPhone(e)
                          field.onChange(e)
                        }}
                      />
                    )}
                  />
                  {errors.phone && (
                      <TooltipContainer
                          text={t("Invalid phone number")}
                          customClasses={"kit-ui-block"}
                          position={"top"}
                          className={classes.error}
                      >
                        <InfoIcon fill="#F5222D" width="18px" height="18px"/>
                      </TooltipContainer>
                  )}
                </div>
              </GridItem>
            </Grid>
          </div>
          <div className={classes.submit}>
            <Button
              className={classes.button}
              theme="primary"
              size="middle"
              disabled={disableSubmit}
            >
              {t("Save changes")}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
