import styles from "./FiltersSkeleton.module.scss";

const FiltersSkeleton = () => {
  const filters = Array.from({ length: 3 });
  const items = Array.from({ length: 5 });

  return (
    <div className={styles.filters}>
      {filters.map((_, index) => (
        <div className={styles.wrapper} key={index + 10}>
          <div className={styles.title} />

          <ul className={styles.items}>
            {items.map((_, index) => (
              <li key={index + 100} className={styles.item} />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default FiltersSkeleton;
