import { InvoicesProps } from "./Invoices.props";
import classes from "./Invoices.module.scss";
import { useTranslation } from "react-i18next";
import clsx from "classnames";
import Table from "components/Table";
import { tableData } from "../invoiceData";

const Invoices = ({
  internalInvoices,
  columns,
  tableActive,
  customPageCount,
  getDataByPage,
  defaultPageSize,
  handleShowPaymentBillModal,
  filter,
  className,
  ...props
}: InvoicesProps) => {
  const { t } = useTranslation();

  return (
    <div className={className} {...props}>
      {!internalInvoices.length ? (
        <div className={clsx(classes.empty)}>
          <div className={classes.empty__header}>
            {t("You don't have any invoices")}
          </div>
          <div className={classes.empty__text}>
            {t("To start a Project please select a Solution")}
          </div>
          <a href="/solutions" className={classes.empty__back}>
            {t("Choice to Solutions")}
          </a>
        </div>
      ) : (
        <Table
          additionalClassNames={classes.table}
          data={tableData(internalInvoices, t)}
          columns={columns}
          tableActive={tableActive}
          customPageCount={customPageCount}
          getDataByPage={getDataByPage}
          defaulPageSize={defaultPageSize}
          mobileModeTitle={t("New ones first")}
          isMobile
          filter={filter}
          handleShowModalOnButton={handleShowPaymentBillModal}
        />
      )}
    </div>
  );
};

export default Invoices;
