import _, { trimEnd } from 'lodash';
import { userStore } from './stores/userStore';
import { marketPlaceStore } from './stores/marketPlaceStore';
import { cartStore } from './stores/cartStore';
import { authStore } from './stores/authStore';
import { toJS } from 'mobx';

export function getCookie(name: string): string | null {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = _.trim(cookies[i]);
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function setCookie(name: string, value: string, days: number): void {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function isPromise(p: any) {
  if (
    typeof p === 'object' &&
    typeof p.then === 'function' &&
    typeof p.catch === 'function'
  ) {
    return true;
  }

  return false;
}

export const requestData = async (options: any, params?: any) => {
  const {
    services = false,
    invoices = false,
    projects = false,
    user = false,
    cart = false,
    types = false,
    serviceItem = false,
    auth = false,
    projectInvoices = false,
    projectInfo = false,
    increment = false,
  } = options;

  userStore.loadData(true);
  // await cartStore.loadCart();
  const isServicesFull = marketPlaceStore?.services?.length;
  const isInvoicesFull = userStore?.invoices?.length;
  const isProjectsFull = userStore?.projects?.length;
  const isCartFull = cartStore?.cart?.id;
  const isTypesFull = marketPlaceStore?.businessTypes?.length;
  const isAuthFull = authStore?.accessToken;

  const promises = [
    isServicesFull && !services
      ? {}
      : services
      ? await marketPlaceStore.loadServices()
      : {},
    isInvoicesFull && !invoices
      ? {}
      : invoices
      ? await userStore.loadInvoices()
      : {},
    isProjectsFull && !projects
      ? {}
      : projects
      ? await userStore.loadProjects()
      : {},
    isCartFull && !cart ? {} : cart ? await cartStore.loadCart() : {},
    isTypesFull && !types
      ? {}
      : types
      ? await marketPlaceStore.loadTypes()
      : {},
    user ? await userStore.loadUser() : {},
    serviceItem ? await marketPlaceStore.loadSingleService(params?.id) : {},
    isAuthFull && auth ? {} : auth ? await authStore.verifyAuth() : {},
    projectInfo ? await userStore.loadProjectData(params?.id) : {},
    increment ? await marketPlaceStore.incrementServiceViews(params?.id) : {},
    projectInvoices ? await userStore.loadProjectInvoices(1, params?.id) : {},
  ];

  try {
    const results = await Promise.all(promises);
    userStore.loadData(false);
    return {
      services: results[0],
      invoices: results[1],
      projects: results[2],
      cart: results[3],
      types: results[4],
    };
  } catch (error) {
    console.error('Error loading data:', error);
    throw error;
  }
};

export const RUSSIAN_LOCALE = process.env.REACT_APP_PLATFORM_TYPE === 'ru';
// export const RUSSIAN_LOCALE = true;
