import * as React from "react"
import { SVGProps } from "react"

export const HintIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width}
    height={props.height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M9.36874 12.6424C9.20129 12.8099 9.10722 13.037 9.10722 13.2738C9.10722 13.5106 9.20129 13.7377 9.36874 13.9051C9.53618 14.0726 9.76328 14.1666 10.0001 14.1666C10.2369 14.1666 10.464 14.0726 10.6314 13.9051C10.7989 13.7377 10.8929 13.5106 10.8929 13.2738C10.8929 13.037 10.7989 12.8099 10.6314 12.6424C10.464 12.475 10.2369 12.3809 10.0001 12.3809C9.76328 12.3809 9.53618 12.475 9.36874 12.6424Z"
      fill="#DDC8FF"/>
    <path
      d="M9.40484 11.0416C9.40484 11.1235 9.47181 11.1905 9.55365 11.1905H10.4465C10.5284 11.1905 10.5953 11.1235 10.5953 11.0416V5.98212C10.5953 5.90028 10.5284 5.83331 10.4465 5.83331H9.55365C9.47181 5.83331 9.40484 5.90028 9.40484 5.98212V11.0416Z"
      fill="#DDC8FF"/>
    <path
      fillRule="evenodd" clipRule="evenodd"
      d="M1.66675 9.99998C1.66675 14.6019 5.39815 18.3333 10.0001 18.3333C14.602 18.3333 18.3334 14.6019 18.3334 9.99998C18.3334 5.39804 14.602 1.66665 10.0001 1.66665C5.39815 1.66665 1.66675 5.39804 1.66675 9.99998ZM3.08044 9.99998C3.08044 6.17929 6.1794 3.08034 10.0001 3.08034C13.8208 3.08034 16.9197 6.17929 16.9197 9.99998C16.9197 13.8207 13.8208 16.9196 10.0001 16.9196C6.1794 16.9196 3.08044 13.8207 3.08044 9.99998Z"
      fill="#DDC8FF"/>
  </svg>
)
;