import React, { FC } from "react";
import { ProjectsContainer} from "containers/Projects";
import "../styles/main.scss";

// todo add localization feature
export const Projects: FC = () => {
  return (
    <ProjectsContainer />
  );
};
