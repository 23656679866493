import MD5 from "crypto-js/md5";


interface Props {
  string: string
}

export async function HashFromString({
  string
}: Props) {
    return MD5(string).toString();
}
