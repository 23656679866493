import * as React from "react"
import { SVGProps } from "react"

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width}
    height={props.height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      d="M8.369 11.643a.893.893 0 1 0 1.263 1.263.893.893 0 0 0-1.263-1.263ZM8.405 10.042a.15.15 0 0 0 .149.15h.893a.15.15 0 0 0 .149-.15v-5.06a.15.15 0 0 0-.15-.148h-.892a.15.15 0 0 0-.149.149v5.06Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.667 9a8.334 8.334 0 1 0 16.668 0A8.334 8.334 0 0 0 .667 9Zm1.414 0A6.92 6.92 0 0 1 15.92 9 6.92 6.92 0 0 1 2.08 9Z"
    />
  </svg>
);