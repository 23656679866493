import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'components/Link';
import { PurpleArrowLeftIcon } from 'components/Icons/PurpleArrowLeftIcon';
import classes from './ServiceDetail.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceInfo } from './ServiceInfo';
import { OurTeam } from './OurTeam';
import { Certificates } from './Certificates';
import { Cases } from './Cases';
import { RecommendedService } from 'containers/RecommendedService';
import { Grid } from 'components/Grid';
import { GridItem } from '../../components/Grid/index';
import { Price } from './Price';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { TitleAndDescription } from 'components/TitleAndDescription/TitleAndDescription';
import { useHotDeals } from 'hooks/useHotDeals';
import { HotDealsCounter } from 'containers/CardsContainer/HotDeals/HotDealsCounter';
import clsx from 'clsx';
import { CardManager } from './CardManager';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useMarketPlaceStore } from 'contexts/marketPlaceContext';
import { NotFoundPage } from 'pages/NotFoundPage';
import { useUserStore } from 'contexts/UserContext';
import Service from 'services/service';
import { ShowNotification } from 'tools/showNotification';
import FormatPrice from '../../components/FormatPriceAndCurrency';
import { Steps } from 'intro.js-react';
import { serviceDetailMessages } from './hints';
import '../CardsContainer/IntroTooltip.css';
import 'intro.js/introjs.css';
import {
  isOnboardingActive,
  setOnboardingOptions,
} from '../../tools/onboarding';
import { LABELS } from '../../constants/onboarding';

interface Props {}

export const ServiceDetailContainer: FC<Props> = observer(({}) => {
  const { width } = useWindowWidth();
  const userStore = useUserStore();
  const params = useParams();
  const navigate = useNavigate();

  const marketPlaceStore = useMarketPlaceStore();
  const singleService = marketPlaceStore?.singleService;
  const allProducts = marketPlaceStore?.services;

  const [serviceInfo, setServiceInfo] = useState<any>(singleService);
  const [hasError, setHasError] = useState(false);

  const isMobile = useWindowWidth().isSmallLaptop;
  const enableHints = isOnboardingActive(
    userStore?.pagesToOnboard?.solutionsDetail
  );

  if (serviceInfo?.notFound || hasError) {
    return <NotFoundPage />;
  }

  const handleExit = useCallback(() => {
    setOnboardingOptions({ solutionsDetail: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  useEffect(() => {
    window?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const dealses = serviceInfo?.discounts?.find(
    (item: any) => item.type === 'HOT_DEAL' && item.is_active === true
  );
  const { t, i18n } = useTranslation();
  const spanishLocale = i18n.language === 'es';

  const { getHotDealText } = useHotDeals(t);
  const [isHotDeal, setIsHotDeal] = useState(false);
  const [hotDealDiscountText, setHotDealDiscountText] = useState('');
  let dealsDates = {
    dateDealDate: 0,
    monthEndDate: '',
    yearEndDate: 0,
    active: false,
  };

  const activeProductsIds = useMemo(
    () =>
      allProducts
        ?.filter(({ is_active, is_favorite }: any) => is_active)
        .map(({ id }: any) => id)
        .sort(() => Math.random() - 0.5)
        .slice(0, 5),
    [allProducts]
  );

  const activeIds = serviceInfo?.recommended_service?.length
    ? serviceInfo?.recommended_service
    : activeProductsIds;

  const fetchData = async () => {
    if (params?.id) {
      try {
        const response = await Service.getService(params.id);
        setServiceInfo(response?.data);
        marketPlaceStore.loadActiveHotDeals([response?.data]);
        marketPlaceStore.loadDataTimeHotDeals();
        setHasError(false);
      } catch (error: any) {
        setHasError(true);
        const errorMessage =
          error?.response?.data?.detail ||
          error?.message ||
          t('Something went wrong');
        ShowNotification({
          type: 'error',
          children: errorMessage,
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [userStore.user.language, userStore.user.currency]);

  useEffect(() => {
    if (serviceInfo && dealses) {
      setIsHotDeal(true);
      const utcDate = new Date(`${dealses.deal_end_date}`);
      const dateDealDate = utcDate.getDate();
      const monthEndDate = utcDate.toLocaleString(i18n.language, {
        month: 'long',
      });
      const yearEndDate = utcDate.getFullYear();
      const active = true;
      dealsDates = {
        dateDealDate,
        monthEndDate,
        yearEndDate,
        active,
      };
      setHotDealDiscountText(getHotDealText(dealses, serviceInfo));
    }
  }, [serviceInfo, dealses]);

  const highlightCartClass = document.querySelector(
    '.tooltip-cart'
  ) as HTMLElement;

  useEffect(() => {
    if (highlightCartClass) {
      highlightCartClass.style.padding = '5px';
    }
  }, [highlightCartClass]);

  const { GOT_IT, NEXT, BACK } = LABELS(t);

  return (
    <>
      <TitleAndDescription pageName='ServiceDetail' title={serviceInfo[`name_${i18n.language}` ?? serviceInfo?.name]} />
      <Steps
        enabled={isMobile && enableHints}
        steps={serviceDetailMessages(t)}
        options={{
          tooltipClass: 'customTooltip',
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={() => {
          handleExit();
          window.location.href = '/cart';
        }}
        onExit={handleExit}
      />
      <div className={classes.wrapper}>
        <Link className={classes.back} href='/' onClick={() => navigate(-1)}>
          <PurpleArrowLeftIcon /> {t('Back to Solutions')}
        </Link>

        <Grid className={classes.grid} direction={'row'}>
          <GridItem col={{ default: 12, xsup: 8 }} className={classes.left}>
            <ServiceInfo
              data={serviceInfo}
              hotDeals={marketPlaceStore.dataTimeHotDeals}
              deals={dealsDates}
            />

            <OurTeam
              data={
                serviceInfo[`description_${i18n.language}`] ??
                serviceInfo?.description
              }
            />
            {width < 961 && (
              <CardManager
                name={serviceInfo?.product?.manager?.first_name}
                src={serviceInfo?.product?.manager?.image}
                alt='Manager'
                serviceId={serviceInfo?.id}
              />
            )}

            {serviceInfo?.certificate &&
              serviceInfo?.certificate?.length > 0 && (
                <Certificates data={serviceInfo?.certificate} />
              )}

            {serviceInfo?.case && serviceInfo?.case?.length > 0 && (
              <Cases data={serviceInfo.case} />
            )}
          </GridItem>

          {width > 960 && (
            <GridItem col={{ default: 12, xsup: 4 }} className={classes.price}>
              <div className={clsx(!enableHints && classes.sticky)}>
                {marketPlaceStore.dataTimeHotDeals.activeDeals &&
                  !marketPlaceStore.dataTimeHotDeals.featureDeals &&
                  isHotDeal && (
                    <div className={classes.deals}>
                      <div
                        className={clsx(
                          classes.deal,
                          spanishLocale && classes.spanish
                        )}
                      >
                        {dealses?.cost_change_type === 'MONEY' && (
                          <FormatPrice
                            currency={userStore.user?.currency?.symbol}
                            price={undefined}
                            withMinus={true}
                          />
                        )}
                        {hotDealDiscountText}
                      </div>
                      <div className={classes.timer}>
                        <HotDealsCounter inner small detail />{' '}
                      </div>
                    </div>
                  )}
                <div className={classes['price-block']}>
                  <Price
                    data={serviceInfo}
                    deals={dealsDates}
                    activeDeals={marketPlaceStore.dataTimeHotDeals.activeDeals}
                    featureDeals={
                      marketPlaceStore.dataTimeHotDeals.featureDeals
                    }
                    nameSource={serviceInfo.name}
                  />
                </div>
                <div className={classes.manager}>
                  <CardManager
                    name={serviceInfo?.product?.manager?.first_name}
                    src={serviceInfo?.product?.manager?.image}
                    alt='Manager'
                    serviceId={serviceInfo?.id}
                  />
                </div>
              </div>
            </GridItem>
          )}
        </Grid>

        {activeIds.length && (
          <RecommendedService ids={activeIds} nameSource={serviceInfo.name} />
        )}
        {serviceInfo && width < 961 && (
          <GridItem col={{ default: 12, xsup: 4 }} className={classes.price}>
            <Price
              data={serviceInfo}
              className='short'
              deals={dealsDates}
              activeDeals={marketPlaceStore.dataTimeHotDeals.activeDeals}
              featureDeals={marketPlaceStore.dataTimeHotDeals.featureDeals}
            />
          </GridItem>
        )}
      </div>
    </>
  );
});
