import React, { FC } from "react";
import classes from "./Menu.module.scss";
import { MarketplaceIcon } from "components/Icons/MarketplaceIcon";
import { ProjectIcon } from "components/Icons/ProjectIcon";
import { FinancesIcon } from "components/Icons/FinancesIcon";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { routers } from "constants/routers";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "hooks/useWindowWidth";
import UiIcon from "../Icon";
import { useUserStore } from 'contexts/UserContext';

interface MenuProps {
  active: boolean;
  disabled?: boolean;
  closeMenu: () => void;
}

export const Menu: FC<MenuProps> = ({ active, disabled, closeMenu }) => {
  const { t } = useTranslation();
  const { width } = useWindowWidth();
  const userStore = useUserStore();
  const { is_staff } = userStore?.user || {};
  const showDashboard = is_staff 

  return (
    <>
      {(active || width > 1024) && (
        <ul className={classes.menu}>
          {showDashboard && (
            <li className={clsx(classes.item, classes.mobile, classes.market)}>
              <NavLink
                to={routers.dashboard}
                className={navData =>
                  clsx(
                    classes.linkWrap,
                    navData.isActive && classes.active,
                    disabled && classes.disabled,
                  )
                }
              >
                <UiIcon name='ChartBarIcon' additionalClassName={classes.dashboardIcon} />
                <div className={classes.wrap}>
                  <span className={classes.link}>{t("Dashboard")}</span>
                </div>
              </NavLink>
            </li>
          )}
          {width > 1024 && (
            <li className={clsx(classes.item, classes.mobile, classes.market)}>
              <NavLink
                to={routers.solutions}
                className={navData =>
                  clsx(
                    classes.linkWrap,
                    navData.isActive && classes.active,
                    disabled && classes.disabled,
                  )
                }
              >
                <MarketplaceIcon />
                <div className={classes.wrap}>
                  <span className={classes.link}>{t("Solutions")}</span>
                </div>
              </NavLink>
            </li>
          )}
          <li
            className={clsx(
              classes.item,
              classes.inside,
              classes.source,
              active && classes.active,
            )}
          >
            <NavLink
              to={routers.projects}
              className={navData =>
                clsx(
                  classes.linkWrap,
                  classes.projects,
                  navData.isActive && classes.active,
                  disabled && classes.disabled,
                )
              }
              onClick={() => closeMenu()}
            >
              <ProjectIcon />
              <div className={classes.wrap}>
                <span className={classes.link}>{t("Projects")}</span>
              </div>
            </NavLink>
          </li>
          <li
            className={clsx(
              classes.item,
              classes.inside,
              classes.finances,
              active && classes.active,
            )}
          >
            <NavLink
              to={routers.finances}
              className={navData =>
                clsx(
                  classes.linkWrap,
                  navData.isActive && classes.active,
                  disabled && classes.disabled,
                )
              }
              onClick={() => closeMenu()}
            >
              <FinancesIcon />
              <div className={classes.wrap}>
                <span className={classes.link}>{t("Finances")}</span>
              </div>
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );
};