import React, { FC } from "react";
import "../styles/main.scss";
import { DisclaimerContainer } from "containers/Disclaimer";

interface Props {
  section: string;
}

export const Disclaimer: FC<Props> = ({ section }) => {
  return <DisclaimerContainer />
};
