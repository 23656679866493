import React, { FC, ReactNode } from "react";
import classes from "./Type.module.scss";
import clsx from "clsx";

export interface TypeProps {
  tag: "h1" | "h2" | "h3" | "h4" | "p" | "span";
  size?: "lg" | "md" | "sm" | "xs" | "xs-bold" | "simple" | "over" | "subtitle";
  color?: string;
  children: string;
  className?: ReactNode;
}

export const Type: FC<TypeProps> = ({
  size,
  tag,
  children,
  color,
  className: additionalClassName,
}) => {
  const className = clsx(size && classes[size], additionalClassName);

  const Tag = tag;

  return (
    <Tag className={className} style={{ color: color }}>
      {children}
    </Tag>
  );
};
