import * as React from "react"
import { SVGProps } from "react"
import { RUSSIAN_LOCALE } from "utils"

export const TimerGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="url(#paint0_linear_7355_6181)"
      fillRule="evenodd"
      d="M13 3.5h12c.82 0 1.5-.68 1.5-1.5S25.82.5 25 .5H13c-.82 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5Zm6 40c-10.48 0-19-8.52-19-19s8.52-19 19-19 19 8.52 19 19-8.52 19-19 19Zm0-35c-8.82 0-16 7.18-16 16s7.18 16 16 16 16-7.18 16-16-7.18-16-16-16Zm0 17c-.82 0-1.5-.68-1.5-1.5V14c0-.82.68-1.5 1.5-1.5s1.5.68 1.5 1.5v10c0 .82-.68 1.5-1.5 1.5Z"
      clipRule="evenodd"
    />
    <defs>
      {
        RUSSIAN_LOCALE &&
        <linearGradient id="paint0_linear_7355_6181" x1="14.2564" y1="2.5" x2="40.4908" y2="42.6854" gradientUnits="userSpaceOnUse">
          <stop stop-color="#E47267" />
          <stop offset="1" stop-color="#EB5945" />
        </linearGradient>
      }
      {
        !RUSSIAN_LOCALE &&
        <linearGradient
          id="paint0_linear_7355_6181"
          x1={28.166}
          x2={24.829}
          y1={0.5}
          y2={43.948}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65B2FF" />
          <stop offset={1} stopColor="#6638F4" />
        </linearGradient>
      }
    </defs>
  </svg>
)
