import React from 'react';
import { authStore } from 'stores/authStore';

const AuthContext = React.createContext<any>(null);

export const AuthProvider = ({ children }: any) => {

    return (
        <AuthContext.Provider value={authStore}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuthStore = () => {
    const context = React.useContext(AuthContext)
    if (context === null) {
        throw new Error(
            "You have forgotten to wrap your root component with AuthProvider"
        );
    }
    return context
}