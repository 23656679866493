import serviceDetailClasses from './ServiceDetail.module.scss';
import cartClasses from '../../components/Header/Header.module.scss';
import priceClasses from './Price/Price.module.scss';
import cartManagerClasses from './CardManager/CardManager.module.scss';
import recommendedServiceClasses from '../RecommendedService/RecommendedService.module.scss';

export const serviceDetailMessages = (t: any) => [
    {
        title: t('On a service page card, you will find the detailed information about this item, including the type, name, pricing and payment details, description of the stages of work on the project'),
        intro: '',
    },
    {
        element: `.${serviceDetailClasses.manager} .${cartManagerClasses.container}`,
        intro: t('If you have any questions about a specific service, contact our manager by clicking "Get in touch" button next to the description'),
        position: 'left',
        scrollTo: 'tooltip',
    },
    {
        element: `.${recommendedServiceClasses.wrapper}`,
        intro: t('You can see our recommendations on another solutions that can also suit your goals'),
        scrollToElement: true,
        scrollTo: 'tooltip',
        highlightClass: 'tooltip-recommended',
    },
    {
        element: `.${priceClasses.buttons}`,
        intro: t('When you are satisfied with the description of the solution and are ready to order it, click "Add to Cart"'),
        scrollTo: 'tooltip',
    },
    {
        element: `.${cartClasses.cart}`,
        intro: t('Click on the cart icon to go to your shopping list'),
        scrollTo: 'tooltip',
        highlightClass: 'tooltip-cart',
    },
];
