import * as React from "react";
import { SVGProps } from "react";

export const HeartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 30 30" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2273 24.4721L14.2294 24.4738L15 25.1429L15.7727 24.4721C18.891 21.7754 21.3632 19.6309 23.0726 17.6365C24.7661 15.6605 25.56 14 25.56 12.2943C25.56 9.51812 23.3024 7.3733 20.4 7.3733C18.7473 7.3733 17.1373 8.11413 16.0927 9.27479L15 10.489L13.9073 9.27479C12.8627 8.11413 11.2527 7.3733 9.6 7.3733C6.69757 7.3733 4.44 9.51812 4.44 12.2943C4.44 14 5.23386 15.6605 6.92744 17.6365C8.63684 19.6309 11.109 21.7754 14.2273 24.4721ZM13.9532 7.4354C14.3362 7.7168 14.6879 8.0336 15 8.38038C15.3121 8.0336 15.6638 7.7168 16.0468 7.4354C17.2691 6.5374 18.8102 6 20.4 6C24.096 6 27 8.75804 27 12.2943C27 16.6087 22.92 20.145 16.74 25.4894L15 27L13.26 25.4894C7.08 20.145 3 16.6087 3 12.2943C3 8.75804 5.904 6 9.6 6C11.1898 6 12.7309 6.5374 13.9532 7.4354Z" />
  </svg>
);

export const HeartBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.49481 17.9347L10.7998 19.1L12.1048 17.9347C16.7398 13.8119 19.7998 11.0839 19.7998 7.75561C19.7998 5.02765 17.6218 2.90002 14.8498 2.90002C13.7662 2.90002 12.7128 3.24241 11.8416 3.8248C11.4537 4.08408 11.102 4.39093 10.7998 4.73632C10.4976 4.39093 10.1459 4.08408 9.75802 3.8248C8.88682 3.24241 7.8334 2.90002 6.74981 2.90002C3.9778 2.90002 1.7998 5.02765 1.7998 7.75561C1.7998 11.0839 4.85981 13.8119 9.49481 17.9347ZM10.7998 17.0488L11.0879 16.7915C13.436 14.7029 15.2601 13.0738 16.515 11.5678C17.7541 10.0807 18.2698 8.90704 18.2698 7.75561C18.2698 5.9021 16.8066 4.43002 14.8498 4.43002C13.7355 4.43002 12.6487 4.94669 11.9513 5.7438L10.7998 7.05983L9.64834 5.7438C8.9509 4.94669 7.86413 4.43002 6.74981 4.43002C4.79305 4.43002 3.3298 5.9021 3.3298 7.75561C3.3298 8.90704 3.84548 10.0807 5.0846 11.5678C6.33956 13.0738 8.16364 14.7029 10.5117 16.7915L10.5139 16.7935L10.7998 17.0488Z" />
  </svg>
);

export const HeartBoldFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M10.883 19.3322L10.8823 19.3316C8.29733 17.0322 6.1924 15.1566 4.72787 13.399C3.26852 11.6476 2.5 10.0766 2.5 8.3951C2.5 5.6498 4.68642 3.5 7.5 3.5C8.59971 3.5 9.67427 3.84803 10.5646 4.4432C10.9603 4.70775 11.3178 5.01991 11.6237 5.36957L12 5.79964L12.3763 5.36957C12.6822 5.01991 13.0397 4.70775 13.4354 4.4432C14.3257 3.84803 15.4003 3.5 16.5 3.5C19.3136 3.5 21.5 5.6498 21.5 8.3951C21.5 10.0766 20.7315 11.6476 19.2721 13.399C17.8076 15.1566 15.7027 17.0322 13.1177 19.3316L13.117 19.3322L12 20.3297L10.883 19.3322ZM11.667 19.0938L12 19.3912L12.333 19.0938L12.6525 18.8086C12.6526 18.8084 12.6528 18.8083 12.6529 18.8082C15.2515 16.4967 17.3108 14.6593 18.7344 12.9509C20.1458 11.2571 20.8 9.84181 20.8 8.3951C20.8 6.0512 18.9419 4.2 16.5 4.2C15.1144 4.2 13.7708 4.83884 12.9031 5.83051L12 6.86269L11.0969 5.83051C10.2292 4.83884 8.88565 4.2 7.5 4.2C5.05806 4.2 3.2 6.0512 3.2 8.3951C3.2 9.84181 3.85424 11.2571 5.26565 12.9509C6.68931 14.6594 8.74878 16.497 11.3475 18.8086L11.3476 18.8086L11.3493 18.8101L11.35 18.8108L11.667 19.0938ZM17.9661 12.3107C16.6031 13.9465 14.6131 15.7262 12 18.0506C9.38689 15.7262 7.39692 13.9465 6.03389 12.3107C4.6917 10.7 4.2 9.50712 4.2 8.3951C4.2 6.62008 5.5936 5.2 7.5 5.2C8.59063 5.2 9.6621 5.7093 10.3443 6.489L11.6237 7.95125L12 8.38132L12.3763 7.95125L13.6557 6.489C14.3379 5.7093 15.4094 5.2 16.5 5.2C18.4064 5.2 19.8 6.62008 19.8 8.3951C19.8 9.50712 19.3083 10.7 17.9661 12.3107Z" fill="#EA4A70" stroke="#EA4A70" />
  </svg>
);
