import { FC, useState, useEffect, useCallback } from "react";
import classes from "./Switch.module.scss";
import { Link, NavLink } from "react-router-dom";
import clsx from "clsx";
import { ShapeGradientIcon } from "components/Icons/ShapeGradientIcon";
import { WalletGradientIcon } from "components/Icons/WalletGradientIcon";
import { LockGradientIcon } from "components/Icons/LockGradientIcon";
import { ArrowDownIcon } from "components/Icons/ArrowDownIcon";
import { useWindowWidth } from "hooks/useWindowWidth";
import { routers } from "constants/routers";
import { useTranslation } from "react-i18next";

interface Props {
  className: string;
  section: string;
  setBusinessTypes: (val: any) => void;
  setCompanySizes: (val: any) => void;
  currentStep?: number;
}

export const Switch: FC<Props> = ({ className: auditionalClassname, section, setBusinessTypes, setCompanySizes, currentStep }) => {
  const { isMediaTablet, width } = useWindowWidth();
  const { origin, pathname } = window.location;
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();


  useEffect(() => {
    if (isMediaTablet) {
      if (
        pathname !== `${routers.personal}` &&
        pathname !== `${routers.business}` &&
        pathname !== `${routers.changePassword}`
      ) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }, [pathname]);

  return (
    <div
      className={clsx(
        classes.wrapper,
        auditionalClassname && classes[auditionalClassname]
      )}
    >
      {isMediaTablet && <div className={classes.mobileTitle}>{t("Account")}</div>}
      <div className={classes.list}>
        <NavLink
          to={section && width <= 960 ? "/account" : "/account/personal"}
          className={clsx(classes.link, (currentStep === 1 || section === "personal")  && classes.active)}
        >
          <div className={classes.icon}>
            <ShapeGradientIcon />
          </div>
          <div className={classes.back}>
            <ArrowDownIcon fill={"#898989"} width={"16px"} height={"8px"} />
          </div>
          <div className={classes.name}>{t("Personal information")}</div>
        </NavLink>
        <NavLink
          to={section && width <= 960  ? "/account" : "/account/business"}
          className={clsx(classes.link, (currentStep === 2 || section === "business") && classes.active)}
        >
          <div className={classes.icon}>
            <WalletGradientIcon />
          </div>
          <div className={classes.back}>
            <ArrowDownIcon fill={"#898989"} width={"16px"} height={"8px"} />
          </div>
          <div className={classes.name}>{t("Business information")}</div>
        </NavLink>
        <NavLink
          to={section && width <= 960   ? "/account" : "/account/change-password"}
          className={clsx(classes.link, (currentStep === 3 || section === "change-password") && classes.active)}
        >
          <div className={classes.icon}>
            <LockGradientIcon />
          </div>
          <div className={classes.back}>
            <ArrowDownIcon fill={"#898989"} width={"16px"} height={"8px"} />
          </div>
          <div className={classes.name}>{t("Change password")}</div>
        </NavLink>
      </div>
    </div>
  );
};
