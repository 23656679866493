import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";
import translationEN from './locales/en/translations.json';
import translationES from './locales/es/translations.json';
import translationRU from './locales/ru/translations.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import {RUSSIAN_LOCALE} from "../utils";


const resources = {
    en: {
      translation: translationEN
    },
    es: {
        translation: translationES
    },
    ru: {
        translation: translationRU
    }
  };


export const defaultLanguage = RUSSIAN_LOCALE ? 'ru' : 'en';


i18n
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: defaultLanguage,
    nsSeparator: ':::',
    keySeparator: '::',
    resources,
    detection: {
        order: ['querystring', 'localStorage'],
        lookupLocalStorage: 'kitGlobalLng',
    }
  })

export default i18n;