export const routers = {
    dashboard: '/dashboard',
    solutions: '/solutions',
    hotdeals: '/solutions/hotdeals',
    projects: '/projects',
    finances: '/finances',
    account: '/account',
    personal: '/account/personal',
    business: '/account/business',
    changePassword: '/account/change-password',
    favorites: '/favorites',
    cart: '/cart',
    login: '/login',
    restore: '/restore',
    disclaimer: '/pricing-disclaimer',
    searchResults: '/search-results',
}