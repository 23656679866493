export const getFormattedDate = (date: Date): string => {
	const day = ('0' + date.getDate()).slice(-2);
	const month = ('0' + (date.getMonth() + 1)).slice(-2);
	const year = String(date.getFullYear()).slice(-2); // We use the last two digits of the year

	return `${day}.${month}.${year}`;
}

export const getFormattedTime = (date: Date): string => {
	const hours = ('0' + date.getHours()).slice(-2);
	const minutes = ('0' + date.getMinutes()).slice(-2);
	return `${hours}:${minutes}`;
}