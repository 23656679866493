import { FC } from "react";
import Footer from "components/Footer";
import { Header } from "components/Header";
import { useOutlet, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useUserStore } from "contexts/UserContext";
import {PageLoader} from "./PageLoader";
import {observer} from "mobx-react-lite";
import {userStore} from "../stores/userStore";

interface Props {
  children?: any;
}

export const BaseTemplate: FC<Props> = observer(() => {
  const outlet = useOutlet();
  const location = useLocation();
  const UserStore = useUserStore();

  const { isDataLoading } = userStore

  const renderBaseTemplate = (
    <section
      className={clsx(
        "app__section",
        location.pathname === "/projects" || location.pathname === "/finances" || !UserStore?.user?.currency
          ? "app__section--gradient"
          : null
      )}
    >
      {userStore?.isComplete && <Header/>}
      <div className="layout-container">
        {!isDataLoading && outlet}
        <Footer/>
      </div>
    </section>
  )

  return isDataLoading ? <PageLoader children={renderBaseTemplate}/> : renderBaseTemplate;
});

