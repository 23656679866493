import { useState } from "react";
import { ShowNotification } from "tools/showNotification";

export const useTopUpBalance = (onClose: () => void) => {
  const [value, setValue] = useState("");

  const handleTopUpBalance = (finalPrice: number) => {
    ShowNotification({ type: "success", children: finalPrice });
    setValue("");
    onClose();
  };

  return { handleTopUpBalance, value, setValue };
};
