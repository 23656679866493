import * as React from "react"
import { SVGProps } from "react"

export const ChevronIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 4.168v1.579c0 .107-.04.208-.103.271L8.778 12l6.119 5.98a.386.386 0 0 1 .103.272v1.58c0 .136-.116.216-.198.136l-7.596-7.423c-.275-.27-.275-.821 0-1.089l7.596-7.423c.082-.082.198-.002.198.135Z"
      fill="#898989"
    />
  </svg>
)