import { FC } from 'react';

import clsx from 'clsx';

import { CheckStrokeIcon } from 'components/Icons/CheckStrokeIcon';

import classes from './OurTeam.module.scss';

interface Props {
  data: ''
}

export const OurTeam: FC<Props> = ({
data
}) => {
  
  return (
    <div className={classes.wrapper}>
      <div dangerouslySetInnerHTML={{__html: data}} />
    </div>
  );
}