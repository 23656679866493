import {FC, useState, useCallback} from "react";

import { Switch } from "./Switch";
import { Personal } from "./Personal";
import { Business } from "./Business";
import { Grid, GridItem } from "components/Grid";
import { ChangePassword } from "./ChangePassword";

import clsx from "clsx";
import classes from "./Account.module.scss";
import { useUserStore } from "contexts/UserContext";
import { observer } from "mobx-react-lite";

import { Steps } from 'intro.js-react';
import '../CardsContainer/IntroTooltip.css';
import 'intro.js/introjs.css';
import { accountMessages } from './hints';
import {isOnboardingActive, setOnboardingOptions} from "../../tools/onboarding";
import {useWindowWidth} from "../../hooks/useWindowWidth";
import {LABELS} from "../../constants/onboarding";
import {t} from "i18next";


interface Props {
  section: string;
}

export const AccountContainer: FC<Props> = observer(({ section }) => {
  const isMobile = useWindowWidth().isSmallLaptop;
  const userStore = useUserStore()
  const user = userStore.user
  const enableHints = isOnboardingActive(userStore?.pagesToOnboard?.profile)

  const handleExit = useCallback(() => {
    setOnboardingOptions({profile: true});
    setCurrentStep(0)
    userStore.enableOnboarding(true)
  }, [userStore]);

  const [businessTypes, setBusinessTypes] = useState<any>([]);
  const [companySizes, setCompanySizes] = useState<any>([]);

  const personalData = {
    fullName: user.full_name,
    email: user.email,
    phone: user.phone_number ? user.phone_number.substring(1) : '',
  }

  const businessData = {
    companyName: user.company_name,
    companyLink: user.company_link,
    companySize: user.company_size,
    businessType: user.company_industry
  }

  const [currentStep, setCurrentStep] = useState(0);

  const getParams = useCallback(async () => {
    userStore.loadUser()
  }, []);

  const { DONE, NEXT, BACK } = LABELS(t);

  return (
    <>
      <div className={classes.wrapper}>
        <Steps
          enabled={isMobile && enableHints}
          steps={accountMessages(t)}
          options={{
            tooltipClass: 'customTooltip',
            doneLabel: DONE,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={currentStep}
          onChange={(step: number) => {
            step >= 0 && setCurrentStep(step)
          }}
          onComplete={() => {
            setCurrentStep(0)
            handleExit()
          }}
          onExit={() => {
            handleExit()
          }}
        />
        <Grid direction={"row"} className={classes.container}>
          <GridItem col={{ default: 12, xsup: 4, md: 3 }}>
            <Switch
              className={clsx(section && "chosen")}
              section={currentStep ? '' : section}
              setBusinessTypes={setBusinessTypes}
              setCompanySizes={setCompanySizes}
              currentStep={currentStep}
            />
          </GridItem>
          <GridItem col={{ default: 12, xsup: 8, md: 6 }}>
            {section && (
              <div className={classes.inner}>
                {((currentStep === 1) || (section === "personal" && personalData && !currentStep)) && (
                  <Personal defaultValue={personalData} getParams={getParams} />
                )}
                {((currentStep === 2) || (section === "business" && businessData && !currentStep)) && (
                  <Business defaultValue={businessData} getParams={getParams} />
                )}
                {(((currentStep === 3) || (currentStep === 4)) || (section === "change-password" && !currentStep)) && <ChangePassword />}
              </div>
            )}
          </GridItem>
        </Grid>
      </div>
    </>
  );
});
