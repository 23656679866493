import * as React from "react"
import { SVGProps } from "react"

export const ThunderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.563 7.018H12.26l3.869-4.89a.157.157 0 0 0-.123-.253h-7.49a.155.155 0 0 0-.135.078l-5.06 8.74a.156.156 0 0 0 .134.235h3.406l-1.746 6.984c-.037.152.147.26.26.15L16.67 7.286a.155.155 0 0 0-.108-.267Zm-9.176 7.289L8.565 9.6H5.49l3.703-6.395h4.387L9.512 8.348h4.12l-6.245 5.959Z"
    />
  </svg>
);