import { FC, Suspense } from "react";
import { useLoaderData, Await } from "react-router-dom";
import CartSkeleton from "./Skeleton/CartSkeleton/CartSkeleton";

export interface Props {
  children?: any;
}

export const LoaderWrapperCart: FC<Props> = ({ children }) => {
  const loadedData: any = useLoaderData();

  return (
    <Suspense fallback={<CartSkeleton />}>
      <Await resolve={loadedData?.loader}>{children}</Await>
    </Suspense>
  );
};
