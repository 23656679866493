import { FC, useEffect, useState } from "react";
import { Column, usePagination, useTable, useSortBy, Row } from "react-table";

import classes from "./Table.module.scss";
import { SearchIcon } from "../Icons/SearchIcon";
import clsx from "classnames";
import { DownloadIcon } from "../Icons/DownloadIcon";
import { NavLink } from "react-router-dom";
import { Fader } from "../Fader";
import FilterTableIcon from "../Icons/FilterTableIcon";
import FilterMobileTableIcon from "../Icons/FilterMobileTableIcon";
import { Pagination } from "../Pagination";
import { SORT_BY } from "../../constants/queryParamTypes";
import _ from "lodash";
import { handleDownload } from "../../tools/fileDownload";
import { Bill } from "types/finances";

interface TableCellProps {
  value?: string | null | undefined;
  className?: string;
  filePath?: string;
  link?: string;
  linkOptions?: boolean;
  description?: {
    text: string | null | undefined;
    className: string;
  };
}

interface TableProps {
  data: TableCellProps[];
  columns: Column[];
  tableActive?: boolean;
  additionalClassNames?: string | string[];
  id?: string;
  customPageCount?: number;
  getDataByPage?: (currentPage?: number | undefined) => void;
  defaulPageSize?: number;
  mobileModeTitle?: string;
  isMobile?: boolean;
  filter?: string;
  handleShowModalOnButton?: (items: Bill[]) => void;
}

const Table: FC<TableProps> = ({
  data,
  columns,
  tableActive,
  additionalClassNames,
  id,
  customPageCount,
  getDataByPage,
  defaulPageSize,
  mobileModeTitle,
  isMobile = true,
  filter,
  handleShowModalOnButton,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    previousPage,
    prepareRow,
    setPageSize,
    pageCount,
    page,
    nextPage,
    gotoPage,
    state: { pageIndex },
  } = useTable({ columns, data }, useSortBy, usePagination);

  const [currentPage, setCurrentPage] = useState(pageIndex + 1);
  const [sortedData, setSortedData] = useState<any>(page);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: "desc" | "asc";
  }>({});

  // TODO: Сделать серверный поиск

  useEffect(() => {
    setSortedData(page);
  }, [page, filter]);

  const handleSort = (type: string, columnId?: string) => {
    const currentDirection = sortDirections[columnId || type] || "asc";
    const newSortDirection: "desc" | "asc" =
      currentDirection === "asc" ? "desc" : "asc";

    let sortedPage;

    if (type === SORT_BY.DATE || columnId === "issued") {
      sortedPage = _.orderBy(
        sortedData,
        [
          (row: Row<{}>) => {
            const dateString = row.values?.issued?.value;
            const parts = dateString.split(".");
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1;
            const year = parseInt(parts[2], 10);
            const date = new Date(year, month, day);
            return date.getTime();
          },
        ],
        [newSortDirection],
      );
    } else if (
      type === SORT_BY.NAME &&
      (columnId === "projects" ||
        columnId === "invoices" ||
        columnId === "action")
    ) {
      sortedPage = _.orderBy(
        sortedData,
        [
          (row: Row<{}>) => {
            if (columnId === "action") {
              return row.values[columnId]?.button?.text;
            }
            return row.values[columnId || "projects"]?.value;
          },
        ],
        [newSortDirection],
      );
    } else if (columnId === "amount") {
      sortedPage = _.orderBy(
        sortedData,
        [
          (row: Row<{}>) => {
            const valueToNumber = Number(
              row.values.amount.value.replace(/\s/g, ""),
            );
            return Number(valueToNumber);
          },
        ],
        [newSortDirection],
      );
    }

    const newSortDirections = {
      ...sortDirections,
      [columnId || type]: newSortDirection,
    };

    setSortedData(sortedPage);
    setSortDirections(newSortDirections);
  };

  useEffect(() => {
    setPageSize(defaulPageSize || 10);
  }, [defaulPageSize, setPageSize]);

  useEffect(() => {
    getDataByPage && getDataByPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    gotoPage(currentPage - 1);
  }, [currentPage, gotoPage, pageIndex]);

  const mainContent = (value: TableCellProps) => {
    if (value?.filePath) {
      return (
        <span
          className={clsx(
            classes.table__link,
            value?.className?.length && String(value?.className),
          )}
          onClick={() =>
            value?.filePath && handleDownload(value?.filePath, true)
          }
        >
          {value?.value}
          {value?.filePath && (
            <DownloadIcon
              onClick={() => handleDownload(value?.filePath as string, true)}
            />
          )}
        </span>
      );
    } else if (value?.link && value?.linkOptions) {
      return (
        <NavLink
          to={process.env.REACT_APP_PUBLIC_URL + value?.link}
          className={clsx(
            classes.table__firstlink,
            classes.table__link,
            value?.className?.length && String(value?.className),
          )}
        >
          {value?.value}
        </NavLink>
      );
    } else {
      return value?.value;
    }
  };

  const mobileOptions = isMobile && (
    <div className={classes.table__row_mob}>
      <button
        className={classes.table__sort}
        onClick={() => handleSort(SORT_BY.DATE)}
      >
        <span>{mobileModeTitle}</span>
        <FilterMobileTableIcon />
      </button>

      <button className={classes.table__search_mob}>
        <SearchIcon />
      </button>

      <button
        className={classes.table__list_mob}
        onClick={() => handleSort(SORT_BY.NAME, "projects")}
      >
        <FilterTableIcon />
      </button>
    </div>
  );

  return (
    <>
      <Fader active={tableActive as boolean}>
        <div
          className={clsx(
            classes.table,
            additionalClassNames && String(additionalClassNames),
          )}
          id={id}
        >
          {mobileOptions}
          <table {...getTableProps()}>
            <thead>
              {headerGroups?.map(headerGroup => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={classes.table__row}
                >
                  <th className={classes.table__cell}>
                    {/* <button className={classes.table__search}>
                      <SearchIcon />
                    </button> */}
                  </th>
                  {headerGroup?.headers?.map((column: any) => {
                    if (column?.header) {
                      return (
                        <th
                          className={classes.table__cell}
                          {...column.getHeaderProps()}
                        >
                          {column.render("header")}
                          <button
                            className={classes.table__sort}
                            onClick={() => {
                              handleSort(SORT_BY.NAME, column.id);
                            }}
                          >
                            <FilterTableIcon />
                          </button>
                        </th>
                      );
                    } else return null;
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {sortedData?.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={classes.table__content}>
                    {row?.cells?.map((cell: any) => {
                      const { value } = cell;
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classes.table__cell}
                        >
                          <div className={classes.table__cell__container}>
                            <div className={classes.table__header}>
                              {value?.currency?.symbol && (
                                <span className={classes.currency}>
                                  {value?.currency?.symbol}
                                </span>
                              )}
                              {mainContent(value)}
                            </div>
                            <div className={value?.description?.className}>
                              {value?.description?.text}
                            </div>

                            {value?.button && (
                              <button
                                className={clsx(
                                  classes.table__button,
                                  value?.button?.className?.length &&
                                    String(value?.button?.className),
                                  value?.button?.cancelled &&
                                    classes.table__button_cancelled,
                                )}
                                disabled={value?.button?.disabled}
                                onClick={() =>
                                  handleShowModalOnButton
                                    ? handleShowModalOnButton([
                                        {
                                          serial_id: value.serial_id,
                                          project_number: value.project_number,
                                          amount: value.amount,
                                          project_name: value.project_name,
                                        },
                                      ])
                                    : handleDownload(
                                        value?.button?.filePath as string,
                                        true,
                                      )
                                }
                              >
                                {value?.button?.text}
                              </button>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Fader>
      {(customPageCount || pageCount) > 1 && (
        <div className={classes.pagination}>
          <Pagination
            currentPage={currentPage}
            totalPages={customPageCount || pageCount}
            setPageNumber={setCurrentPage}
            nextPage={nextPage}
            prevPage={previousPage}
          />
        </div>
      )}
    </>
  );
};

export default Table;
