import * as React from "react"
import { SVGProps } from "react"
import { RUSSIAN_LOCALE } from "utils";

export const HeartGradienIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.55 19.7052L12 21L13.45 19.7052C18.6 15.1243 22 12.0932 22 8.3951C22 5.36403 19.58 3 16.5 3C15.296 3 14.1255 3.38042 13.1575 4.02753C12.7266 4.31562 12.3358 4.65656 12 5.04033C11.6642 4.65656 11.2734 4.31562 10.8425 4.02753C9.87446 3.38042 8.70399 3 7.5 3C4.42 3 2 5.36403 2 8.3951C2 12.0932 5.4 15.1243 10.55 19.7052ZM12 18.7208L12.3201 18.435C14.9291 16.1143 16.9558 14.3042 18.3502 12.6308C19.727 10.9785 20.3 9.67447 20.3 8.3951C20.3 6.33564 18.6742 4.7 16.5 4.7C15.2619 4.7 14.0543 5.27407 13.2794 6.15975L12 7.62201L10.7206 6.15975C9.94566 5.27407 8.73813 4.7 7.5 4.7C5.32583 4.7 3.7 6.33564 3.7 8.3951C3.7 9.67447 4.27297 10.9785 5.64977 12.6308C7.04417 14.3042 9.07092 16.1143 11.6799 18.435L11.6823 18.4372L12 18.7208Z" fill="url(#paint0_linear_5600_41438)" />
    <defs>
      {RUSSIAN_LOCALE &&
        <linearGradient id="paint0_linear_5600_41438" x1="6.87179" y1="3" x2="16.6835" y2="21.8966" gradientUnits="userSpaceOnUse">
          <stop stop-color="#E47267" />
          <stop offset="1" stop-color="#EB5945" />
        </linearGradient>
      }
      {!RUSSIAN_LOCALE &&
        <linearGradient id="paint0_linear_5600_41438" x1="0.560002" y1="2.32" x2="18.88" y2="23.84" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6E3CFF" />
          <stop offset="1" stop-color="#4BA5FF" />
        </linearGradient>
      }
    </defs>
  </svg>
);