import { FC, useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

import clsx from 'clsx';
import { ProductCard } from 'containers/CardsContainer/ProductList/ProductCard';
import classes from './RecommendedService.module.scss';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { queryParamsHandler } from '../CardsContainer/utils';
import { marketPlaceStore } from '../../stores/marketPlaceStore';
import { userStore } from '../../stores/userStore';
import Service from '../../services/service';
import { toJS } from 'mobx';
import YouMayAlsoNeedSkeleton from 'components/Skeleton/YouMayAlsoNeedSkeleton/YouMayAlsoNeedSkeleton';

interface Props {
  ids?: any[];
  title?: string;
  nameSource?: string;
}

export const RecommendedService: FC<Props> = observer(
  ({ ids, title, nameSource }) => {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const { currency, language } = userStore.user;
    const innerProducts = marketPlaceStore?.services?.slice(0, 5);
    const params = queryParamsHandler({
      pageSize: 7,
      ids,
      page: 0,
    });

    const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    Service.getServices(params)
      .then((services: any) => {
        setProducts(ids?.length ? services?.data?.results : innerProducts);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currency?.id, language?.id, ids?.length]);

    return (
      <div className={classes.wrapper}>
        <h3 className={classes.title}>
          {title ? title : t('Others usually choose')}
        </h3>
        {isLoading ? <YouMayAlsoNeedSkeleton /> : <div className={classes['swiper-wrapper']}>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={20}
            navigation={true}
            modules={[Navigation]}
            className={clsx(classes.swiper)}
            watchSlidesProgress
            loop={false}
            scrollbar={{ draggable: true }}
          >
            <div>
              {products?.length
                ? products?.map((item: any) => (
                    <SwiperSlide
                      className={classes['swiper-slide']}
                      key={item.id}
                    >
                      <div className={clsx(classes.content)}>
                        <ProductCard
                          isSmall={true}
                          data={item}
                          hotDeals={marketPlaceStore.dataTimeHotDeals}
                          nameSource={nameSource}
                        />
                      </div>
                    </SwiperSlide>
                  ))
                : null}
            </div>
          </Swiper>
        </div>}
      </div>
    );
  }
);
