import classes from "./FormTopUpBalance.module.scss";
import { Button } from "components/Button";
import { useTranslation } from "react-i18next";
import { Input } from "components/Input";
import { userStore } from "stores/userStore";
import { formatBalanceForLocale } from "../formatBalanceForLocale";
import { RubIcon } from "components/Icons/RubIcon";
import { FormTopUpBalanceProps } from "./FormTopUpBalance.props";
import { useEffect } from "react";
import { financesStore } from "stores/financesStore";

export const FormTopUpBalance = ({
  handleTopUpBalance,
  value,
  setValue,
}: FormTopUpBalanceProps) => {
  const { loadConditions, conditions } = financesStore;
  const feeService = conditions?.commission_prc_in;
  const { t } = useTranslation();
  const currency = userStore.user.currency?.char_code;

  const handleChangeValue = (e: any) => {
    const value = e.replace(/\s+/g, ""); // We remove spaces because in input we can have a division of "1000" into spaces "1 000"

    if (/^\d*$/.test(value)) {
      const numValue = Number(value);
      setValue(numValue.toString());
    }
  };

  useEffect(() => {
    loadConditions();
  }, []);

  const isDisabled = Number(value) <= 0;
  const totalValue = (Number(value) * Number(feeService)) / 100 + Number(value);
  const formattedTotalValue = formatBalanceForLocale(totalValue, currency);

  return (
    <div className={classes.inner}>
      <div className={classes.header}>
        <h3 className={classes.title}>{t("Top up balance")}</h3>
        <p className={classes["title-description"]}>
          {t("Replenishment is possible only with bank cards")}
        </p>
      </div>

      <div>
        <Input
          iconJSX={<RubIcon />}
          onChange={handleChangeValue}
          type={"text"}
          placeholder={t("Value")}
          value={value.toString()}
          inputForCurrency={true}
        />
        <p className={classes["input-description"]}>
          {`${t("Commission for replenishment")} ${feeService}%${
            totalValue > 0 ? ` = ${formattedTotalValue}` : ""
          } `}
        </p>
      </div>

      <Button
        theme={"primary"}
        size="middle"
        disabled={isDisabled}
        className={classes.btn}
        onClick={() => handleTopUpBalance(totalValue)}
      >
        {t("Top up")}
      </Button>
    </div>
  );
};
