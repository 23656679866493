import * as React from "react"
import { SVGProps } from "react"

export const ResetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.731 3.399a6.952 6.952 0 0 1 4.25.767c1.293.7 2.32 1.79 2.923 3.11a6.53 6.53 0 0 1 .426 4.204 6.643 6.643 0 0 1-2.244 3.61 6.905 6.905 0 0 1-4.013 1.566 6.935 6.935 0 0 1-4.17-1.106 6.69 6.69 0 0 1-2.653-3.334l-.158-.438a.2.2 0 0 1 .123-.257l.908-.313a.2.2 0 0 1 .254.122l.16.443a5.352 5.352 0 0 0 2.122 2.667c.984.641 2.155.952 3.336.885a5.525 5.525 0 0 0 3.21-1.253A5.315 5.315 0 0 0 16 11.184a5.225 5.225 0 0 0-.34-3.363 5.38 5.38 0 0 0-2.34-2.488 5.561 5.561 0 0 0-3.399-.614A5.502 5.502 0 0 0 6.813 6.24L5.28 7.647a.2.2 0 0 0 .136.347h2.338c.11 0 .2.09.2.2v.934a.2.2 0 0 1-.2.2H3.182A.674.674 0 0 1 2.5 8.66V4.194c0-.11.09-.2.2-.2h.964c.11 0 .2.09.2.2v2.47a.2.2 0 0 0 .335.148l1.672-1.537A6.878 6.878 0 0 1 9.731 3.4Z"
      fill="#8362F3"
    />
  </svg>
)