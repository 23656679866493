import React, { FC } from 'react';
import clsx from 'clsx';
import classes from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { LinksPolitic } from 'components/LinksPolitic';

interface FooterProps {
  className?: string;
}

export const Footer: FC<FooterProps> = ({ className }) => {
  const { t, i18n } = useTranslation();

  return (
    <footer className={clsx(classes.footer, className && classes[className])}>
      <div className='layout-inner'>
        <div className={classes.inner}>
          <div className={classes.copy}>
            &copy; {new Date().getFullYear()} {t('KIT Global_footer')}
          </div>
          <LinksPolitic />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
