import styles from "./YouMayAlsoNeedSkeleton.module.scss";

const YouMayAlsoNeedSkeleton = () => {
  const items = Array.from({ length: 4 });

  return (
    <ul className={styles.wrapper}>
      {items.map((_, index) => (
        <li key={index} className={styles.card}>
          <div className={styles.top}>
            <div className={styles.tag} />

            <div className={styles.title}>
              <div className={styles["title-item"]} />
              <div className={styles["title-item"]} />
            </div>
          </div>

          <div className={styles.button} />
        </li>
      ))}
    </ul>
  );
};

export default YouMayAlsoNeedSkeleton;
