import { FilterSearchProps } from "./FilterSearch.props";
import styles from "./FilterSearch.module.scss";
import clsx from "clsx";
import { useState, useRef, useEffect } from "react";

const FilterSearch = ({
  buttonElement,
  classNameForButton,
  children,
  isLastFilter
}: FilterSearchProps) => {
  const [open, setOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.wrapper}>
      <button
        ref={buttonRef}
        onClick={toggleOpen}
        className={clsx(styles.button, classNameForButton)}
      >
        {buttonElement}
      </button>
      <div
        ref={contentRef}
        className={clsx(styles.content, open && styles.open, isLastFilter && styles.last)}
      >
        {children}
      </div>
    </div>
  );
};

export default FilterSearch;
