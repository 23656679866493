import * as React from "react";
import { SVGProps } from "react";

export const UnionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill={props.fill}
    width={props.width}
    height={props.height}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 20 18"
  >
    <path d="M9.46443 11.4286C9.36621 11.4286 9.28585 11.3482 9.28585 11.25V5.17857C9.28585 5.08036 9.36621 5 9.46443 5H10.5359C10.6341 5 10.7144 5.08036 10.7144 5.17857V11.25C10.7144 11.3482 10.6341 11.4286 10.5359 11.4286H9.46443Z"></path>
    <path d="M8.92871 13.9286C8.92871 13.6444 9.04159 13.3719 9.24253 13.171C9.44346 12.97 9.71598 12.8571 10.0001 12.8571C10.2843 12.8571 10.5568 12.97 10.7578 13.171C10.9587 13.3719 11.0716 13.6444 11.0716 13.9286C11.0716 14.2127 10.9587 14.4853 10.7578 14.6862C10.5568 14.8871 10.2843 15 10.0001 15C9.71598 15 9.44346 14.8871 9.24253 14.6862C9.04159 14.4853 8.92871 14.2127 8.92871 13.9286Z"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0002 0.199951C9.56267 0.199951 9.13368 0.31572 8.75482 0.534577C8.37611 0.753346 8.06117 1.06714 7.83853 1.44374L7.83631 1.44753L0.540101 13.945L0.533893 13.9561C0.315972 14.3433 0.201418 14.7808 0.200205 15.2252C0.198992 15.6697 0.311156 16.1078 0.526934 16.4962C0.742781 16.8848 1.05544 17.2111 1.43583 17.4407C1.81637 17.6705 2.25056 17.7949 2.69505 17.7999L17.2963 17.8L17.3053 17.7999C17.7498 17.7949 18.184 17.6705 18.5646 17.4407C18.9449 17.2111 19.2576 16.8848 19.4735 16.4962C19.6892 16.1078 19.8014 15.6697 19.8002 15.2252C19.799 14.7808 19.6845 14.3432 19.4666 13.956L12.1641 1.44752L12.1619 1.44374C11.9392 1.06714 11.6243 0.753346 11.2456 0.534577C10.8667 0.31572 10.4377 0.199951 10.0002 0.199951ZM9.21585 2.25799L9.21684 2.25632C9.30125 2.11418 9.41837 1.99904 9.55515 1.92002C9.69232 1.84079 9.84553 1.79995 10.0002 1.79995C10.1549 1.79995 10.3081 1.84079 10.4452 1.92002C10.5821 1.99906 10.6992 2.11423 10.7836 2.25642L10.7845 2.25799L18.0748 14.7455C18.156 14.8917 18.1997 15.0586 18.2002 15.2296C18.2007 15.4025 18.1569 15.5713 18.0748 15.7192C17.9927 15.867 17.8758 15.9876 17.7376 16.071C17.6007 16.1537 17.4469 16.1975 17.2911 16.2H2.70933C2.5535 16.1975 2.39968 16.1537 2.26279 16.071C2.12463 15.9876 2.00769 15.867 1.9256 15.7192C1.84344 15.5713 1.79973 15.4025 1.8002 15.2296C1.80067 15.0586 1.84437 14.8917 1.92562 14.7455L9.21585 2.25799Z"
    ></path>
  </svg>
);
