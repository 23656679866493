import {makeAutoObservable, runInAction} from "mobx";
import User from 'services/user'
import i18n from "i18n/i18n";
import {QueryParamTypes} from "../constants/queryParamTypes";
import {RUSSIAN_LOCALE} from "../utils";

export interface userProps {
    user: any;
    projects: any;
    invoices: any;
    projectInvoices: any;
    projectData: any;
    addUser: (val: any) => void;
    loadUser: () => any;
    loadInvoices: (params: {}) => any;
    loadProjectInvoices: (currentPage: number, projectId: number) => any;
    sendRegistrationCompleteEmail: () => void;
    changeLanguage: (language: string) => void;
    clearStore: () => void;
    onBoardingComplete: boolean,
    enableOnboarding: (enable: boolean) => void
    pagesToOnboard: any
    isInnerDataLoading: boolean
    localeChanged: boolean
    setLocaleChanged: (val: boolean) => void
    isProjectError: boolean
}


class UserStore {
    user = {} as any
    projects = []
    invoices = []
    projectInvoices = []
    isComplete = false
    projectData = {}
    isDataLoading = false
    isInnerDataLoading = false
    currentLanguage = i18n.language
    onboardingStatus = false
    onBoardingComplete = false
    pagesToOnboard = {
        solutions: false,
        solutionsDetail: false,
        projects: false,
        finances: false,
        profile: false,
        cart: false,
        locale: false,
    }
    localeChanged = false
    isProjectError = false

    constructor() {
        makeAutoObservable(this)
    }

    addUser(user: any) {
        this.user = user
    }

    async loadUser() {
        const response = await User.getUser()
        this.addUser(response?.data)
        if (RUSSIAN_LOCALE) {
            this.currentLanguage = 'ru'
            await this.changeLanguage('ru')
        } else {
            this.currentLanguage = response?.data?.language?.lang_code === 'ru' ? 'en' : response?.data?.language?.lang_code
            await this.changeLanguage(this.currentLanguage)
        }
        if (!RUSSIAN_LOCALE && i18n.language !== this.currentLanguage) {
            await this.changeLanguage(this.currentLanguage)
        }
        this.onboardingStatus = response?.data?.onboarding_passed

        if (response?.data) {
            runInAction(() => {
                this.isComplete = true
            })
        }

        return response?.data
    }


    async sendRegistrationCompleteEmail() {
        const response = await User.sendRegistrationCompleteEmail()
        return response.data
    }

    setLocaleChanged = (val: boolean) => {
        this.localeChanged = val
    }

    async changeLanguage(language: string) {
        try {
            const response = await User.changeLanguage(language)
            if (response.data.is_valid === true) {
                await i18n.changeLanguage(language)
                this.addUser(response.data.user)
                return true
            }
            return false
        }
        catch (error) {
            return false
        }
    }

    setProjects(projects: any) {
        this.projects = projects;
    }

    setProjectsError(error: boolean) {
        this.isProjectError = error
    }

    async loadProjects(reload?: any) {
        if (reload?.reload) {
            this.loadData(undefined, true)
        }
        const response = await User.getProjects()
        this.setProjects(response?.data)
        this.loadData(undefined, false)
        return response?.data
    }

    setFinances(finances: any){
        this.invoices = finances
    }

    setProjectInvoices(projectFinance: any) {
        this.projectInvoices = projectFinance
    }

    async loadInvoices(params?: QueryParamTypes) {
        const response = await User.getInvoices(params)
        this.setFinances(response?.data)
        return response?.data
    }

    async loadProjectInvoices(page: number = 1, projectId: number) {
        const response = await User.getProjectInvoices(page, projectId)
        if (response?.status === 404) {
            this.setProjectsError(true)
        } else {
            this.setProjectInvoices(response?.data)
            this.setProjectsError(false)
            return response?.data
        }
    }

    async loadProjectData(projectId: number) {
        const response = await User.getProject(projectId)
        if (response?.status === 404) {
            this.setProjectsError(true)
        } else {
            this.projectData = response?.data
            this.setProjectsError(false)
        }
    }

    loadData(isDataLoading?: boolean, isInnerDataLoading?: boolean) {
        if (isDataLoading !== undefined) {
            this.isDataLoading = isDataLoading
        }
        if (isInnerDataLoading !== undefined) {
            this.isInnerDataLoading = isInnerDataLoading
        }
    }

    async enableOnboarding(enable: boolean) {
        const options = JSON?.parse(localStorage.getItem("onboardingComplete") || "{}") || {}
        const {
            solutions,
            solutionsDetail,
            profile,
            projects,
            locale,
            finances,
            cart
        } = options;

        if (Object.values({ solutions, solutionsDetail, profile, projects, finances, cart }).every(Boolean)) {
            await User.setOnboardingStatus(true);
        }

        this.onBoardingComplete = enable
        if (enable) {
            this.pagesToOnboard = {
                solutions: solutions,
                solutionsDetail: solutionsDetail,
                projects: projects,
                finances: finances,
                profile: profile,
                cart: cart,
                locale: locale,
            }
        }
        if (
          solutions &&
          solutionsDetail &&
          projects &&
          finances &&
          cart &&
          enable
        ) {
            sessionStorage.setItem('onboarding', 'false');
        }
    }

    clearStore () {
        this.user = {}
        this.projects = []
        this.invoices = []
        this.projectInvoices = []
        this.isComplete = false
        this.projectData = {}
        this.onBoardingComplete = false
        this.enableOnboarding(false)
        localStorage.setItem("onboardingComplete", JSON.stringify({}))
    }
}


export const userStore = new UserStore()