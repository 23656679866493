export interface QueryParamTypes {
	search?: string;
	sort?: string;
	order?: string;
	page?: number;
	pageSize?: number;
	quickFilter?: string;
}

export const SORT_BY = {
	DATE: 'date',
	NAME: 'name',
}