import { FC } from 'react';
import clsx from 'clsx';
import classes from './Link.module.scss';
import { Link as BaseLink } from "react-router-dom";

interface Props {
  className?: string;
  children: any;
  isEmpty?: boolean;
  href: string;
  target?: string;
  onClick?: any
}

export const Link: FC<Props> = ({
  children,
  href,
  className,
  isEmpty = false,
  target,
  onClick
}) => {

  const handleClick = (e: any) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  }

  return (
    <div className={clsx(
      classes.wrapper,
      isEmpty && classes.empty,
      className,
    )}>
      <BaseLink to={href} target={target} onClick={handleClick}>
        {children}
      </BaseLink>
    </div>
  );
}
