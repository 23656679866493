import { FC } from "react";
import "../styles/main.scss";
import { CartContainer } from "containers/Cart";

interface Props {
  section: string;
}

// todo add localization feature
export const Cart: FC<Props> = ({ section }) => {
  return <CartContainer />;
};
