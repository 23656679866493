import cartClasses from './Cart.module.scss';
import cartListClasses from './CartList/CartList.module.scss';
import totalClasses from './Total/Total.module.scss';
import menuClasses from '../../components/Menu/Menu.module.scss';

export const emptyCartMessages = (t:any) => [
    {
        title: t('Once you add solutions to your cart, they will appear on this screen'),
        intro: '',
    }
]

export const cartMessages = (t: any) => [
    {
        title: t('On this page you will find all the solutions you have added to your Cart'),
        intro: '',
    },
    {
        element: `.${cartListClasses.list}`,
        intro: t('Checkboxes allow you to choose which of the items you are ready to order now - one, several or all of them'),
        position: 'right',
        scrollTo: 'tooltip',
    },
    {
        element: `.${cartClasses.orderDetails}`,
        intro: t('Order details will show your information on pricing of all chosen solutions and applied discounts'),
        position: 'left',
    },
    {
        element: `.${totalClasses.button}`,
        intro: t("Once you've decided on the items you are ready to order now, click on the button 'Start projects'"),
    },
    {
        element: `.${menuClasses.projects}`,
        intro: t('Click Projects in the top menu to see the list of created projects'),
    },
]