import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { PurpleArrowDownIcon } from "components/Icons/PurpleArrowDownIcon";
import { RocketIcon } from "components/Icons/RocketIcon";
import { SearchIcon } from "components/Icons/SearchIcon";
import { ServicesIcon } from "components/Icons/ServicesIcon";
import { WalletIcon } from "components/Icons/WalletIcon";
import { Input } from "components/Input";
import { useTranslation } from "react-i18next";
import classes from "./Filters.module.scss";
import { CrossIcon } from "components/Icons/CrossIcon";
import { userStore } from "stores/userStore";
import { marketPlaceStore } from "stores/marketPlaceStore";
import i18n from "i18n/i18n";
import FiltersSkeleton from "components/Skeleton/FiltersSkeleton/FiltersSkeleton";
import {
  FilterProps,
  ServiceFilterProps,
} from "containers/CardsContainer/types";
import {
  defaultSortParams,
  handleFilterServices,
} from "containers/CardsContainer/utils";

const filterLimits = 5;
const filterMaxItems = 30;

export const Filters: FC<ServiceFilterProps> = ({
  isLoading,
  serviceTypesChecks,
  businessTypesChecks,
  setServiceTypes,
  setBusinessTypes,
  maxCost,
  priceMin,
  setPriceMin,
  serviceTypesOf,
  businessTypesOf,
  setCurrentPage,
  setBudget,
  budget,
  pageSize,
  setProductsToShow,
  topFilters,
  isFavorites,
  sortSelect,
  setMoreButtonClicked,
  clearBusinessTypes,
  setClearBusinessTypes,
  setIsFirstRender
}) => {
  const [serviceOptions, setServiceOptions] = useState(serviceTypesOf);
  const [serviceSearch, setServiceSearch] = useState("");
  const [businessOptions, setBusinessOptions] = useState(businessTypesOf);
  const [businessSearch, setBusinessSearch] = useState("");
  const [serviceLimit, setServiceLimit] = useState(filterLimits);
  const [businessLimit, setBusinessLimit] = useState(filterLimits);

  // Фильтр по стоимости
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);

  const user = userStore.user;

  useEffect(() => {
    if (budget) {
      //Эта штука сделана с "-1" для того, чтобы в запрос передавать price_max = 0
      budget === -1 ? setMaxValue(0) : setMaxValue(budget);
    } else if (budget === 0 || maxCost) {
      setMaxValue(maxCost);
    } else {
      if (budget) {
        //Эта штука сделана с "-1" для того, чтобы в запрос передавать price_max = 0
        budget === -1 ? setMaxValue(0) : setMaxValue(budget);
      }
    }
  }, [maxCost]);

  // useEffect(() => {
  //   if (budget === 0 || maxCost)  {
  //     setMaxValue(maxCost);
  //   } else {
  //     if (budget) {
  //       //Эта штука сделана с "-1" для того, чтобы в запрос передавать price_max = 0
  //       budget === -1 ? setMaxValue(0) : setMaxValue(budget);}
  //   }
  // }, [maxCost]);

  useEffect(() => {
    if (priceMin) {
      setMinValue(priceMin);
    } else {
      setMinValue(0);
    }
  }, [priceMin]);

  const handleChangeMinValue = (e: any) => {
    const value = e.replace(/\s+/g, ""); // Удаляем пробелы так как в input у нас может быть разделение "1000" на пробелмы "1 000"

    if (/^\d*$/.test(value)) {
      const numValue = Number(value);
      // const limit = budget !== null && budget > 0 ? budget : maxCost;

      if (numValue <= maxCost) {
        setMinValue(numValue);

        if (budget === 0) setMaxValue(maxCost);
      }
    }
  };

  const handleChangeMaxValue = (e: any) => {
    const value = e.replace(/\s+/g, ""); // Удаляем пробелы так как в input у нас может быть разделение "1000" на пробелмы "1 000"

    if (/^\d*$/.test(value)) {
      const numValue = Number(value);

      setMaxValue(numValue);
      // if (numValue <= maxCost) {
      //   setMaxValue(numValue);
      // }
    }
  };

  const handleChangeMinPrice = (e: any) => {
    if (e.includes("e") || e.includes("+")) {
      setMaxValue(maxCost);
      return;
    }

    const value = e.replace(/\s+/g, ""); // Удаляем пробелы так как в input у нас может быть разделение "1000" на пробелмы "1 000"

    // Проверка на ввод только цифр
    if (/^\d*$/.test(value)) {
      const numValue = Number(value);
      const limit = budget !== null && budget > 0 ? budget : maxCost;

      if (numValue === priceMin) return;

      if (numValue <= limit) {
        setMoreButtonClicked(false);
        setPriceMin(numValue);

        if (budget === 0) setBudget(maxCost);

        handleFilter({ price_max: budget, price_min: priceMin });
      } else {
        setMinValue(priceMin);
      }
    }
  };

  const handleChangeMaxPrice = (e: any) => {
    if (e.includes("e") || e.includes("+")) {
      setMaxValue(maxCost);
      return;
    }

    const value = e.replace(/\s+/g, ""); // Удаляем пробелы так как в input у нас может быть разделение "1000" на пробелмы "1 000"

    // Проверка на ввод только цифр
    if (/^\d*$/.test(value)) {
      const numValue = Number(value);

      if (
        (budget === 0 && numValue === maxCost) ||
        (budget && budget > maxCost && numValue !== 0 && numValue === maxCost)
      )
        return;

      if (numValue <= maxCost && numValue >= priceMin) {
        setMoreButtonClicked(false);
        //Эта штука сделана с "-1" для того, чтобы в запрос передавать price_max = 0
        numValue === 0 ? setBudget(-1) : setBudget(numValue);

        handleFilter({ price_max: budget, price_min: priceMin });
      } else if (numValue > maxCost) {
        setMoreButtonClicked(false);
        setBudget(maxCost);
        setMaxValue(maxCost);
        handleFilter({ price_max: maxCost, price_min: priceMin });
      } else {
        if (budget) setMaxValue(budget);
      }
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    callback: (value: string) => void,
  ) => {
    if (event.key === "Enter") {
      callback((event.target as HTMLInputElement).value);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    callback: (value: string) => void,
  ) => {
    callback((event.target as HTMLInputElement).value);
  };
  // _________

  const { t, i18n } = useTranslation();
  const order = defaultSortParams(sortSelect);

  useEffect(() => {
    if (serviceSearch !== "") {
      let newOptions = serviceTypesOf.filter((item: any) => {
        if (item.name.toLowerCase().includes(serviceSearch.toLowerCase())) {
          return item;
        }
      });
      setServiceOptions(newOptions);
      return;
    }
    setServiceOptions(serviceTypesOf);
  }, [serviceSearch, serviceTypesOf]);

  useEffect(() => {
    if (businessSearch !== "") {
      let newOptions = businessTypesOf.filter((item: any) => {
        if (item.name.toLowerCase().includes(businessSearch.toLowerCase())) {
          return item;
        }
      });

      setBusinessOptions(newOptions);
      return;
    }

    // Если у пользвателя указан business type - тогда мы выводим в списке фильтров его на первое место.
    if (user.company_industry && !isFavorites) {
      const indexUserCompanyIndustry = businessTypesOf.findIndex(
        (item: any) => item.id === user.company_industry,
      );

      if (indexUserCompanyIndustry > -1) {
        const newOptions = businessTypesOf;
        const [businessType] = businessTypesOf.splice(
          indexUserCompanyIndustry,
          1,
        );
        newOptions.unshift(businessType);

        if (!businessTypesChecks.find(item => item === businessType.id)) {
          setBusinessTypes([...businessTypesChecks, businessType.id]);
        }

        setBusinessOptions(newOptions);
      } 
      setIsFirstRender(false)
    } else {
      setBusinessOptions(businessTypesOf);
      setIsFirstRender(false)
    }
  }, [businessSearch, businessTypesOf]);

  const resetSearchService = () => {
    setServiceTypes([]);
    setServiceSearch("");
    handleFilter({ serviceTypes: [] });
  };

  const resetSearchBusiness = () => {
    setClearBusinessTypes(true);
    setBusinessTypes([]);
    setBusinessSearch("");
    handleFilter({ businessTypes: [] });
  };

  const handleFilter = (options: FilterProps) => {
    setCurrentPage(1);
    setMoreButtonClicked(false);

    //Я не знаю зачем это нужно тут, убрал так как вызывает лишние запросы с неправильными пропсами (artem zykov)
    // if (isFavorites) {
    //   handleFilterServices(
    //     options,
    //     pageSize,
    //     businessTypesChecks,
    //     serviceTypesChecks,
    //     setProductsToShow,
    //     topFilters,
    //     isFavorites,
    //     order,
    //   );
    // }
  };

  const handleClearPrice = () => {
    setCurrentPage(1);
    setMoreButtonClicked(false);
    setPriceMin(0);
    setBudget(0);
  };

  if (isLoading) return <FiltersSkeleton />;

  return (
    <div className={classes.wrapper}>
      <div className={classes.filter}>
        <div className={classes.title}>
          <ServicesIcon /> {t("Types of solutions")}
        </div>
        {serviceTypesChecks?.length > 0 && (
          <Button
            onClick={() => resetSearchService()}
            className={classes.clear}
            theme="icon-default"
          >
            {serviceTypesChecks?.length}
            <CrossIcon />
          </Button>
        )}
        {serviceLimit > filterLimits && (
          <div className={classes["search-field"]}>
            <SearchIcon />
            <Input
              value={serviceSearch}
              onChange={setServiceSearch}
              placeholder={t("Search")}
              className={classes.search}
            />
          </div>
        )}
        <div className={classes.choices}>
          {serviceOptions.slice(0, serviceLimit).map((item: any) => (
            <Checkbox
              className={classes.check}
              key={item.id}
              onChange={() => {
                if (serviceTypesChecks.includes(item.id)) {
                  let arr = serviceTypesChecks.filter(val => val !== item.id);
                  setServiceTypes(arr);
                  handleFilter({ serviceTypes: arr });
                  return;
                }
                let arr = [...serviceTypesChecks, item.id];
                setServiceTypes(arr);
                handleFilter({ serviceTypes: arr });
              }}
              checked={serviceTypesChecks.includes(item.id)}
              label={item[`name_${i18n.language}`] ?? item.name}
              disabled={marketPlaceStore.isLoadingServicePages}
            />
          ))}
        </div>
        {serviceOptions.length > filterLimits ? (
          <>
            {serviceLimit === filterLimits ? (
              <Button
                onClick={() => setServiceLimit(serviceOptions.length)}
                className={classes.button}
                theme="text"
              >
                <PurpleArrowDownIcon className={classes["button-icon"]} />
                {t("Show all")}
              </Button>
            ) : (
              <Button
                onClick={() => setServiceLimit(filterLimits)}
                className={classes.button}
                theme="text"
              >
                <PurpleArrowDownIcon
                  className={clsx(classes["button-icon"], classes.rotate)}
                />
                {t("Show less")}
              </Button>
            )}
          </>
        ) : null}
      </div>

      <div className={classes.filter}>
        <div className={classes.title}>
          <RocketIcon /> {t("Type of business")}
        </div>
        {businessTypesChecks?.length > 0 && (
          <Button
            onClick={() => resetSearchBusiness()}
            className={classes.clear}
            theme="icon-default"
          >
            {businessTypesChecks?.length}
            <CrossIcon />
          </Button>
        )}
        {businessLimit > filterLimits && (
          <div className={classes["search-field"]}>
            <SearchIcon />
            <Input
              value={businessSearch}
              onChange={setBusinessSearch}
              placeholder={t("Search")}
              className={classes.search}
            />
          </div>
        )}
        <div className={classes.choices}>
          {businessOptions.slice(0, businessLimit).map((item: any) => (
            <Checkbox
              className={classes.check}
              key={item.id}
              onChange={() => {
                if (businessTypesChecks.includes(item.id)) {
                  let arr = businessTypesChecks.filter(val => val !== item.id);
                  setClearBusinessTypes(true);
                  setBusinessTypes(arr);
                  handleFilter({ businessTypes: arr });
                  return;
                }
                let arr = [...businessTypesChecks, item.id];
                setBusinessTypes(arr);
                handleFilter({ businessTypes: arr });
              }}
              checked={businessTypesChecks.includes(item.id)}
              label={item[`name_${i18n.language}`] ?? item.name}
              disabled={marketPlaceStore.isLoadingServicePages}
            />
          ))}
        </div>
        {businessOptions.length > filterLimits ? (
          <>
            {businessLimit === filterLimits ? (
              <Button
                onClick={() => setBusinessLimit(businessOptions.length)}
                className={classes.button}
                theme="text"
              >
                <PurpleArrowDownIcon className={classes["button-icon"]} />
                {t("Show all")}
              </Button>
            ) : (
              <Button
                onClick={() => setBusinessLimit(filterLimits)}
                className={classes.button}
                theme="text"
              >
                <PurpleArrowDownIcon
                  className={clsx(classes["button-icon"], classes.rotate)}
                />
                {t("Show less")}
              </Button>
            )}
          </>
        ) : null}
      </div>

      <div className={classes.filter}>
        <div className={classes.title}>
          <WalletIcon /> {t("Budget")}
        </div>
        {(priceMin !== 0 || budget !== 0) && (
          <Button
            onClick={() => handleClearPrice()}
            // className={classes.clearPrice}
            className={classes.clear}
            theme="icon-default"
          >
            {t("Clear all")}
            <CrossIcon />
          </Button>
        )}
        <div className={classes["choices-budget"]}>
          <Input
            onKeyDown={e => handleKeyDown(e, handleChangeMinPrice)}
            onBlur={e => handleBlur(e, handleChangeMinPrice)}
            currency={userStore.user.currency?.symbol}
            onChange={handleChangeMinValue}
            type={"text"}
            placeholder={t("Min price")}
            value={minValue.toString()}
            disabled={marketPlaceStore.isLoadingServicePages}
            inputForCurrency={true}
          />
          <Input
            onKeyDown={e => handleKeyDown(e, handleChangeMaxPrice)}
            onBlur={e => handleBlur(e, handleChangeMaxPrice)}
            currency={userStore.user.currency?.symbol}
            onChange={handleChangeMaxValue}
            labelPosition={"left"}
            type={"text"}
            placeholder={t("Max price")}
            value={maxValue?.toString()}
            disabled={marketPlaceStore.isLoadingServicePages}
            inputForCurrency={true}
          />
        </div>
      </div>
    </div>
  );
};
