import React, { FC } from 'react';
import { RegistrationContainer } from '../containers/Registration';
import '../styles/main.scss';


// todo add localization feature
export const Registration: FC = () => {
  return (
    <>
      <RegistrationContainer />
    </>
  );
}