import React, { FC } from 'react';
import { RestoreContainer } from 'containers/Restore';
import '../styles/main.scss';


// todo add localization feature
export const Restore: FC = () => {
  return (
    <>
      <RestoreContainer />
    </>
  );
}