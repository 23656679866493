import { FC } from 'react';
import {COST_TYPE} from "../../constants/costType";

interface PriceTextProps {
	data: any;
	t: any;
	asteriskSymbol?: boolean;
}

export const priceTextContent = (data: any, t: any, asteriskSymbol = false, noPrice = false) => {
	const PERIOD = {
		PER_MONTH: t('per month'),
		PER_HOUR: t('per hour'),
		PER_LEAD: t('per lead'),
		BUDGET: t('Budget'),
		ALL: t('All')
	}

	if (data?.price_per !== 'BUDGET') {
		if (noPrice && data?.price_per === 'ALL') {
			return `${t('All')}`
		}
		return `${noPrice ? '' : t('Price')} ${ data?.price_per !== 'ALL' ? t(PERIOD[data.price_per as keyof typeof PERIOD]) : ''} ${asteriskSymbol ? '*' : ''}`;
	} else if (data?.cost_type === COST_TYPE.FROM) {
		if (noPrice) return `${t('Budget')}`
		return `${t('Budget from')} ${asteriskSymbol ? '*' : ''}`;
	} else if (data?.cost_type === COST_TYPE.TO) {
		if (noPrice) return `${t('Budget')}`
		return `${t('Budget to')} ${asteriskSymbol ? '*' : ''}`;
	}
}

const PriceText: FC<PriceTextProps> = ({ data, t, asteriskSymbol}) =>
	<>{priceTextContent(data, t, asteriskSymbol)}</>;



export default PriceText;