import React from "react";
import classes from './NotFoundPage.module.scss';
import { Type } from 'components/Type';
import { Button } from 'components/Button';
import { NavLink } from 'react-router-dom';
import desktop from 'img/System/box.png'
import mobile from 'img/System/box-mobile.png'
import { useTranslation } from "react-i18next";

export const NotFoundPageContainer = () => {

  const {t} = useTranslation();

  return (
    <div className={classes.wrap}>
        <div className={classes.inner}>
          <div className={classes.title}>
            <Type tag="h2" size="lg" className={classes.text}>
              {t("Page not found")}
            </Type>
          </div>
          <div className={classes.subtitle}>
            <Type tag="h3" size="subtitle" className={classes.text}>
              {t("Sorry, the page you were looking for doesn’t exist")}
            </Type>
          </div>
          <div className={classes.button}>
              <Button size='middle' theme='primary'>
                <NavLink to='/solutions' className={classes.button}>
                  {t("Back to Homepage")}
                </NavLink>
              </Button>
          </div>
        </div>
        <div className={classes.img}>
              <img src={desktop} alt="Box" className={classes.desktop} />
              <img src={mobile} alt="Box" className={classes.mobile} />
        </div>
    </div>
  );
}