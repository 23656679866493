import * as React from "react"
import { SVGProps } from "react"
import { RUSSIAN_LOCALE } from "utils"

export const CheckCircleGradientItem = (props: SVGProps<SVGSVGElement>) => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="48" cy="48" r="44.6897" stroke="url(#paint0_linear_1437_9516)" stroke-width="6.62069" />
    <path d="M28.9658 45.9711L42.1892 59.5862L69.5175 31.4483" stroke="url(#paint1_linear_1437_9516)" stroke-width="6.62069" />
    <defs>
      {RUSSIAN_LOCALE &&
        <>
          <linearGradient id="paint0_linear_1437_9516" x1="23.3846" y1="0.5" x2="78.7692" y2="96.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E47267" />
            <stop offset="1" stop-color="#EB5945" />
          </linearGradient>
          <linearGradient id="paint1_linear_1437_9516" x1="38.8428" y1="33.9482" x2="51.7824" y2="66.2717" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E47267" />
            <stop offset="1" stop-color="#EB5945" />
          </linearGradient>
        </>
      }
      {!RUSSIAN_LOCALE &&
        <>
          <linearGradient id="paint0_linear_1437_9516" x1="-15.3057" y1="-66.353" x2="145.336" y2="44.2364" gradientUnits="userSpaceOnUse">
            <stop stop-color="#7699E7" />
            <stop offset="1" stop-color="#8362F3" />
          </linearGradient>
          <linearGradient id="paint1_linear_1437_9516" x1="22.5005" y1="12" x2="72.9034" y2="62.0067" gradientUnits="userSpaceOnUse">
            <stop stop-color="#7699E7" />
            <stop offset="1" stop-color="#8362F3" />
          </linearGradient>
        </>
      }
    </defs>
  </svg>
)
