const titleConstant = 'KIT Global';
const insideDescription = 'KIT Global Platform – all digital marketing tools at one place';
const emptyConstant = 'EMPTY';

export const pagesTitlesAndDescriptions = [
    {
      page: 'Registration',
      title: `Account creation - KIT Global`,
      description: 'Sign up for KIT Global Platform'
    },
    {
      page: 'Login',
      title: `Platform Log in - KIT Global`,
      description: 'Log in to KIT Global Platform'
    },
    {
      page: 'Restore',
      title: `Restore password - KIT Global`,
      description: 'Restore password to KIT Global Platform'
    },
    {
      page: 'Solutions',
      title: `Solutions - KIT Global`,
      description: insideDescription
    },
    {
      page: 'ServiceDetail',
      title: `{{title}} - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Projects',
      title: `Projects - KIT Global`,
      description: insideDescription
    },
    {
      page: 'ProjectsDetail',
      title: `{{title}} - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Finances',
      title: `Finances - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Orders',
      title: `Orders - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Your invoices',
      title: `Your invoices - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Favorites',
      title: `Favorites - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Cart',
      title: `Cart - KIT Global`,
      description: insideDescription
    },
    {
      page: 'HotDeals',
      title: `Hot Deals - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Account/personal',
      title: `Personal information - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Account/business',
      title: `Business information - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Account/change-password',
      title: `Change password - KIT Global`,
      description: insideDescription
    },
    {
      page: '404',
      title: `Page not found - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Disclaimer',
      title: 'Pricing Disclaimer - KIT Global',
      description: insideDescription
    },
    {
      page: 'NewPassword',
      title: `New Password - KIT Global`,
      description: insideDescription
    },
    {
      page: 'Dashboard',
      title: 'Dashboard - KIT Global',
      description: insideDescription
    },
  ];