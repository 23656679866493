import {FC, useEffect, useState} from "react";

import classes from "./HotDealsItem.module.scss";
import clsx from "clsx";
import {WhiteFireIcon} from "components/Icons/WhiteFireIcon";
import {Link, useNavigate} from "react-router-dom";
import {FilledHeartIcon} from "components/Icons/FilledHeartIcon";
import {HeartIcon} from "components/Icons/HeartIcon";
import {Button} from "components/Button";
import useFavorites from "hooks/useFavorites";
import {useUserStore} from "contexts/UserContext";
import {toJS} from "mobx";
import {useHotDeals} from "hooks/useHotDeals";
import {TooltipContainer} from "components/Tooltip/TooltipContainer";
import {InfoIcon} from "components/Icons/InfoIcon";
import {useCartStore} from "contexts/CartContext";
import {observer} from "mobx-react-lite";

import {useTranslation} from "react-i18next";
import AddToCartButton from "../../../../components/Button/AddToCartButton";
import FormatPrice from "../../../../components/FormatPriceAndCurrency";
import PriceText from "../../../../components/PriceText";


interface Props {
  infoDiscount: any;
  deals: {
    cost_change_type: string;
    cost_change_value: string;
    name: string;
    deal_end_date: string
  };
  isFeature: boolean;
}

interface Source {
  id: number;
  name: string | null;
  name_en?: string | null;
  name_es?: string | null;
  name_es_mx?: string | null;
  name_hi?: string | null;
  name_ind?: string | null;
  name_ru?: string | null;
}

export const HotDealsItem: FC<Props> = observer(({
  infoDiscount,
  deals,
  isFeature,
}) => {

  const cartStore = useCartStore();
  const userStore = useUserStore();

  const [isFavorite, setIsFavorite] = useState(infoDiscount.is_favorite);
  const navigate = useNavigate();
  const [isInCart, setIsInCart] = useState(false);

  const [cartSources, setCartSources] = useState<any>();

  const {t, i18n} = useTranslation();

  const [hotDealDiscountText, setHotDealDiscountText] = useState("");
  const {getHotDealText} = useHotDeals(t);

  const changeFavorites = useFavorites(
    infoDiscount,
    isFavorite,
    setIsFavorite,
    navigate
  );

  useEffect(() => {
    if (toJS(cartStore.cart)) {
      if (Object.keys(toJS(cartStore.cart)).length !== 0) {
        const newCart = toJS(cartStore.getActiveCart())
        newCart?.basket_project_service?.forEach((service: any) => {
          if (service.service.id === infoDiscount.id) {
            setIsInCart(true);
            setCartSources(service.source);
          }
        });
      }
    }
  }, [cartStore.cart]);

  useEffect(() => {
    setHotDealDiscountText(getHotDealText(deals, infoDiscount))
  }, [deals, infoDiscount])

  const utcDate = new Date(`${deals.deal_end_date}`);
  const dayEnd = utcDate.getDate();
  const monthEnd = utcDate.toLocaleString(i18n.language, {month: "long"});
  const yearEnd = utcDate.getFullYear();

  const checkCurrency = () => {
    return userStore.user.currency?.char_code === "IDR" || userStore.user.currency?.char_code === "INR";
  }

  const formattedPrice = (price?: number | string, withMinus?: boolean) => <FormatPrice currency={userStore.user?.currency?.symbol} price={price} withMinus={withMinus} />
  return (
    <>
      <div className={clsx(classes.item)}>
        <div className={clsx(classes.deal, !isFeature && classes.activeDeal)}>
          {deals.cost_change_type === 'MONEY' && formattedPrice(undefined,true)}
          {hotDealDiscountText}
        </div>
        <div className={classes.inner}>
          <div className={classes.box}>
            <div className={classes.header}>
              <div className={classes.tags}>
                <div className={clsx(classes.tag, classes.hit)}>
                  <WhiteFireIcon/>
                  {deals.cost_change_type === 'MONEY' && formattedPrice(undefined,true)}
                  {hotDealDiscountText}
                </div>
                <div className={classes.tag}>{infoDiscount.product?.name}</div>
              </div>
              <div className={classes.favored}>
                <Button
                  className={classes.favoriteButton}
                  theme="icon-default"
                  onClick={changeFavorites}
                >
                  {isFavorite ? (
                    <FilledHeartIcon/>
                  ) : (
                    <HeartIcon fill={"#8362F3"}/>
                  )}
                </Button>
              </div>
            </div>
            {isFeature ? (
              <span className={classes.title}>{infoDiscount.name}</span>
            ) : (
              <div>
                <Link
                  to={`/solutions/${infoDiscount.slug}`}
                  className={classes.title}
                >
                  {infoDiscount.name}
                </Link>
                <div className={classes.tooltipWrap}>
                  <span className={classes.closeTimeInner}>
                    {t("Closing time")}:{" "}
                    <span className={classes.closeTime}>
                      {dayEnd} {monthEnd} {yearEnd}{" "}
                    </span>
                  </span>
                  <div className={classes.closeTimeTooltip}>
                    <TooltipContainer
                      text={t("Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled")}
                      position={"top"}
                      className={classes.tooltipContainer}
                      classNameTooltip={classes.tooltip}
                    >
                      <InfoIcon fill="#A09CAC" width="18px" height="18px"/>
                    </TooltipContainer>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={classes.footer}>
            <div
              className={clsx(
                classes.price,
                isFeature && classes.priceDisabled
              )}
            >
              <span className={classes.priceTitle}>
                {infoDiscount?.price_per && <PriceText data={infoDiscount} t={t} />}
              </span>
              <div className={classes.row}>
              <span className={clsx(classes.priceItem, checkCurrency() && classes['priceItem--small'])}>
                {formattedPrice((infoDiscount?.has_active_discounts
                    ? infoDiscount?.cost_with_discounts
                    : infoDiscount?.cost
                ) ?? '')}
              </span>
                {!isFeature && (
                  <span className={classes.current}>
                    {infoDiscount.has_active_discounts &&
                      Number(infoDiscount?.cost) !== Number(infoDiscount?.cost_with_discounts) && formattedPrice(infoDiscount?.cost)}
                  </span>
                )}
              </div>
            </div>
            <div className={classes["mobile-buttons"]}>
              <AddToCartButton data={infoDiscount}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
