import React from 'react';
import style from "./Label.module.scss";
import PropTypes from "prop-types";
import clsx from "clsx";

export const Label = ({position, children}) => {
    const className = clsx(
        style.formlabel, 
        position && style[position]
    )

    return <label className={className}>{children}</label>
}

Label.defaultProps = {
    position: 'left',
    children: ''
}

Label.propTypes = {
    position: PropTypes.oneOf(['left', 'right']),
    children: PropTypes.string.isRequired
}