import React, { FC, useState, useEffect } from 'react';
import { useCookies } from "react-cookie";
import clsx from "clsx";
import classes from "./DashboardContainer.module.scss";
import Select from "../../components/SelectComponent";
import {useTranslation} from "react-i18next";
import {dashboardProjectOptions} from "./utils";
import {useWindowWidth} from "../../hooks/useWindowWidth";
import emptyDashboard from 'img/Dashboard/EmptyDashboard.png'
import {TitleAndDescription} from "../../components/TitleAndDescription/TitleAndDescription";
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { userStore } from 'stores/userStore';
import { getAuthToken, getDashboardID, getAdditionalParams } from 'services/improvado';
import { userRoles } from 'constants/userRoles';
import { GetDashboardParams } from 'api/getDashboardParams';

interface DashboardContainerProps {
	title: string;
}

export const DashboardContainer: FC<DashboardContainerProps> = ({title}) => {
	const {t} = useTranslation();
	const [selectedProject, setSelectedProject] = useState<any>();
	const [userProjects, setUserProjects] = useState<any>([]);
	const [openSelect, setOpenSelect] = useState(false);
	const {isMediaTablet} = useWindowWidth();
	const [user, setUser] = useState<any>();
	const [cookies, setCookies] = useCookies(["access", "refresh"]);


	const loadProjects = async () => {
	    const projects = await userStore.loadProjects();
	    setUserProjects(projects);
	    setSelectedProject(projects[0]);
	}

	const getDashBoardParams = async (workspaceID : string) => {
        try {
          const response = await GetDashboardParams({
            access: cookies.access,
            refresh: cookies.refresh,
            workspaceId: workspaceID
          });
            return response
        } catch (error) {
          console.log(error, 'Get DashboardParams Error');
        }
      }

    const getGuestToken = async (workspaceID : string) => {
        try {
          const response = await GetDashboardParams({
            access: cookies.access,
            refresh: cookies.refresh,
            workspaceId: workspaceID
          });
            return response?.guest_token
        } catch (error) {
          console.log(error, 'Get DashboardParams Error');
        }
      }

	const loadUser = async () => {
	    const currentUser = await userStore.loadUser();
	    setUser(currentUser);
	}

	useEffect(() => {
        loadProjects();
        loadUser();
  }, []);

//   useEffect(() => {
//         const imrpovadpScriptDownloadElement = document.createElement('script');
//         imrpovadpScriptDownloadElement.src = "https://unpkg.com/@superset-ui/embedded-sdk";
//         document.head.appendChild(imrpovadpScriptDownloadElement);
//
//         const dashboardParams = getDashBoardParams("16180");
//         getDashBoardParams("16180").then((result) => {
//
//             const agencyUID = result?.current_agency_uuid;
//             const dashboardID = result?.dashboard_id;
//             const guestToken = result?.guest_token;
//
//             const getGuestTokenScriptElement = document.createElement('script');
//             getGuestTokenScriptElement.type = 'text/javascript';
//             getGuestTokenScriptElement.innerHTML  = `
//                     function getGuestTokenHTML() {
//                     return "${guestToken}"; }
//           `;
//             document.head.appendChild(getGuestTokenScriptElement);
//
//             const dashboardScripElement = document.createElement('script');
//             dashboardScripElement.type = 'text/javascript';
//             dashboardScripElement.innerHTML  = `
//                     supersetEmbeddedSdk.embedDashboard({
//                     id: "${dashboardID}",
//                     supersetDomain: "https://superset-embedded.tools.improvado.io",
//                     mountPoint: document.getElementById("improvado_dashboard"),
//                     fetchGuestToken: () => getGuestTokenHTML(),
//                     dashboardUiConfig: {
//                       urlParams: {
//                         current_agency_uuid: "${agencyUID}",
//                         current_workspace_ids: [16180],
//                       },
//                     iframeSandboxExtras: ['allow-top-navigation', 'allow-popups-to-escape-sandbox']
//         }})
//           `;
//             document.head.appendChild(dashboardScripElement);
//            }
//         )
//   }, []);

    const createImprovadoDashboard = async (selectedProject: any) => {
        if (selectedProject?.improvado_workspace_id) {
            if (
            (user?.role === userRoles['CLIENT'] && selectedProject.dashboard_active_for_client) ||
            (user?.role === userRoles['PARTNER'] && selectedProject.dashboard_active_for_partner) ||
            (user?.role !== userRoles['CLIENT'] && user?.role !== userRoles['PARTNER'] )
            ) {

            const workspaceID = selectedProject?.improvado_workspace_id;
            const dashboardParams = await getDashBoardParams(workspaceID);
            const agencyUID = dashboardParams?.current_agency_uuid
            const dashboardID = dashboardParams?.dashboard_id
            const dashboardHTMLElement = document.getElementById("improvado_dashboard")!;

            console.log(dashboardParams, "dashboard params");
            embedDashboard({
                id: dashboardID,
                supersetDomain:"https://superset-embedded.tools.improvado.io",
                mountPoint: dashboardHTMLElement,
                fetchGuestToken: () => getGuestToken(workspaceID),
                dashboardUiConfig: {
                  urlParams: {
                    current_agency_uuid: agencyUID,
                    current_workspace_ids: [selectedProject?.improvado_workspace_id],
                  },
                },
              });
            const embededDashboardElement = document.querySelector<HTMLElement>("[title='Embedded Dashboard']");
            if (embededDashboardElement){
                embededDashboardElement.style.width = "100%";
                embededDashboardElement.style.height = "500px";
                embededDashboardElement.setAttribute("frameBorder", "0");
            }
        }}
    }


    useEffect(() => {
        createImprovadoDashboard(selectedProject);
   }, [selectedProject]);

	const emptyPage = (
			<div className={classes.emptyPage}>
				<div className={classes.emptyPage__icon}>
					<img
						src={emptyDashboard}
						alt={t("This information is only available on the desktop version")}
						className={classes.emptyPage__image}
					/>
				</div>
				<div className={classes.emptyPage__text}>
					{t("This information is only available on the desktop version")}
				</div>
			</div>
	)
	return (
	    <>
		<div className={clsx(classes.wrapper)}>
			<TitleAndDescription pageName='Dashboard' />
			<div className={classes.title}>
				{title}
			</div>
			{!isMediaTablet ? (
				<div className={classes.selectContainer}>
					<Select
						select={selectedProject}
						setSelect={setSelectedProject}
						options={userProjects}
						open={openSelect}
						setOpen={setOpenSelect}
						title={t("Projects")}
						boxClassName={classes.selectBox}
						wrapperClassName={classes.selectWrapper}
					/>
				</div>
			) : emptyPage}
		</div>
		<div id="improvado_dashboard" className={classes.improvadoDashboard}></div>
		</>
	);
};