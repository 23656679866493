import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { pagesTitlesAndDescriptions } from "constants/titleAndDescriptionConstants";
import { useTranslation } from "react-i18next";

interface ITitleAndDescription {
  pageName: string,
  title?: string
}

// Функция, которая использует textarea элемент для декодирования HTML-энтити в строку. Этот подход обеспечивает корректное отображение символов, таких как &, ', ", / и других, в заголовке и мета-тегах.
const decodeHtmlEntity = (str: string) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
}

export const TitleAndDescription: FC<ITitleAndDescription> = ({title, pageName}) => {
  const {t, i18n} = useTranslation();

  const pageDetails = pagesTitlesAndDescriptions.find(el => el.page === pageName);
  const decodedTitle = decodeHtmlEntity(t(pageDetails?.title || "", { title: title }));
  const decodedDescription = decodeHtmlEntity(t(pageDetails?.description || ""));
  return (
    <Helmet key={i18n.language}>
      {/* <title>
         {t(pagesTitlesAndDescriptions.find(el => el.page === pageName)?.title || "", {title: title})}
      </title>
      <meta name="description" content={t(pagesTitlesAndDescriptions.find(el => el.page === pageName)?.description || "")} /> */}
      <title>{decodedTitle}</title>
      <meta name="description" content={decodedDescription} />
    </Helmet>
  )
}