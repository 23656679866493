import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ShowNotification } from "tools/showNotification";
import { userStore } from "stores/userStore";
import { financesStore } from "stores/financesStore";
import Finances from "services/finances";

export const useFormPaymentBill = (
  onClose: () => void,
  handleShowTopUpModal: () => void,
  showPaymentBillModal: boolean,
) => {
  const { bills, setBillsForPayment } = financesStore;
  const { t } = useTranslation();
  const currency = userStore.user.currency?.char_code;
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState({
    alreadyTryToPay: false,
    success: false,
  });

  const balance =
    userStore.user.available_real_balance.find(
      (i: any) => i.currency.char_code === "RUB",
    )?.amount || 0;

  const handlePayment = async () => {
    setIsLoading(true);
    const formData = new FormData();
    const billId = bills[0].serial_id;
    formData.append("id", String(bills[0].serial_id));

    try {
      await Finances.payTransaction(formData);
      await userStore.loadInvoices();
      await userStore.loadUser();

      ShowNotification({
        type: "success",
        children: t("payment_success", { billId }),
      });

      setBillsForPayment([]);
      setIsLoading(false);

      setIsSuccess({
        alreadyTryToPay: true,
        success: true,
      });
    } catch (error: any) {
      console.log("error", error);
      setIsSuccess({
        alreadyTryToPay: true,
        success: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const totalAmount = bills
    .map((i: any) => i.amount)
    .reduce((a: number, b: number) => a + b, 0);

  const isTopUpBalance = totalAmount > balance;

  const handleTopUpBalance = () => {
    onClose();
    handleShowTopUpModal();
  };

  const handleClose = () => {
    setIsSuccess({
      alreadyTryToPay: false,
      success: false,
    });
    setBillsForPayment([]);
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    return () => {
      setIsSuccess({
        alreadyTryToPay: false,
        success: false,
      });
    };
  }, [showPaymentBillModal]);

  return {
    balance,
    currency,
    t,
    isTopUpBalance,
    isLoading,
    handlePayment,
    handleTopUpBalance,
    totalAmount,
    bills,
    isSuccess,
    handleClose,
  };
};
