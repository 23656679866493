import React from 'react';
import { userStore, userProps } from 'stores/userStore';

const UserContext = React.createContext<userProps>({
  user: {},
  projects: [],
  invoices: [],
  projectInvoices: [],
  projectData: {},
  addUser(){},
  loadUser() {},
  loadInvoices() {},
  loadProjectInvoices() {},
  sendRegistrationCompleteEmail() {},
  changeLanguage() {},
  clearStore() {},
  onBoardingComplete: false,
  enableOnboarding() {},
  pagesToOnboard: {},
  isInnerDataLoading: false,
  setLocaleChanged() {},
  localeChanged: false,
  isProjectError: false,
});

export const UserProvider = ({children}: any) => {

  return (
    <UserContext.Provider value={userStore}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserStore = () => {
  const context = React.useContext(UserContext)
  if (context === null) {
    throw new Error(
      "You have forgotten to wrap your root component with UserProvider"
    );
  }
  return context
}