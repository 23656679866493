import * as React from "react"
import { SVGProps } from "react"

export const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.455 16.422-4.87-4.87a5.575 5.575 0 0 0 1.165-3.427 5.591 5.591 0 0 0-1.648-3.977A5.583 5.583 0 0 0 8.125 2.5a5.591 5.591 0 0 0-3.977 1.648A5.58 5.58 0 0 0 2.5 8.125c0 1.502.587 2.916 1.648 3.977a5.58 5.58 0 0 0 3.977 1.648 5.58 5.58 0 0 0 3.426-1.162l4.869 4.867a.154.154 0 0 0 .217 0l.818-.816a.153.153 0 0 0 0-.217Zm-6.36-5.327a4.178 4.178 0 0 1-2.97 1.23 4.177 4.177 0 0 1-2.97-1.23 4.177 4.177 0 0 1-1.23-2.97c0-1.121.437-2.177 1.23-2.97a4.177 4.177 0 0 1 2.97-1.23c1.121 0 2.177.435 2.97 1.23a4.177 4.177 0 0 1 1.23 2.97 4.174 4.174 0 0 1-1.23 2.97Z"
      fill="#898989"
    />
  </svg>
)
