import * as React from "react"
import { SVGProps } from "react"

export const LeftArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 .474V1.79c0 .09-.033.173-.086.226L2.496 6.334l.001 1.333 4.417 4.318A.321.321 0 0 1 7 12.21v1.316c0 .114-.097.18-.166.114L.504 7.455c-.228-.225-.228-.685 0-.907L6.835.362C6.904.293 7 .36 7 .474Z"
    />
    <path
      d="M2.496 6.334h10.837c.184 0 .333.15.333.333v.667c0 .184-.149.333-.333.333H2.497V6.334Z"
    />
  </svg>
);