import axios from "axios"
import { makeAutoObservable } from "mobx"
import AuthService from "services/auth"
import { API_URL, getRefreshToken, setAccessToken, setRefreshToken } from "services/http"




class AuthStore {
    isAuth = false
    isInit = false
    isLoading = false
    user = {}
    accessToken = ''

    constructor() {
        makeAutoObservable(this)
    }

    setUser(user: any) {
        this.user = user
    }

    setAuth(bool: boolean) {
        this.isAuth = bool
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }

    setInit(bool: boolean) {
        this.isInit = bool
    }

    async loginByTokens(access: string, refresh: string) {
        this.setLoading(true)
        try {
          setAccessToken(access)
          setRefreshToken(refresh)
          return true
        }
        catch {
          return false
        }
        finally {
          this.setLoading(false);
        }
    }

    async login(email: string, password: string, recaptcha: string) {
        this.setLoading(true)
        try {
            const response = await AuthService.login(email, password, recaptcha)
            if (response.data.status) {
                this.setAuth(true)
                setAccessToken(response.data.data.access)
                setRefreshToken(response.data.data.refresh)
            }
            this.setLoading(false)
            return response.data
        } catch (e: any) {
            // console.log(e.response)
        } finally {
            this.setLoading(false)
        }
    }

    async logout() {
        try {
            // await AuthService.logout()
            setAccessToken('')
            setRefreshToken('')
            this.setAuth(false)
        } catch (e: any) {
            console.log(e.response)
        }
    }

    async refreshAuth() {
        if (!getRefreshToken()) return
        this.setLoading(true)
        try {
            const response = await axios.post(`${API_URL}/refresh/`, {refresh: getRefreshToken()})
            if (response.data.access) {
                setAccessToken(response.data.access)
                this.setAuth(true)
                this.accessToken = response.data.access
                return true
            }
            return false
        } catch (e: any) {
            return false
        } finally {
            this.setLoading(false)
            this.setInit(true)
        }
        return
    }

    async verifyAuth() {
        this.setLoading(true)
        try {
            const response = await axios.post(`${API_URL}/verify/`, {token: getRefreshToken()})
            this.setAuth(true)
            return true
        } catch (e: any) {
            this.setAuth(false)
            return false
        } finally {
            this.setLoading(false)
            this.setInit(true)
        }
    }
}

export const authStore = new AuthStore()