import { Request } from "tools/request";

interface Props {
  cartId: Number;
  access: string;
  refresh: string;
}

export async function WithdrawCoupon({cartId, access, refresh}: Props) {

  const result = await Request({
    type: "PUT", 
    url: `${process.env.REACT_APP_API}/api/v1/basket/remove_coupon/`,
    isProtected: true,
    access: access
  });

  return result;
}