import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import classes from './FeedbackModal.module.scss';
import { CrossIcon } from 'components/Icons/CrossIcon';
import { Button } from 'components/Button';
import { Portal } from 'components/Portal';

interface Props {
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

export const FeedbackModal: FC<Props> = ({
  children,
  onClose,
  isOpen,
}) => {
  return (
    <Portal selector={`.layout-container`}>
      <div className={clsx(classes.wrapper, isOpen && classes.open)}>
        <div className={classes.background} />
        <div className={classes.modal}>
          <Button theme="icon-default" onClick={onClose} className={classes.close}>
            <CrossIcon />
          </Button>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
}