import { TimerGradientIcon } from 'components/Icons/TimerGradientIcon';
import classes from './HowItWorks.module.scss';
import { CartGradientIcon } from 'components/Icons/CartGradientIcon';
import { CalendarGradientIcon } from 'components/Icons/CalendarGradientIcon';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation } from 'swiper';

export const HotItWorks = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrap}>
      <span className={classes.title}>{t('How it works')}</span>

      <div className={classes['swiper-wrapper']}>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={16}
          navigation={true}
          modules={[Navigation]}
          className={clsx(classes.swiper)}
          breakpoints={{
            768: {
              spaceBetween: 30,
            },
          }}
        >
          <SwiperSlide className={classes['swiper-slider']}>
            <div className={classes.item}>
              <div className={classes.icon}>
                <TimerGradientIcon />
              </div>
              <div className={classes.subtitle}>{t('Catch the bargain')}</div>
              <p className={classes.text}>
                {t(
                  'Hot Deals are activated at a specific time and available for a limited period to catch it'
                )}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes['swiper-slider']}>
            <div className={classes.item}>
              <div className={classes.icon}>
                <CartGradientIcon />
              </div>
              <div className={classes.subtitle}>{t('Complete your Cart')}</div>
              <p className={classes.text}>
                {t(
                  'Add an item to your Cart and create a Solution before the timer goes off and the offer expires'
                )}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes['swiper-slider']}>
            <div className={classes.item}>
              <div className={classes.icon}>
                <CalendarGradientIcon />
              </div>
              <div className={classes.subtitle}>{t('Sign an agreement')}</div>
              <p className={classes.text}>
                {t(
                  'Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled'
                )}
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
