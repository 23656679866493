import * as React from "react"
import { SVGProps } from "react"

export const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#9C93AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);