import { FC } from "react";
import classes from "./InvoiceCard.module.scss";
import { DownloadIcon } from 'components/Icons/DownloadIcon';
import clsx from 'classnames';
import { useTranslation } from "react-i18next";
import FormatPrice from "../../../components/FormatPriceAndCurrency";
import { getFormattedDate, getFormattedTime } from 'tools/formattedDate';


interface Props {
  invoice: any,
  className?: any,
}

export const InvoiceCardContainer: FC<Props> = ({ invoice, className }) => {
  const { t, i18n } = useTranslation();
  const projectName = invoice.project.name;
  const projectPath = `/projects/${invoice.project.id}`;
  const projectNumber = invoice.project.number;
  const invoiceNumber = invoice.invoice.number;
  const invoiceDescription = invoice.invoice.description;
  const invoiceFilePath = invoice.file;
  const invoiceDate = new Date(invoice.created);
  const serialNumber = invoice.serial_number;
  const paidDate = invoice.paid_date ? new Date(invoice.paid_date) : null;
  const cancelledDate = invoice.cancelled_date ? new Date(invoice.cancelled_date) : null;
  const diffDays = invoice.diff_days;



  const getPaymentDateMessage = () => {
    if (invoice.status === 'PAID' && paidDate) {
      return t("Paid {{ dateString }}", {dateString: getFormattedDate(paidDate)})
    } else if (invoice.status === 'PARTIALLY_PAID' && paidDate){
      return t("Partially paid {{ dateString }}", {dateString: getFormattedDate(paidDate)})
    } else if (invoice.status === 'CANCELLED' && paidDate) {
      return t("Cancelled {{dateString}}", {dateString: cancelledDate ? getFormattedDate(cancelledDate) : "" });
    }
    else if (!diffDays.overdue && diffDays.difference) {
      return t("{{ dateString }} days for payment", {dateString: diffDays.difference})
    } else if (diffDays.overdue) {
      return t("Overdue by {{ dateString }} days", {dateString: diffDays.difference})
    } else if (!diffDays.overdue && !diffDays.difference) {
      return t("Due today")
    }
  }

  const handleDownload = () => {
    if (!invoiceFilePath) return
    const fileUrl = `${invoiceFilePath}`;
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = fileUrl;
    a.download = fileUrl?.split('/').pop() as string;
    a.target = '_blank';
    document.body?.appendChild(a);
    a.click();
    document.body?.removeChild(a);
  };

  const getInvoiceBtnText = (stat : string) => {
    switch (stat) {
      case "PAID": 
        return "Paid"
      case "CANCELLED":
        return "Cancelled"
      default: 
        return "Pay now"
    }
  };

  return (
    <>
      <div className={className}>
        <div className={classes.table__cell}>
          {serialNumber}
        </div>

        <div className={classes.table__cell}>
          {window.location.href.indexOf("finances") != -1 &&
          <a href={process.env.REACT_APP_PUBLIC_URL + projectPath} className={clsx(classes.table__link, classes.table__firstlink)}>
            <span>{projectName}</span>
          </a>
          }
          {window.location.href.indexOf("finances") == -1 &&
          <a className={clsx(classes.table__link, classes.table__firstlink)}>
            <span>{projectName}</span>
          </a>
          }
          <div className={classes.table__text}>ID {projectNumber}</div>
        </div>

        <div className={classes.table__cell}>
          {invoiceFilePath ?
            <a target='_blank' href={invoiceFilePath} className={classes.table__link}>
              <span>{invoiceNumber}</span>
              <DownloadIcon/>
            </a> :
            <span>{invoiceNumber}</span>
          }
          <div className={classes.table__text}>{invoiceDescription}</div>
        </div>

        <div className={classes.table__cell}>
          <div className={classes.table__header}>{getFormattedDate(invoiceDate)}</div>
          <div className={classes.table__text}>{getFormattedTime(invoiceDate)}</div>
        </div>

        <div className={classes.table__cell}>
          <div className={classes.table__header}>
            <FormatPrice currency={invoice.currency?.symbol} price={invoice.total} />
          </div>
          <div className={classes.table__text}>{getPaymentDateMessage()}</div>
        </div>

        <div className={classes.table__cell}>
          {/* Предположительно кнопка будет дизейблиться по какому-то признаку после оплаты. Пока поставлю такое условие */}
          <button
            className={clsx(classes.table__button, invoice.status == "CANCELLED" && classes.table__button_cancelled)}
            disabled={invoice.status === 'PAID'}
            onClick={handleDownload}>
            {t(getInvoiceBtnText(invoice.status))}
          </button>
        </div>
      </div>
    </>
  );
};
