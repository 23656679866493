import { FC, Dispatch, SetStateAction, useRef } from 'react';
import classes from './Total.module.scss';
import { Button } from 'components/Button';
import clsx from 'clsx';
import { Promo } from 'containers/Cart/Promo';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useUserStore } from 'contexts/UserContext';
import FormatPrice from '../../../components/FormatPriceAndCurrency';
import { TooltipContainer } from '../../../components/Tooltip/TooltipContainer';
import i18n from 'i18n/i18n';

interface Props {
  total: string;
  totalWithDiscounts: number;
  count: number;
  createDeals: () => void;
  applyCouponHandler: (coupon: string) => void;
  coupon: string;
  setCoupon: Dispatch<SetStateAction<string>>;
  couponDiscount: number;
  couponDiscountText: string;
  couponError: boolean;
  setCouponError: Dispatch<SetStateAction<boolean>>;
  withdrawCouponHandler: (remove?: boolean) => void;
  selectedItems: any[];
  buttonRef?: any;
  errorMessage: string;
}

export const Total: FC<Props> = ({
  total,
  totalWithDiscounts,
  count,
  createDeals,
  applyCouponHandler,
  coupon,
  setCoupon,
  couponDiscount,
  couponDiscountText,
  couponError,
  setCouponError,
  withdrawCouponHandler,
  selectedItems,
  buttonRef,
  errorMessage,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const realDiscount = Number(~~total) - Number(~~totalWithDiscounts);
  // const discountOnlyWithCoupon = realDiscount == Number(~~couponDiscount) ? true : false

  const checkCurrency = () => {

    return (
      userStore.user.currency.char_code === 'IDR' ||
      userStore.user.currency.char_code === 'INR'
    );
  };

  const formattedPrice = (price?: number | string, withMinus?: boolean) => (
    <FormatPrice
      currency={userStore.user.currency.symbol}
      price={price}
      withMinus={withMinus}
    />
  );


  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.box}>
          <span className={classes.title}>{t('Order details')}</span>
          {selectedItems.map((item, index) => {
            const localeName =
              item.service[`name_${i18n.language}`] ?? item?.service?.name;
            const serviceName =
              localeName > 25 ? localeName?.slice(0, 25) + '...' : localeName;
            return (
              <div className={classes.row}>
                <span className={classes.subtitle} title={item?.service?.name}>
                  {item.service.name.length > 25 ? (
                    <TooltipContainer
                      text={localeName}
                      position={'top'}
                      className={classes.tooltipContainer}
                      classNameTooltip={classes.tooltip}
                    >
                      <div className={classes.tooltipContainer_name}>{serviceName}</div>
                      <span>x{item?.count}</span>
                    </TooltipContainer>
                  ) : (
                    <>
                      {serviceName}
                      <span>x{item?.count}</span>
                    </>
                  )}
                </span>
                <span className={classes.line}></span>
                <span className={classes.cost}>
                  {item.service.is_free
                    ? formattedPrice(0)
                    : formattedPrice(item?.total_cost * item?.count)}
                </span>
              </div>
            );
          })}
          {selectedItems?.length > 0 && (
            <div className={clsx(classes.row, classes.discount)}>
              <span className={classes.subtitle}>{t('Discount')}</span>
              <span className={classes.line}></span>
              <span className={classes.cost}>
                {formattedPrice(realDiscount, !!realDiscount)}
              </span>
            </div>
          )}
          {/* <div className={classes.row}>
            {realDiscount > 0 && !discountOnlyWithCoupon && (
              <>
                <span className={classes.subtitle}>{t("Discount")}</span>
                <span className={clsx(classes.cost, classes.prm)}>
                  –
                  <span className={userStore.user.currency.char_code === "INR" && classes['cost__noto']}>
                    {userStore?.user?.currency?.symbol}
                  </span>
                  {
                    (Math.floor(Number(realDiscount - Number(couponDiscount || 0)))
                    ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </span>
              </>
            )}
          </div> */}
          {/* {realDiscount > 0 && Number(couponDiscount) > 0 && (
            <>
              <div className={classes.row}>
                <span className={classes.appliedCoupon}>{t("Applied promo code")}</span>
              </div>
              <div className={classes.row}>
                <span className={classes.subtitle}>
                  {couponDiscountText}
                </span>
                <span className={clsx(classes.cost, classes.prm)}>
                  –
                  <span className={userStore.user.currency.char_code === "INR" && classes['cost__noto']}>
                    {userStore?.user?.currency?.symbol}
                  </span>
                  {Math.floor(Number(couponDiscount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </span>
              </div>
            </>
          )} */}
        </div>

        <div className={classes.create}>
          {selectedItems?.length > 0 && (
            <Promo
              applyCouponHandler={applyCouponHandler}
              coupon={coupon}
              setCoupon={setCoupon}
              couponError={couponError}
              errorMessage={errorMessage}
              setCouponError={setCouponError}
              withdrawCouponHandler={withdrawCouponHandler}
              couponDiscount={couponDiscount}
              totalWithDiscounts={totalWithDiscounts}
            />
          )}
          <div className={classes.total}>
            <span className={classes.subtitleBig}>{t('Total')}*</span>
            <span
              ref={buttonRef}
              className={clsx(
                classes.costBig,
                checkCurrency() && classes['costBig--small']
              )}
            >
              {formattedPrice(
                Number(total) !== Number(totalWithDiscounts)
                  ? totalWithDiscounts
                  : total
              )}
            </span>
          </div>
        </div>
        <Button
          theme={'primary'}
          size={'big'}
          className={classes.button}
          disabled={!selectedItems.length}
          onClick={createDeals}
        >
          <span>{t('Start projects')}</span>
        </Button>

        <div className={classes.desc}>
          *{' '}
          {t(
            "This price is\u00A0preliminary. VAT is\u00A0not included. You'll get VAT and the total price calculation and terms of\u00A0deal after consultation with our sales manager. To\u00A0know more about the price please check our"
          )}
          &nbsp;
          <NavLink
            to={'/pricing-disclaimer/'}
            state={{ name: 'Cart' }}
            className={classes.disclaimer}
          >
            {t('Pricing Disclaimer')}
          </NavLink>
        </div>
      </div>
    </>
  );
};
