import {FC, useCallback, useEffect, useState} from "react";

import classes from "./CartList.module.scss";
import {CartCard} from "../CartCard";
import {useMarketPlaceStore} from "contexts/marketPlaceContext";
import {observer} from "mobx-react-lite";
import {Checkbox} from "components/Checkbox/Checkbox";
import {useCartStore} from "contexts/CartContext";
import {useTranslation} from "react-i18next";
import {queryParamsHandler} from "../../CardsContainer/utils";

interface Props {
  data: {
    total_cost: string,
    total_cost_with_discounts: string,
    source: {
      id: number;
      name: string;
    }[];
    service: {
      id: string | number;
      available_for: string;
      cost: string;
      cost_type: string;
      cost_with_discounts: string;
      has_active_discounts: boolean;
      if_favorite: boolean;
      is_active: boolean;
      name: string;
      price_per: string;
      product: {
        name: string;
      };
      slug: string;
      isHit: boolean,
      hit: string,
      is_new: boolean,
      is_free: boolean,
      is_favorite: boolean,
      discounts: {
        cost_change_type: string;
        cost_change_value: string;
        name: string;
        deal_end_date: Date
      }[];
    };
  }[],
  cartId: any,
  selectedItems: any[],
}

export const CartList: FC<Props> = observer(({data, cartId, selectedItems}) => {

  const marketPlaceStore = useMarketPlaceStore();
  const cartStore = useCartStore();
  const {t} = useTranslation();
  const [disabled, setDisabled] = useState(cartStore?.isSelected);

  useEffect(() => {
    setDisabled(cartStore?.isSelected)
  }, [cartStore?.isSelected]);

  const handleAllCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(!disabled)
    cartStore.handleAllItemsInSelectedItem(data, event);
  }, [data]);

  const handleRemoveSelected = () => {
    cartStore.handleRemoveSelected();
  };

  useEffect(() => {
    const params = queryParamsHandler({isHot: true})
    marketPlaceStore.loadHotDeals(params)
  }, []);

  return (
    <div className={classes.list}>
      <div className={classes.top}>
        <Checkbox
          className={classes.checkbox}
          onChange={handleAllCheckboxChange}
          checked={selectedItems.length === data.length}
          label={t("Select all")}
          disabled={disabled}
        />

        <button className={classes.top__reset} onClick={handleRemoveSelected}>
          {t("Remove selected")}
        </button>


      </div>
      <div className={classes.cards}>
        {data?.map((item) => (
          <CartCard
            hotDeals={marketPlaceStore.dataTimeHotDeals}
            cartId={cartId}
            item={item}
            key={item.service.id}
            selectedItems={selectedItems}
          />
        ))}
      </div>
    </div>
  );
});