import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TitleAndDescription } from 'components/TitleAndDescription/TitleAndDescription';
import { HotDealsContainer } from './HotDealsContainer/index';
import { routers } from 'constants/routers';
import clsx from 'clsx';
import { PurpleArrowLeftIcon } from 'components/Icons/PurpleArrowLeftIcon';
import { EmptyDeals } from './EmptyDeals/EmptyDeals';
import classes from './HotDeals.module.scss';
import { HotDealsCounter } from './HotDealsCounter';
import { HotItWorks } from './HowItWorks';
import { useTranslation } from 'react-i18next';
import { useMarketPlaceStore } from 'contexts/marketPlaceContext';
import { observer } from 'mobx-react-lite';
import { queryParamsHandler } from '../utils';
import { RUSSIAN_LOCALE } from 'utils';

export const HotDeals: FC = observer(() => {
  const { t } = useTranslation();
  const marketPlaceStore = useMarketPlaceStore();
  const params = queryParamsHandler({ isHot: true });

  useEffect(() => {
    marketPlaceStore.loadHotDeals(params);
  }, []);

  if (!marketPlaceStore.dataTimeHotDeals?.activeDeals) {
    return (
      <div className={classes.wrap}>
        <EmptyDeals />
      </div>
    );
  }

  return (
    <>
      <div className={classes.wrap}>
        <TitleAndDescription pageName='HotDeals' />
        {marketPlaceStore.dataTimeHotDeals?.activeDeals && (
          <div>
            <Link className={classes.back} to={routers.solutions}>
              <PurpleArrowLeftIcon /> {t('Back to Solutions')}
            </Link>
            <div
              className={clsx(
                classes.time,
                !marketPlaceStore.dataTimeHotDeals?.activeDeals &&
                  classes.timeEmpty
              )}
            >
              <div className={classes.deals}>
                <div className={classes.inner}>
                  <span className={classes.title}>{t('Hot Deals')}</span>
                  <span className={classes.subtitle}>
                    {t(
                      'We carefully select the hottest solutions that you can get in time to order at very good discounts and in limited quantities. Catch the tastiest specials!'
                    )}
                  </span>
                  {RUSSIAN_LOCALE && <span className={classes.subtitle}>
                    {t(
                      'Hot Deal_ru'
                    )}
                  </span>}
                </div>
                <div className={classes.timer}>
                  <HotDealsCounter inner />{' '}
                </div>
              </div>
            </div>
            <div className={classes.container}>
              {marketPlaceStore.activeHotDeals.map((item: any) => {
                return (
                  <HotDealsContainer
                    key={item.id}
                    activeDiscount={item}
                    isFeature={marketPlaceStore.dataTimeHotDeals?.featureDeals}
                  />
                );
              })}
            </div>
            <HotItWorks />
          </div>
        )}
      </div>
    </>
  );
});
