import React, {FC} from 'react';

interface FormatPriceProps {
	currency: string;
	price?: number | string;
	withMinus?: boolean;
	text?: string;
}

export const formatPrice = (price: number | string) => {
	const realPrice: number = Number(price) < 1 && Number(price) > 0 ? Math.ceil(Number(price)) : Math.floor(Number(price));
	let parts = realPrice.toString()?.split('.');

	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	return parts[0]
}

const FormatPrice: FC<FormatPriceProps> = ({ currency, price, withMinus, text }) => {

	const hasRupeeSymbol = /₹/.test(currency);

	const renderText = () => {
		const test = hasRupeeSymbol ? { fontFamily: 'Noto, sans-serif' } : { fontFamily: 'inherit' }
		return <span><span style={test}>{withMinus ? `-${currency}` : currency}</span>{text && text}</span>;
	};

	return (
		<>
			{renderText()}
			{price && formatPrice(price)}
		</>
	);
};

export default FormatPrice;