import _ from "lodash";
import { marketPlaceStore } from "../../stores/marketPlaceStore";
import { FilterProps, TopFilterProps } from "./types";
import { SelectData } from "../../components/SelectComponent";
import { userStore } from "stores/userStore";

export const getRandomServices = (allProducts: any, limit: number) => {
  const ids = [];
  const nameCounts: any = {};
  const generatedIds = new Set();

  for (const item of allProducts) {
    const name = item.product.name;
    nameCounts[name] = (nameCounts[name] || 0) + 1;
  }

  while (ids.length < 5 && ids.length < allProducts.length) {
    const randomIndex = _.random(0, allProducts.length - 1);
    const randomId = allProducts[randomIndex].id;
    const productName = allProducts[randomIndex].product.name;

    if (nameCounts[productName] > limit) continue;

    if (generatedIds.has(randomId)) continue;

    ids.push(randomId);
    nameCounts[productName]++;
    generatedIds.add(randomId);
  }

  return ids;
};

export const queryParamsHandler = ({
  page,
  page_size: pageSize,
  isFree,
  isNew,
  isHot,
  isSale,
  isTopUp,
  serviceTypes,
  businessTypes,
  isFavorites,
  budget,
  price_min,
  ids,
  order,
  clearBusinessTypes,
}: any) => {
  //Устанавливаем дефолтный typeof business на странице solutions, НЕ на странице favorites
  if(window.location.pathname !== '/favorites') {
    if (clearBusinessTypes && !businessTypes) {
      businessTypes = [];
    } else {
      // Проверяем выбран ли у пользователя typeof business, если да - тогда ставим его по дефолту в query
      if (userStore?.user?.company_industry) {
        businessTypes = businessTypes || [];
  
        if (businessTypes.length === 0 && !isFavorites && (page && page === 1)) {
          businessTypes.push(userStore.user.company_industry);
        }
  
        if (businessTypes.includes("clear") && !isFavorites) businessTypes = [];
      }
    }
  }
  
  const queryParams = {
    page: page || 1,
    page_size: pageSize || 8,
    is_hot: isHot,
    is_free: isFree,
    is_new: isNew,
    is_sale: isSale,
    is_top_up: isTopUp,
    price_max: budget === -1 ? '0' : budget || 0,
    price_min: price_min || 0,
    is_favorite: isFavorites,
    service_type: serviceTypes?.length && serviceTypes?.join(","),
    business_type: businessTypes?.length && businessTypes?.join(","),
    service_id: ids?.length && ids?.join(","),
    order,
  };

  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};

export const handleFilterServices = (
  { price_max, price_min, businessTypes, serviceTypes }: FilterProps,
  pageSize: number,
  businessTypesChecks: string[] | number[],
  serviceTypesChecks: string[] | number[],
  setProductsToShow: (products: any) => void,
  topFilters?: TopFilterProps,
  isFavorites?: boolean,
  order?: any,
) => {
  const params = queryParamsHandler({
    price_min,
    pageSize,
    businessTypes: businessTypes || businessTypesChecks,
    serviceTypes: serviceTypes || serviceTypesChecks,
    isFavorites,
    order,
    ...topFilters,
  });
  marketPlaceStore.loadServices(params).then(services => {
    setProductsToShow(services);
  });
};

export const options = (t: any): any => [
  { id: 1, name: t("Best sellers") },
  { id: 2, name: `${t("Price")}: ${t("low to high")}` },
  { id: 3, name: `${t("Price")}: ${t("high to low")}` },
  // {id: 4, name: 'Discount: high to low'},
];

// Сделал отдельную под Ru потому, что тут нет слова "Price" в начале
export const optionsRu = (t: any): any => [
  { id: 1, name: t("Best sellers") },
  { id: 2, name: t("low to high") },
  { id: 3, name: t("high to low") },
  // {id: 4, name: 'Discount: high to low'},
];

export const defaultSortParams = (sortSelect: SelectData) => {
  switch (sortSelect?.id) {
    case 1:
      return "views";
    case 2:
      return "cost_lth";
    case 3:
      return "cost_htl";
  }
};
