import * as React from "react"
import { SVGProps } from "react"


export const PurpleArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
  <path d="M19.8321 8H18.2531C18.1458 8 18.0447 8.03906 17.9816 8.10313L12.0004 14.2219L6.01925 8.10313C5.95609 8.03906 5.85503 8 5.74766 8H4.16869C4.03185 8 3.95184 8.11563 4.03185 8.19844L11.4551 15.7937C11.7246 16.0687 12.2762 16.0687 12.5436 15.7937L19.9668 8.19844C20.049 8.11563 19.969 8 19.8321 8Z"/>
</svg>
)
