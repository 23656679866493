import { FC, useState, useMemo, useEffect, useCallback } from "react";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import { useUserStore } from "contexts/UserContext";
import { observer } from "mobx-react-lite";
import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import { Filters } from "./Filters";
import Table from "components/Table";
import { columnsArray, queryParamsHandler, tableData } from "./invoiceData";
import classes from "./Finances.module.scss";
import { Steps } from "intro.js-react";
import "../CardsContainer/IntroTooltip.css";
import "intro.js/introjs.css";
import { financesMessages } from "./hints";
import {
  isOnboardingActive,
  setOnboardingOptions,
} from "../../tools/onboarding";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { LABELS } from "../../constants/onboarding";
import { RUSSIAN_LOCALE } from "utils";
import { Grid, GridItem } from "components/Grid";
import { Button } from "components/Button";
import i18n from "i18n/i18n";
import { StarIcon } from "components/Icons/StarIcon";
import { Modal } from "components/Modal";
import { FormTopUpBalance } from "./FormTopUpBalance";
import { formatBalanceForLocale } from "./formatBalanceForLocale";
import HistoryOperation from "./HistoryOperation";
import Invoices from "./Invoices";
import { useTopUpBalance } from "./useTopUpBalance";
import { FormPaymentBill } from "./FormPaymentBill";
import { toJS } from "mobx";
import { financesStore } from "stores/financesStore";
import { Bill } from "types/finances";
import { ModalMobile } from "components/ModalMobile";

export type Filter = "all" | "paid" | "topay" | "cancelled";

export const FinancesContainer: FC = observer(() => {
  const userStore = useUserStore();
  console.log("userStore", toJS(userStore));
  const { setBillsForPayment } = financesStore;
  const data = userStore.invoices;
  const results = data.results;
  const invoices = results.sub_invoices;
  const allInvoices = results.all_count ?? 0;
  const paidInvoices = results.paid_count ?? 0;
  const toPayInvoices = results.to_pay_count ?? 0;
  const cancelledInvoices = results.cancelled_count ?? 0;
  const isMobile = useWindowWidth().isSmallLaptop;
  const enableHints = isOnboardingActive(userStore?.pagesToOnboard?.finances);
  const currency = userStore.user.currency?.char_code;

  const { t } = useTranslation();
  const [tableActive, setTableActive] = useState(false);
  const [internalInvoices, setInternalInvoices] = useState<any[]>(invoices);
  const itemsPerPage = 10;
  const [filter, setFilter] = useState<Filter>("all");
  const [customPageCount, setCustomPageCount] = useState<number | undefined>(
    undefined,
  );
  const [dataLength, setDataLength] = useState(allInvoices);

  useEffect(() => {
    if (filter === "all") {
      setDataLength(allInvoices);
    } else if (filter === "paid") {
      setDataLength(paidInvoices);
    } else if (filter === "topay") {
      setDataLength(toPayInvoices);
    } else if (filter === "cancelled") {
      setDataLength(cancelledInvoices);
    }
  }, [filter]);

  useEffect(() => {
    if (dataLength) {
      setCustomPageCount(Math.ceil(dataLength / itemsPerPage));
    }
  }, [dataLength]);

  useEffect(() => {
    setInternalInvoices(invoices);
  }, [filter, invoices, results]);

  const getDataByPage = async (currentPage: number | undefined) => {
    const params = queryParamsHandler({
      page: currentPage,
      pageSize: itemsPerPage,
      quickFilter: filter,
    });
    setTableActive(true);
    try {
      await userStore.loadInvoices(params).then((response: any) => {
        setDataLength(response?.count);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setTableActive(false);
    }
  };

  const getDataByFilter = async (filter: any) => {
    const params = queryParamsHandler({
      page: 1,
      pageSize: itemsPerPage,
      quickFilter: filter,
    });
    setTableActive(true);
    try {
      await userStore.loadInvoices(params);
      setFilter(filter);
    } catch (error) {
      console.error(error);
    } finally {
      setTableActive(false);
    }
  };

  const columns = useMemo(() => columnsArray(t), [t]);

  const handleExit = useCallback(() => {
    setOnboardingOptions({ finances: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const balanceValue =
    userStore.user.available_real_balance.find(
      (i: any) => i.currency.char_code === "RUB",
    )?.amount || 0;
  const balanceBonus = userStore.user.available_bonus_balance;
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showPaymentBillModal, setShowPaymentBillModal] = useState(false);

  const handleShowTopUpModal = () => {
    setShowTopUpModal(true);
  };

  const handleCloseTopUpModal = () => {
    setShowTopUpModal(false);
  };

  const handleShowPaymentBillModal = (items: Bill[]) => {
    setBillsForPayment(items);
    setShowPaymentBillModal(true);
  };

  const handleClosePaymentBillModal = () => {
    setShowPaymentBillModal(false);
  };

  const tabs = [
    {
      id: 1,
      label: t("Operations history"),
    },
    {
      id: 2,
      label: t("Invoices"),
    },
  ];

  const [activeTab, setActivTab] = useState(tabs[0].id);
  const { handleTopUpBalance, value, setValue } = useTopUpBalance(
    handleCloseTopUpModal,
  );

  return (
    <>
      <TitleAndDescription pageName="Your invoices" />
      <Steps
        enabled={isMobile && enableHints}
        steps={financesMessages(t)}
        options={{
          tooltipClass: "customTooltip",
          showBullets: false,
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={() => {
          handleExit();
          window.location.href = "/account/personal";
        }}
        onExit={handleExit}
      />

      {RUSSIAN_LOCALE ? (
        <div className={classes.wrapper}>
          <div className={clsx(classes.title, classes.title_ru)}>
            {t("Your invoices")}
          </div>

          <Grid direction="row">
            <GridItem col={{ default: 12, xsup: 3 }} className={classes.left}>
              <ul className={classes.balance}>
                <li className={classes["balance-count"]}>
                  <span className={classes["balance-title"]}>
                    {t("Balance")}
                  </span>
                  <h3 className={classes["balance-value"]}>
                    {formatBalanceForLocale(balanceValue, currency)}
                  </h3>
                </li>

                <li className={classes["balance-bonus"]}>
                  <StarIcon />
                  {balanceBonus}
                  <span className={classes["balance-bonus-tooltip"]}>
                    {t("Use bonus points to pay bills")}
                  </span>
                </li>
              </ul>

              <Button
                theme="primary"
                size="big"
                className={classes["top-up-balance"]}
                onClick={handleShowTopUpModal}
              >
                <span>{t("Top up")}</span>
              </Button>

              <ModalMobile
                title=""
                isOpen={showTopUpModal}
                onClose={handleCloseTopUpModal}
                isBasicHeader={false}
              >
                <FormTopUpBalance
                  handleTopUpBalance={handleTopUpBalance}
                  value={value}
                  setValue={setValue}
                />
              </ModalMobile>
            </GridItem>

            <GridItem col={{ default: 12, xsup: 9 }} className={classes.right}>
              <div className={classes.tabs__wrapper}>
                <div className={classes.tabs}>
                  {tabs.map(tab => {
                    return (
                      <label key={tab.id} className={classes.tab}>
                        <input
                          type="radio"
                          name="project_tab"
                          id="stages"
                          onChange={() => {
                            setActivTab(tab.id);
                          }}
                          checked={activeTab === tab.id}
                        />
                        <div className={classes.tab_label}>{tab.label}</div>
                      </label>
                    );
                  })}
                </div>

                {/* {activeTab === 2 && (
                  <Button
                    theme={"primary"}
                    size="middle"
                    className={classes.desktop__btn}
                    onClick={handleShowPaymentBillModal}
                  >
                    {t("Pay all bills")}
                  </Button>
                )} */}

                <ModalMobile
                  title=""
                  isOpen={showPaymentBillModal}
                  onClose={handleClosePaymentBillModal}
                  isBasicHeader={false}
                >
                  <FormPaymentBill onClose={handleClosePaymentBillModal} handleShowTopUpModal={handleShowTopUpModal} showPaymentBillModal={showPaymentBillModal}/>
                </ModalMobile>
              </div>

              {activeTab === 1 && (
                <HistoryOperation
                  internalInvoices={internalInvoices}
                  handleShowModal={handleShowTopUpModal}
                />
              )}
              {activeTab === 2 && (
                <Invoices
                  internalInvoices={internalInvoices}
                  columns={columns}
                  tableActive={tableActive}
                  customPageCount={customPageCount}
                  getDataByPage={getDataByPage}
                  defaultPageSize={itemsPerPage}
                  filter={filter}
                  handleShowPaymentBillModal={handleShowPaymentBillModal}
                />
              )}
            </GridItem>
          </Grid>
        </div>
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.title}>
            {t("Your invoices")}
            <span>{allInvoices}</span>
          </div>

          <Filters
            all={allInvoices}
            paid={paidInvoices}
            topay={toPayInvoices}
            cancelled={cancelledInvoices}
            filter={filter}
            setFilter={getDataByFilter}
          />

          {!internalInvoices.length && (
            <div className={clsx(classes.empty)}>
              <div className={classes.empty__header}>
                {t("You have no invoices")}
              </div>
              <div className={classes.empty__text}>
                {t("To start a Project please select a Solution")}
              </div>
              <a href="/solutions" className={classes.empty__back}>
                {t("Back to Solutions")}
              </a>
            </div>
          )}

          {internalInvoices.length > 0 && (
            <div className={classes.main}>
              <Table
                data={tableData(internalInvoices, t)}
                columns={columns}
                tableActive={tableActive}
                customPageCount={customPageCount}
                getDataByPage={getDataByPage}
                defaulPageSize={itemsPerPage}
                mobileModeTitle={t("New ones first")}
                isMobile
                filter={filter}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
});
