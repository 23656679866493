import { FC, useEffect, useState, Dispatch, SetStateAction } from "react";

import { CrossIcon } from "components/Icons/CrossIcon";
import { InfoIcon } from "components/Icons/InfoIcon";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { TooltipContainer } from "components/Tooltip/TooltipContainer";
import { useTranslation } from "react-i18next";

import classes from "./Promo.module.scss";
import {useCartStore} from "../../../contexts/CartContext";

interface Props {
  applyCouponHandler: (coupon: string) => void;
  coupon: string;
  setCoupon: Dispatch<SetStateAction<string>>;
  couponError: boolean;
  setCouponError: Dispatch<SetStateAction<boolean>>;
  withdrawCouponHandler: (remove?: boolean) => void;
  errorMessage: string;
  couponDiscount: number;
  totalWithDiscounts: number;
}

export const Promo: FC<Props> = ({
   applyCouponHandler,
   coupon,
   setCoupon,
   couponError,
   setCouponError,
   withdrawCouponHandler,
   errorMessage,
   couponDiscount,
   totalWithDiscounts,
  }) => {
  const [disabled, setDisabled] = useState(false);
  const [couponInput, setCouponInput] = useState("");
  const [focused, setFocused] = useState(false)
  const cartStore = useCartStore();
  const cart = cartStore.cart;
  const discountTotalSumOfCart = cart.coupon_discount?.total_sum_of_cart;

  const {t} = useTranslation();

  useEffect(() => {
    if (coupon) {
      setCouponInput(coupon)
      setDisabled(true)
    }
    else {
      setCouponInput("")
      setDisabled(false)
    }
  }, [coupon, ]);

  useEffect(() => {
    if (couponInput === "" && !errorMessage) {
      setCouponError(false)
    }
  }, [couponInput, errorMessage]);

  useEffect( () => {
    if ((!cart.coupon_discount || discountTotalSumOfCart) && discountTotalSumOfCart - couponDiscount > totalWithDiscounts){
      withdrawCouponHandler(false)
    }
  }, [totalWithDiscounts, cart.coupon_discount])

  return (
    <div className={classes.wrapper}>
      <div className={classes.input}>
        <Input
          placeholder={t("Promo code")}
          onChange={e => {
            setCouponInput(e);
            setCouponError(false);
          }}
          value={couponInput}
          error={couponError}
          disabled={disabled}
          onFocus={() => {
            setFocused(true);
            setCouponError(false);
          }}
        />
        {disabled ? (
          <CrossIcon
            width={15}
            height={14}
            className={classes.btn}
            onClick={() => withdrawCouponHandler(true)}
          />
        ) : !couponError && (!errorMessage || couponInput || focused) ? (
          <Button
            theme={"text"}
            className={classes.btn}
            disabled={!couponInput}
            onClick={() => {
              applyCouponHandler(couponInput);
              setFocused(false);
            }}
          >
            {t("Apply")}
          </Button>
        ) : (
          <TooltipContainer
            text={
              errorMessage
                ? errorMessage
                : t("Sorry, can't apply this promo code")
            }
            customClasses={"kit-ui-block"}
            position={"top"}
            className={classes.error_msg}
          >
            <InfoIcon fill="#F5222D" width="18px" height="18px" />
          </TooltipContainer>
        )}
      </div>
    </div>
  );
};
