import React, { FC } from 'react';
import { NewPasswordContainer } from 'containers/NewPassword';
import '../styles/main.scss';


// todo add localization feature
export const NewPassword: FC = () => {
  return (
    <>
      <NewPasswordContainer />
    </>
  );
}