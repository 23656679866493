import React, { FC } from "react";
import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import "../styles/main.scss";
import { NeedConfirmPageContainer } from "components/NeedConfirmPageContainer";

export const NeedConfirm: FC = () => {
  return (
    <div className={"layout-container--inside"}>
      <TitleAndDescription pageName="needconfirm" />
      <div className="layout-container-inner">
        <NeedConfirmPageContainer />
      </div>
    </div>
  );
};
