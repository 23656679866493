import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import classes from './SearchResults.module.scss';

import { Link } from 'components/Link';
import { PurpleArrowLeftIcon } from 'components/Icons/PurpleArrowLeftIcon';
import { TitleAndDescription } from 'components/TitleAndDescription/TitleAndDescription';
import { TabFilter } from 'components/TabFilter';
import { Button } from 'components/Button';
import { PageLoader } from 'components/PageLoader';
import { ProductCard } from 'containers/CardsContainer/ProductList/ProductCard';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMarketPlaceStore } from 'contexts/marketPlaceContext';
import { ShowNotification } from 'tools/showNotification';
import api from 'services/http';
import Search from 'services/search';
import { ProjectCard } from '../Projects/Card';
import clsx from 'clsx';

export const SearchResultsContainer: FC = observer(({}) => {
  const { t } = useTranslation();
  const marketPlaceStore = useMarketPlaceStore();

  const { hash } = useLocation();
  const parsedHash = queryString.parse(hash);
  const fromPage = (parsedHash?.from as string) || '/solutions';
  const searchTerm = (parsedHash?.search as string) || '';

  const [solutions, setSolutions] = useState<any>(null);
  const [projects, setProjects] = useState<any>(null);
  const [finances, setFinances] = useState<any>(null);
  const [tabs, setTabs] = useState<any>([
    {
      id: 1,
      name_key: 'solutions',
      name: t('Solutions'),
      count: 0,
    },
    {
      id: 2,
      name_key: 'projects',
      name: t('Projects'),
      count: 0,
    },
    {
      id: 3,
      name_key: 'finances',
      name: t('Finances'),
      count: 0,
    },
  ]);
  const [isLoading, setisLoading] = useState(true);

  const [activeTab, setActiveTab] = useState<any>(tabs[0]);

  const handleSeeMore = async (type: string) => {
    try {
      if (type === 'solutions') {
        const response = (await api.get(solutions?.next))?.data;
        setSolutions((prevSolutions: any) => {
          return {
            count: response.count,
            next: response.next,
            previous: response.previous,
            results: [...prevSolutions.results, ...response.results],
          };
        });
      }

      if (type === 'projects') {
        const response = (await api.get(projects?.next))?.data;
        setProjects((prevProjects: any) => {
          return {
            count: response.count,
            next: response.next,
            previous: response.previous,
            results: [...prevProjects.results, ...response.results],
          };
        });
      }
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('An error occurred. Please try again later'),
      });
    }
  };

  const fetchData = async () => {
    setisLoading(true);
    try {
      const searchTerm = (queryString.parse(hash)?.search as string) || '';
      const responseSolutions = await Search.getSearchResults(
        'solutions',
        searchTerm
      );
      const responseProjects = await Search.getSearchResults(
        'projects',
        searchTerm
      );
      setSolutions(responseSolutions?.data);
      setProjects(responseProjects?.data);
      setTabs((prevTabs: any) => {
        const updatedTabs = [...prevTabs];
        const solutionsIndex = updatedTabs.findIndex(
          (tab) => tab.name_key === 'solutions'
        );
        const projectsIndex = updatedTabs.findIndex(
          (tab) => tab.name_key === 'projects'
        );
        updatedTabs[solutionsIndex].count = responseSolutions?.data?.count;
        updatedTabs[projectsIndex].count = responseProjects?.data?.count;
        return updatedTabs;
      });
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: 'An error occurred. Please try again later',
      });
    }
    setisLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [window.location.href]);

  if (isLoading) {
    return <PageLoader />;
  }

  const renderProducts = () => {
    if (
      (activeTab.name_key === 'solutions' && !solutions?.results?.length) ||
      (activeTab.name_key === 'projects' && !projects?.results?.length) ||
      (activeTab.name_key === 'finances' && !finances?.results?.lenth)
    ) {
      return <span className={classes['no-results']}>{t('No results')}</span>;
    } else {
      return (
        <div
          className={clsx(
            classes.list,
            activeTab?.id === 2 && classes.projectList
          )}
        >
          {activeTab.name_key === 'solutions' &&
            solutions?.results.map((service: any) => {
              return (
                <ProductCard
                  key={service?.id}
                  data={service}
                  hotDeals={marketPlaceStore.dataTimeHotDeals}
                />
              );
            })}
          {activeTab.name_key === 'projects' &&
            projects?.results.map((project: any) => {
              return <ProjectCard project={project} key={project?.id} />;
            })}
        </div>
      );
    }
  };

  return (
    <>
      <TitleAndDescription pageName='SearchResults' />
      <div className={classes.wrapper}>
        <Link className={classes.back} href={`${decodeURIComponent(fromPage)}`}>
          <PurpleArrowLeftIcon /> {t('Back')}
        </Link>
        <div className={classes.container}>
          <div className={classes.title}>
            {t('Search results for') + ' '}
            <span className={classes['search-term']}>{`«${searchTerm}»`}</span>
          </div>
          <TabFilter
            tabArray={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className={classes['tab-name']}>{activeTab.name}</div>

          {renderProducts()}
          {((activeTab.id === 1 && solutions?.next) ||
            (activeTab.id === 2 && projects?.next)) &&
            activeTab.count > 3 && (
              <div className={classes.pagination}>
                <Button
                  onClick={() => handleSeeMore(activeTab.name_key)}
                  theme='default'
                  className={classes.button}
                >
                  {t('See more')}
                </Button>
              </div>
            )}
        </div>
      </div>
    </>
  );
});
