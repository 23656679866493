import {FC, useMemo} from "react";
import classes from "./EmptyCart.module.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button";
import { RecommendedService } from "containers/RecommendedService/";
import { useTranslation } from "react-i18next";
import {useMarketPlaceStore} from "../../../contexts/marketPlaceContext";

interface Props {}

export const EmptyCart: FC<Props> = ({}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const marketPlaceStore = useMarketPlaceStore();

  const allProducts = marketPlaceStore.services

  const activeIds = useMemo(() =>
    allProducts?.filter(({ is_active, is_favorite }: any) => is_active && !is_favorite)
      .map(({ id }: any) => id).sort(() => Math.random() - 0.5).slice(0, 5), [allProducts]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.inner}>
          <div className={classes.info}>
            <h2 className={classes.title}>{t("Your cart is empty")}</h2>
            <p className={classes.text}>
              {t("Lots of useful solutions are waiting for you in our catalog")}
            </p>
            <Button theme="primary" className={classes.button} onClick={() => navigate('/solutions')}>
              {t("Back to Solutions")}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.recommended}>
        <RecommendedService title={t("Others usually choose")} ids={activeIds} />
      </div>
    </div>
  );
};
