import React, { FC } from 'react';
import classes from './LinksPolitic.module.scss';
import { useTranslation } from 'react-i18next';
import { defaultLanguage } from 'i18n/i18n';

interface LinksPoliticProps {}

export const LinksPolitic: FC<LinksPoliticProps> = ({}) => {
  const { t, i18n } = useTranslation();

  return (
    <ul className={classes['politic-links']}>
      <li>
        <a
          href={`https://kit.global/${
            i18n.language !== defaultLanguage ? i18n.language + '/' : ''
          }terms/`}
          target='_blank'
          className={classes['politic-links__link']}
        >
          {t('Terms of service')}
        </a>
      </li>
      <li>
        <a
          href={`https://kit.global/${
            i18n.language !== defaultLanguage ? i18n.language + '/' : ''
          }privacy-policy/`}
          target='_blank'
          className={classes['politic-links__link']}
        >
          {t('Privacy Policy (single)')}
        </a>
      </li>
      <li>
        <a
          href={`https://kit.global/${
            i18n.language !== defaultLanguage ? i18n.language + '/' : ''
          }cookie-policy/`}
          target='_blank'
          className={classes['politic-links__link']}
        >
          {t('Cookie Policy')}
        </a>
      </li>
    </ul>
  );
};
