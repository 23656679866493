import { chunk, reverse } from 'lodash';

export function getBillMessage(count: number): string {
    // Функция для определения правильного склонения слова "счет"
    const getDeclension = (n: number): string => {
      n = Math.abs(n) % 100;
      const n1 = n % 10;
      if (n > 10 && n < 20) {
        return 'счетов';
      }
      if (n1 > 1 && n1 < 5) {
        return 'счета';
      }
      if (n1 == 1) {
        return 'счет';
      }
      return 'счетов';
    };
  
    const declension = getDeclension(count);
    return `${declension} ожидают оплаты`;
  }

export const integerToWords = (number: number, t: (key: string) => string): string => {
	if (isValidInteger(number)) {
		return '';
	}
	if (Object.is(number, -0)) return 'negative zero';
	if (number === 0) return 'zero';

	const words = toWords(Math.abs(number), t);

	return number < 0 ? `negative ${words}` : words;
};

const isValidInteger = (int: number): boolean =>
	!Number.isInteger(int) ||
	int > Number.MAX_SAFE_INTEGER ||
	int < Number.MIN_SAFE_INTEGER;

const toWords = (num: number, t: (key: string) => string) =>
	reverseAndChunkDigitsByThree(num)
		.map(digits => mapToWordGroup(digits, 0, [], t))
		.map((group, i,) => addMagnitude(group, i, t))
		.filter(str => str.length)
		.reverse()
		.join(' ');

const mapToWordGroup = (
	[ones = 0, tens = 0, huns = 0]: number[],
	i: number,
	c: number[][],
	t: (key: string) => string
): string => {
	const hundredsPlace =
		huns === 0 ? '' : zeroToNineteenNames(t)[huns] + ' hundred ';
	const tensPlace =
		ones === 0 ? tensNames(t)[tens] : tensNames(t)[tens] && tensNames(t)[tens] + ' ';
	const onesPlace =
		zeroToNineteenNames(t)[parseInt(`${tens}${ones}`, 10)] ||
		zeroToNineteenNames(t)[ones];

	const addAnAnd =
		(hundredsPlace || c.length > 1) && (tensPlace || onesPlace) && i === 0
			? 'and '
			: '';

	return [hundredsPlace, addAnAnd, tensPlace, onesPlace].join('').trim();
};

const addMagnitude = (group: string, i: number, t: (key: string) => string) => {
	const magnitude = magnitudeName(t)[i];
	return magnitude === '' ? group : group ? `${group} ${magnitude}` : '';
};

const zeroToNineteenNames = (t: (key: string) => string) => [
	'',
	t('one'),
	t('two'),
	t('three'),
	t('four'),
	t('five'),
	t('six'),
	t('seven'),
	t('eight'),
	t('nine'),
	t('ten'),
	t('eleven'),
	t('twelve'),
	t('thirteen'),
	t('fourteen'),
	t('fifteen'),
	t('sixteen'),
	t('seventeen'),
	t('eighteen'),
	t('nineteen'),
];

const tensNames = (t: (key: string) => string) => [
	'',
	'',
	t('twenty'),
	t('thirty'),
	t('forty'),
	t('fifty'),
	t('sixty'),
	t('seventy'),
	t('eighty'),
	t('ninety'),
];

const magnitudeName = (t: (key: string) => string) => [
	'',
	t('thousand'),
	t('million'),
	t('billion'),
	t('trillion'),
	t('quadrillion'),
];


const pipe = <T extends any, R>(firstFn: (arg: T) => any, ...fns: any[]) => (
	arg: any
): R => fns.reduce((argument, fn) => fn(argument), firstFn(arg));

const splitNumberIntoDigits = (n: number): number[] =>
	`${n}`.split('').map(v => parseInt(v, 10));

const reverseAndChunkDigitsByThree = pipe<number, number[][]>(
	splitNumberIntoDigits,
	reverse,
	(a: unknown[]) => chunk(a, 3)
);
