import React, {SVGProps} from 'react';

export const EmptyIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
			<svg {...props} width='96' height='50' viewBox="0 0 96 50" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M23.1998 38.6001H59.5998C59.8058 38.6001 60.0065 38.5779 60.1998 38.5357C60.3931 38.5779 60.5939 38.6001 60.7998 38.6001H81.5998C83.1462 38.6001 84.3998 37.3465 84.3998 35.8001C84.3998 34.2537 83.1462 33.0001 81.5998 33.0001H79.1998C77.6534 33.0001 76.3998 31.7465 76.3998 30.2001C76.3998 28.6537 77.6534 27.4001 79.1998 27.4001H86.7998C88.3462 27.4001 89.5998 26.1465 89.5998 24.6001C89.5998 23.0537 88.3462 21.8001 86.7998 21.8001H77.9998C79.5462 21.8001 80.7998 20.5465 80.7998 19.0001C80.7998 17.4537 79.5462 16.2001 77.9998 16.2001H52.3998C53.9462 16.2001 55.1998 14.9465 55.1998 13.4001C55.1998 11.8537 53.9462 10.6001 52.3998 10.6001H29.5998C28.0534 10.6001 26.7998 11.8537 26.7998 13.4001C26.7998 14.9465 28.0534 16.2001 29.5998 16.2001H13.5998C12.0534 16.2001 10.7998 17.4537 10.7998 19.0001C10.7998 20.5465 12.0534 21.8001 13.5998 21.8001H23.5998C25.1462 21.8001 26.3998 23.0537 26.3998 24.6001C26.3998 26.1465 25.1462 27.4001 23.5998 27.4001H7.5998C6.05341 27.4001 4.7998 28.6537 4.7998 30.2001C4.7998 31.7465 6.05341 33.0001 7.5998 33.0001H23.1998C21.6534 33.0001 20.3998 34.2537 20.3998 35.8001C20.3998 37.3465 21.6534 38.6001 23.1998 38.6001ZM88.3998 38.6001C89.9462 38.6001 91.1998 37.3465 91.1998 35.8001C91.1998 34.2537 89.9462 33.0001 88.3998 33.0001C86.8534 33.0001 85.5998 34.2537 85.5998 35.8001C85.5998 37.3465 86.8534 38.6001 88.3998 38.6001Z"
				      fill="#F7F5FF"/>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M43.2475 29.9226C43.2163 30.1439 43.2001 30.37 43.2001 30.6C43.2001 33.2509 45.3491 35.4 48.0001 35.4C50.6511 35.4 52.8001 33.2509 52.8001 30.6C52.8001 30.37 52.7839 30.1439 52.7527 29.9226H64.4001V40.6C64.4001 41.2627 63.8628 41.8 63.2001 41.8H32.8001C32.1374 41.8 31.6001 41.2627 31.6001 40.6V29.9226H43.2475Z"
				      fill="white"/>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M53.2001 29.7999C53.2001 32.6718 50.872 34.9999 48.0001 34.9999C45.1282 34.9999 42.8001 32.6718 42.8001 29.7999C42.8001 29.7069 42.8025 29.6144 42.8074 29.5226H31.6001L35.4243 18.2154C35.5891 17.728 36.0464 17.3999 36.561 17.3999H59.4392C59.9538 17.3999 60.4111 17.728 60.5759 18.2154L64.4001 29.5226H53.1928C53.1977 29.6144 53.2001 29.7069 53.2001 29.7999Z"
				      fill="white"/>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M52.4392 30.1818C52.4392 32.401 50.4518 34.6 48.0002 34.6C45.5486 34.6 43.5612 32.401 43.5612 30.1818C43.5612 30.1099 43.5633 29.6385 43.5674 29.5675H35.2002L38.4647 21.6302C38.6055 21.2535 38.9959 21 39.4351 21H56.5653C57.0045 21 57.3949 21.2535 57.5357 21.6302L60.8002 29.5675H52.433C52.4371 29.6385 52.4392 30.1099 52.4392 30.1818Z"
				      fill="#F7F5FF"/>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M32.1001 29.6048V40.1999C32.1001 40.5865 32.4135 40.8999 32.8001 40.8999H63.2001C63.5867 40.8999 63.9001 40.5865 63.9001 40.1999V29.6048L60.1023 18.3756C60.0061 18.0913 59.7394 17.8999 59.4392 17.8999H36.561C36.2608 17.8999 35.9941 18.0913 35.8979 18.3756L32.1001 29.6048Z"
				      stroke="#8362F3"/>
				<path
					d="M37.2 29.3999C38.7746 29.3999 40.4739 29.3999 42.298 29.3999C43.0485 29.3999 43.0485 29.9273 43.0485 30.1999C43.0485 32.8509 45.247 34.9999 47.9589 34.9999C50.6709 34.9999 52.8693 32.8509 52.8693 30.1999C52.8693 29.9273 52.8693 29.3999 53.6199 29.3999H63.6M34.2295 29.3999H35.2H34.2295Z"
					stroke="#8362F3" strokeLinecap="round" strokeLinejoin="round"/>
				<path
					d="M58.04 8.32119L53.6002 13.3024M47.64 6.6001V13.3024V6.6001ZM37.2002 8.32119L41.64 13.3024L37.2002 8.32119Z"
					stroke="#DDC8FF" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
	);
};