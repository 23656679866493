import React, { FC, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import { Request } from "tools/request";

import classes from "./ChangePassword.module.scss";
import { Type } from "components/Type";
import { Grid, GridItem } from "components/Grid";
import { Input } from "components/Input";
import { EyeIcon } from "components/Icons/EyeIcon";
import { Button } from "components/Button";
import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import clsx from "clsx";
import { CheckIcon } from "components/Icons/CheckIcon";
import { KrestIcon } from "components/Icons/KrestIcon";
import { TooltipContainer } from "components/Tooltip/TooltipContainer";
import { InfoIcon } from "components/Icons/InfoIcon";
import { useTranslation } from "react-i18next";
import { ShowNotification } from "tools/showNotification";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {lettersCheckReg, numbersCheckReg, PASS_MODE, passwordSchema, symbolsCheckReg} from "../validationSchemas";

interface Props {}

interface FormInputs {
  oldPass: string;
  newPass: string;
  newPassConfirmation: string;
}

export const ChangePassword: FC<Props> = () => {
  const {t} = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm<FormInputs>({ resolver: yupResolver(passwordSchema(t('Passwords don’t match'), t)), mode: "onChange" });

  const [numbersCheck, setNumbersCheck] = useState(false);
  const [symbolsCheck, setSymbolsCheck] = useState(false);
  const [letterCheck, setLetterCheck] = useState(false);
  const [showChecks, setShowCheks] = useState(false);
  const [showRepeatCheck, setShowRepeatCheck] = useState(false);
  const [showPassword, setShowPassword] = useState<any>({ oldPass: false, newPass: false, newPassConfirmation: false });
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");

  const [cookies, setCookies] = useCookies(['access', 'refresh']);

  useEffect(() => {
    if (numbersCheckReg.test(password)) {
      setNumbersCheck(true);
    } else {
      setNumbersCheck(false);
    }
    if (lettersCheckReg.test(password)) {
      setLetterCheck(true);
    } else {
      setLetterCheck(false);
    }
    if (symbolsCheckReg.test(password)) {
      setSymbolsCheck(true);
    } else {
      setSymbolsCheck(false);
    }
  }, [password, oldPassword, passwordRepeat]);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const onSubmit = async (data: any) => {
      let formValues = new FormData();

      const { oldPass, newPass } = data;

      formValues.append("old_password", oldPass);
      formValues.append("new_password", newPass);

      try {
        setDisableSubmit(true);
        await Request({
          type: "PUT",
          url: `${process.env.REACT_APP_API}/api/v1/users/`,
          isProtected: true,
          access: cookies.access,
          data: formValues,
        });
        setDisableSubmit(false);
        ShowNotification({type: 'success', children: t('Profile successfully changed')})
      } catch (error: any) {
        setDisableSubmit(false);

        const { old_password, new_password } = error?.response?.data || '';

        if (old_password?.length) {
          setError('oldPass', { type: 'server', message: old_password });
        }
        if (new_password?.length) {
          setError('newPass', { type: 'server', message: new_password });
        }
      }
    }

    const handleShowPassword = (type: string) => {
      setShowPassword({ ...showPassword, [type]: !showPassword[type] })
    }

  const { oldPass, newPass, newPassConfirmation } = showPassword;


  return (
    <>
      <TitleAndDescription pageName="Account/change-password" />
      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={"h2"} className={classes.title}>
            {t("Change password")}
          </Type>
        </div>
        <form
          autoComplete="off"
          className={classes.inner}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.box}>
            <Grid direction={"row"} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes.field}>
                  <div className={classes["input-field"]}>
                    <Controller
                      name="oldPass"
                      control={control}
                      render={({ field }) => (
                        <Input
                          label={t("Old password")}
                          labelPosition={"left"}
                          type={oldPass ? "text" : "password"}
                          placeholder={t("Old password")}
                          value={field.value}
                          error={!!errors.oldPass}
                          onChange={e => {
                            field.onChange(e);
                            setOldPassword(e);
                          }}
                          className={classes.password}
                        />
                      )}
                    />
                    {errors.oldPass && errors.oldPass.type !== "min" ? (
                      <TooltipContainer
                        text={errors.oldPass?.message}
                        customClasses={"kit-ui-block"}
                        position={"top"}
                        className={classes.error}
                      >
                        <InfoIcon fill="#F5222D" width="18px" height="18px" />
                      </TooltipContainer>
                    ) : (
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => handleShowPassword(PASS_MODE.OLD)}
                        className={classes["show-password"]}
                      >
                        <EyeIcon width="18px" height="14px" />
                      </div>
                    )}
                  </div>
                </div>
              </GridItem>
            </Grid>
            <Grid direction={"row"} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes.field}>
                  {showChecks && (
                    <div className={classes["password-check"]}>
                      <div className={classes.title}>
                        {t("Password requirements")}
                      </div>
                      <div className={classes.checks}>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill="#A09CAC" />
                            )}
                            {password.length >= 8 ? (
                              <CheckIcon fill="#00C853" />
                            ) : (
                              password.length > 0 &&
                              password.length < 8 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              password.length >= 8
                                ? classes.correct
                                : password.length > 0 && classes.wrong
                            )}
                          >
                            {t("At least 8 characters long")}
                          </div>
                        </div>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill="#A09CAC" />
                            )}
                            {numbersCheck && password.length >= 1 ? (
                              <CheckIcon fill="#00C853" />
                            ) : (
                              password.length >= 1 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              numbersCheck && password.length >= 1
                                ? classes.correct
                                : password.length >= 1 && classes.wrong
                            )}
                          >
                            {t("Contains numbers")}
                          </div>
                        </div>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill="#A09CAC" />
                            )}
                            {symbolsCheck && password.length >= 1 ? (
                              <CheckIcon fill="#00C853" />
                            ) : (
                              password.length >= 1 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              symbolsCheck &&
                                password.length >= 1 &&
                                classes.correct,
                              !(symbolsCheck && password.length >= 1) &&
                                password.length >= 1 &&
                                classes.wrong
                            )}
                          >
                            {t("Symbols ~!@#$%^&*()_+<>?￥¿¡·॰।")}
                          </div>
                        </div>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill="#A09CAC" />
                            )}
                            {letterCheck && password.length >= 1 ? (
                              <CheckIcon fill="#00C853" />
                            ) : (
                              password.length >= 1 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              letterCheck &&
                                password.length >= 1 &&
                                classes.correct,
                              !(letterCheck && password.length >= 1) &&
                                password.length >= 1 &&
                                classes.wrong
                            )}
                          >
                            {t("Password must contain at least 1 letter")}
                          </div>
                        </div>
                        {(errors?.newPass?.type === "server" ||
                          errors?.newPass?.message ===
                            t("Password must not contain spaces")) && (
                          <div className={classes.check}>
                            <div className={classes.icon}>
                              <KrestIcon />
                            </div>
                            <div className={clsx(classes.name, classes.wrong)}>
                              {errors.newPass?.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <Controller
                    name="newPass"
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t("New password")}
                        labelPosition={"left"}
                        type={newPass ? "text" : "password"}
                        placeholder={t("Enter new password")}
                        value={field.value}
                        onChange={e => {
                          field.onChange(e);
                          setPassword(e);
                        }}
                        error={!!errors.newPass}
                        onBlur={() => {
                          setShowCheks(false);
                        }}
                        onFocus={() => setShowCheks(true)}
                      />
                    )}
                  />
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => handleShowPassword(PASS_MODE.NEW)}
                    onKeyDown={() => {}}
                    className={classes["show-password"]}
                  >
                    <EyeIcon width="18px" height="14px" />
                  </div>
                </div>
              </GridItem>
            </Grid>
            <Grid direction={"row"} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes.field}>
                  {showRepeatCheck && (
                    <div className={classes["repeat-check"]}>
                      <div className={classes.title}>
                        {t("Password checking")}
                      </div>
                      <div className={classes.check}>
                        <div className={classes.icon}>
                          {passwordRepeat.length === 0 && (
                            <CheckIcon fill="#A09CAC" />
                          )}
                          {!errors.newPassConfirmation &&
                          passwordRepeat.length > 1 ? (
                            <CheckIcon fill="#00C853" />
                          ) : (
                            passwordRepeat.length > 0 && <KrestIcon />
                          )}
                        </div>
                        <div
                          className={clsx(
                            classes.name,
                            !errors.newPassConfirmation &&
                              passwordRepeat.length > 1 &&
                              classes.correct,
                            errors.newPassConfirmation &&
                              passwordRepeat.length > 0 &&
                              classes.wrong
                          )}
                        >
                          {errors.newPassConfirmation
                            ? errors.newPassConfirmation?.message
                            : t("Passwords match")}
                        </div>
                      </div>
                    </div>
                  )}
                  <Controller
                    render={({ field }) => (
                      <Input
                        label={t("Repeat password")}
                        labelPosition={"left"}
                        type={newPassConfirmation ? "text" : "password"}
                        placeholder={t("Repeat password")}
                        value={field.value}
                        onChange={e => {
                          setPasswordRepeat(e);
                          field.onChange(e);
                        }}
                        onBlur={() => setShowRepeatCheck(false)}
                        onFocus={() => setShowRepeatCheck(true)}
                      />
                    )}
                    name="newPassConfirmation"
                    control={control}
                  />
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => handleShowPassword(PASS_MODE.CONFIRM)}
                    onKeyDown={() => {}}
                    className={classes["show-password"]}
                  >
                    <EyeIcon width="18px" height="14px" />
                  </div>
                </div>
              </GridItem>
            </Grid>
          </div>
          <div className={classes.submit}>
            <Button
              className={clsx(classes.button, classes["submit-button"])}
              theme="primary"
              size="middle"
              disabled={!isValid || disableSubmit}
            >
              {t("Save password")}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
