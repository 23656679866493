import React, { FC } from "react";

import { TitleAndDescription } from "components/TitleAndDescription/TitleAndDescription";
import { LocalePageContainer } from "containers/Locale";
import "../styles/main.scss";

export const LocalePage: FC = () => {
  return (
    <>
      <TitleAndDescription pageName="localepage" />
      <LocalePageContainer />
    </>
  );
};
