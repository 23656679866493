import { FC } from 'react';

import classes from './Success.module.scss';
import { SuccessIcon } from 'components/Icons/SuccessIcon';
import { Notice } from 'components/Notice';
import { useTranslation } from 'react-i18next';

import { toNbsp } from 'tools/toNbsp';

interface Props {
  email: string;
  resendEmail: () => void;
}

export const Success: FC<Props> = ({ email, resendEmail }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading}>
        <SuccessIcon />
        {toNbsp(
          t('Congratulations! Your account has been successfully created')
        )}
      </div>
      <div className={classes.body}>
        <div className={classes.title}>{t('Email verification')}</div>
        <div className={classes.text}>
          {t('We’ve sent you a verification link to your email address')}{' '}
          <span>{email}.</span>
          <br />
          {t('Please check your inbox. It may take up to 2 minutes to deliver')}
        </div>
        <div className={classes.help}>
          {t('Can’t find it? Check your spam folder or')}
          <span onClick={resendEmail} className={classes.link}>
            {t('resend verification link')}
          </span>
        </div>
      </div>
    </div>
  );
};
