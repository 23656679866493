import React, { FC, useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';
import { Input } from 'components/Input';
import { Button } from 'components/Button';

import logo from 'img/logo.png';
import logo_ru from 'img/logo_ru.svg';
import desktop from 'img/Restore/desktop.png';
import classes from './NewPassword.module.scss';
import { Request } from 'tools/request';
import { CheckIcon } from 'components/Icons/CheckIcon';
import { KrestIcon } from 'components/Icons/KrestIcon';
import { EyeIcon } from 'components/Icons/EyeIcon';
import { useSearchParams } from 'react-router-dom';
import { toNbsp } from 'tools/toNbsp';
import { SuccessIcon } from 'components/Icons/SuccessIcon';
import {Link} from "components/Link";
import { useTranslation } from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {newPasswordSchema, PASS_MODE} from "../Account/validationSchemas";
import {TooltipContainer} from "../../components/Tooltip/TooltipContainer";
import {InfoIcon} from "../../components/Icons/InfoIcon";
import {ShowNotification} from "../../tools/showNotification";
import {MultiselectComponent} from "../../components/MultiselectComponent";
import {languages} from "../../constants/languages";
import { RUSSIAN_LOCALE } from 'utils';
import { TitleAndDescription } from 'components/TitleAndDescription/TitleAndDescription';

interface Props {}

interface FormInputs {
  newPass: string;
  newPassConfirmation: string;
}

const numbersCheckReg = /\d+/;
const symbolsCheckReg = /[~!@#$%^&*()_+<>?￥¿¡·॰।]/;

export const NewPasswordContainer: FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setError,
  } = useForm<FormInputs>({
    resolver: yupResolver(newPasswordSchema(t('Passwords don’t match'))),
    mode: 'onChange',
  });

  const [numbersCheck, setNumbersCheck] = useState(false);
  const [symbolsCheck, setSymbolsCheck] = useState(false);
  const [showChecks, setShowCheks] = useState(false);
  const [showRepeatCheck, setShowRepeatCheck] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [passwordRep, setPasswordRep] = useState<string>('');
  const [token, setToken] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState<any>({
    newPass: false,
    newPassConfirmation: false,
  });

  useEffect(() => {
    const newToken = searchParams.get('token');
    if (newToken) {
      setToken(newToken);
      tokenCheck(newToken);
    }
  }, [searchParams]);

  useEffect(() => {
    if (numbersCheckReg.test(password)) {
      setNumbersCheck(true);
    } else {
      setNumbersCheck(false);
    }
    if (symbolsCheckReg.test(password)) {
      setSymbolsCheck(true);
    } else {
      setSymbolsCheck(false);
    }
  }, [password, passwordRep]);

  const tokenCheck = useCallback(async (t: string) => {
    let formdata = new FormData();

    formdata.append('token', t);

    const result = await Request({
      type: 'POST',
      url: `${process.env.REACT_APP_API}/api/v1/auth/validate-token/`,
      data: formdata,
    });
  }, []);

  const submit = useCallback(
    async (e: any) => {
      let formdata = new FormData();

      formdata.append('token', token);
      formdata.append('new_password', password);
      formdata.append('new_password_repeat', password);

      setDisableSubmit(true);
      try {
        await Request({
          type: 'POST',
          url: `${process.env.REACT_APP_API}/api/v1/auth/new-password/`,
          data: formdata,
        });
        setDisableSubmit(false);
        setStep(1);
      } catch (e: any) {
        if (e.response.status === 400 && e.response.data?.non_field_errors) {
          setError('newPass', {
            type: 'server',
            message: e.response.data?.non_field_errors[0],
          });
        }
        if (e.response.status === 400 && !e.response.data?.is_valid) {
          ShowNotification({
            type: 'error',
            children: t(
              'Your session token has expired. Please request a new restoration link to continue.'
            ),
          });
        }
      }
    },
    [password, setError, t, token]
  );

  const handleShowPassword = (type: string) => {
    setShowPassword({ ...showPassword, [type]: !showPassword[type] });
  };

  const { newPass, newPassConfirmation } = showPassword;

  const changeLanguage = (langObj: any) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has('lng')) {
      newSearchParams.delete('lng');
      setSearchParams(newSearchParams);
    }
    i18n.changeLanguage(langObj.lang_code);
    localStorage.setItem('kitGlobalLng', langObj.lang_code);
  };

  return (
  <>
    <TitleAndDescription pageName="NewPassword" />
    <div className={classes.container}>
      <img src={RUSSIAN_LOCALE ? logo_ru : logo} alt='' className={clsx(classes.logo, RUSSIAN_LOCALE && classes.logo_ru)} />
      <div className={classes.left}>
        <img src={desktop} alt='' className={classes.image} />
      </div>
      <div className={classes.right}>
        {!RUSSIAN_LOCALE &&
          <div className={classes.lang}>
            <MultiselectComponent
              data={languages}
              langSelect={true}
              defaultSelected={
                languages[
                  languages.findIndex((lang) => lang.lang_code === i18n.language)
                ].id
              }
              setMultiselect={changeLanguage}
              className={classes['multiselect-lang']}
            />
          </div>
        }
        {step === 0 && (
          <>
            <div className={classes.title}>{t('Restore your password')}</div>
            <div className={classes.text}>{t('Create a new password')}</div>
            <form className={classes.form} onSubmit={handleSubmit(submit)}>
              <div className={classes['bottom-fields']}>
                <div className={classes['input-field']}>
                  {showChecks && (
                    <div className={classes['password-check']}>
                      <div className={classes.title}>
                        {t('Password requirements')}
                      </div>
                      <div className={classes.checks}>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill='#A09CAC' />
                            )}
                            {password.length >= 8 ? (
                              <CheckIcon fill='#00C853' />
                            ) : (
                              password.length > 0 &&
                              password.length < 8 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              password.length >= 8
                                ? classes.correct
                                : password.length > 0 && classes.wrong
                            )}
                          >
                            {t('At least 8 characters long')}
                          </div>
                        </div>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill='#A09CAC' />
                            )}
                            {numbersCheck && password.length >= 1 ? (
                              <CheckIcon fill='#00C853' />
                            ) : (
                              password.length >= 1 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              numbersCheck && password.length >= 1
                                ? classes.correct
                                : password.length >= 1 && classes.wrong
                            )}
                          >
                            {t('Contains numbers')}
                          </div>
                        </div>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill='#A09CAC' />
                            )}
                            {symbolsCheck && password.length >= 1 ? (
                              <CheckIcon fill='#00C853' />
                            ) : (
                              password.length >= 1 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              symbolsCheck &&
                                password.length >= 1 &&
                                classes.correct,
                              !(symbolsCheck && password.length >= 1) &&
                                password.length >= 1 &&
                                classes.wrong
                            )}
                          >
                            {t('Symbols ~!@#$%^&*()_+<>?￥¿¡·॰।')}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Controller
                    render={({ field }) => (
                      <Input
                        label={t('New password')}
                        labelPosition={'left'}
                        type={newPass ? 'text' : 'password'}
                        placeholder={t('Enter new password')}
                        value={field.value}
                        onChange={(e) => {
                          setPassword(e);
                          field.onChange(e);
                        }}
                        className={classes.password}
                        error={!!errors.newPass}
                        onBlur={() => setShowCheks(false)}
                        onFocus={() => setShowCheks(true)}
                      />
                    )}
                    name='newPass'
                    control={control}
                  />
                  {errors.newPass && errors.newPass.type === 'server' ? (
                    <TooltipContainer
                      text={errors.newPass?.message}
                      customClasses={'kit-ui-block'}
                      position={'top'}
                      className={classes.error}
                    >
                      <InfoIcon fill='#F5222D' width='18px' height='18px' />
                    </TooltipContainer>
                  ) : (
                    <div
                      role='button'
                      tabIndex={-1}
                      onClick={() => handleShowPassword(PASS_MODE.NEW)}
                      className={classes['show-password']}
                    >
                      <EyeIcon width='18px' height='14px' />
                    </div>
                  )}
                </div>
                <div className={classes['input-field']}>
                  {showRepeatCheck && (
                    <div className={classes['repeat-check']}>
                      <div className={classes.title}>
                        {t('Password checking')}
                      </div>
                      <div className={classes.check}>
                        <div className={classes.icon}>
                          {passwordRep.length === 0 && (
                            <CheckIcon fill='#A09CAC' />
                          )}
                          {!errors.newPassConfirmation &&
                          passwordRep.length > 1 ? (
                            <CheckIcon fill='#00C853' />
                          ) : (
                            passwordRep.length > 0 && <KrestIcon />
                          )}
                        </div>
                        <div
                          className={clsx(
                            classes.name,
                            !errors.newPassConfirmation &&
                              passwordRep.length > 1 &&
                              classes.correct,
                            errors.newPassConfirmation &&
                              passwordRep.length > 0 &&
                              classes.wrong
                          )}
                        >
                          {errors.newPassConfirmation
                            ? errors.newPassConfirmation?.message
                            : t('Passwords match')}
                        </div>
                      </div>
                    </div>
                  )}
                  <Controller
                    render={({ field }) => (
                      <Input
                        label={t('Repeat password')}
                        labelPosition={'left'}
                        type={newPassConfirmation ? 'text' : 'password'}
                        placeholder={t('Repeat new password')}
                        value={field.value}
                        onChange={(e) => {
                          setPasswordRep(e);
                          field.onChange(e);
                        }}
                        className={classes.password}
                        error={!!errors.newPassConfirmation}
                        onBlur={() => setShowRepeatCheck(false)}
                        onFocus={() => setShowRepeatCheck(true)}
                      />
                    )}
                    name='newPassConfirmation'
                    control={control}
                  />
                  <div
                    role='button'
                    tabIndex={-1}
                    onClick={() => handleShowPassword(PASS_MODE.CONFIRM)}
                    onKeyDown={() => {}}
                    className={classes['show-password']}
                  >
                    <EyeIcon width='18px' height='14px' />
                  </div>
                </div>
              </div>
              <div className={classes.buttons}>
                <Button
                  className={clsx(classes.button, classes['submit-button'])}
                  theme='primary'
                  disabled={!isValid || disableSubmit}
                >
                  {t('Save password')}
                </Button>
              </div>
            </form>
          </>
        )}
        {step === 1 && (
          <>
            <div className={classes.heading}>
              <SuccessIcon />
              {toNbsp(t('Password has changed'))}
            </div>
            <div className={classes.body}>
              <div className={classes.text}>
                {t('Your new password has been successfully changed, you can ')}
                <span>
                  <Link
                    className={classes.link}
                    href={`${process.env.REACT_APP_LOGIN_URL}`}
                  >
                    {t('sign in')}
                  </Link>
                </span>
                {t('now')}.
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  </>
  );
};
