import { Request } from "tools/request";

interface Props {
  coupon: string;
  cartId: Number;
  access: string;
  refresh: string;
  totalSelectedItemsAmount: number;
}

export async function ApplyCoupon({coupon, cartId, access, refresh, totalSelectedItemsAmount}: Props) {

  let data = new FormData();

  data.append('coupon', coupon);
  data.append('total_selected_items_amount', totalSelectedItemsAmount.toString());

  const result = await Request({
    type: "PUT", 
    url: `${process.env.REACT_APP_API}/api/v1/basket/apply_coupon/`,
    isProtected: true,
    data: data,
    access: access
  });


  return result;
}