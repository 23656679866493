import { AxiosResponse } from "axios";
import api from "./http";


export default class AuthService {
    static async login(email: string, password: string, recaptcha: string): Promise<AxiosResponse> {
        return api.post('/login/', { email, password, 'g-recaptcha-response': recaptcha }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }

    static async logout(): Promise<AxiosResponse> {
        return api.post('/logout/')
    }
}