import * as React from "react"
import { SVGProps } from "react"

export const CheckBoxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.913 4.035a.525.525 0 0 1 .412.2l-.412-.2Zm0 0h-.82a.243.243 0 0 0-.19.394l3.21 4.067c.21.266.613.265.824 0l5.723-7.252A.243.243 0 0 0 10.47.85h-.82a.523.523 0 0 0-.411.2L4.523 7.023m-2.61-2.987 2.61 2.987m0 0L2.325 4.235l2.199 2.787Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.3}
    />
  </svg>
);