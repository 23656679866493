import { Request } from 'tools/request';

interface Props {
  access: string;
  refresh: string;
  workspaceId: string;
}

export async function GetDashboardParams({access, refresh, workspaceId}: Props) {
  const result = await Request({
    type: 'GET',
    url: `${process.env.REACT_APP_API}/api/v1/dashboards/params/${workspaceId}/`,
    isProtected: true
  });

  return result;
}
