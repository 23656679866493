import { FC, useCallback, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import axios from "axios";

import { Request } from 'tools/request';
import classes from "./CardManager.module.scss";
import { Button } from "components/Button";
import { FeedbackModal } from "components/FeedbackModal";
import { useDisableScrolling } from "hooks/useDisableScrolling";
import { Textarea } from "components/Input/Textarea";
import Service from "services/service";
import { renderToString } from 'react-dom/server';
import { useWindowWidth } from "hooks/useWindowWidth";
import { Logo } from "components/Logo";
import { CrossIcon } from "components/Icons/CrossIcon";
import { useTranslation } from "react-i18next";
import { RUSSIAN_LOCALE } from "utils";
import defaultImageBlue from "img/AvatarBlue.svg"
import defaultImageRed from "img/AvatarRed.svg"

interface Manager {
  name: string;
  src: string;
  alt: string;
  serviceId: string;
}

export const CardManager: FC<Manager> = ({ name, src, alt, serviceId }) => {
  const [openModal, setOpenModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bigQuestionLength, setBigQuestionLength] = useState(false);
  const [questionErrorMessage, setQuestionErrorMessage] = useState("");
  const [bigSourceLength, setBigSourceLength] = useState(false);
  const [sourceErrorMessage, setSourceErrorMessage] = useState("");
  const [questionValue, setQuestionValue] = useState("");
  const [sourceValue, setSourceValue] = useState("");
  const [success, setSuccess] = useState(false);
  const [confirmFeedback, setConfirmFeedback] = useState(false);
  const { width } = useWindowWidth();
  const {t} = useTranslation();

  useEffect(() => {
    if (questionValue !== "" && !!questionValue.trim()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [questionValue, sourceValue]);

  const closeModal = useCallback(() => {
    setOpenModal(false)
    setConfirmFeedback(false)
    setQuestionValue("")
    setSourceValue("")
    setBigQuestionLength(false)
    setBigSourceLength(false)
    setSourceErrorMessage("")
    setQuestionErrorMessage("")
  }, [])

  const sendRequest = useCallback(async () => {
    if (disabled) {
      return;
    }

    setDisabled(true);
    setBigQuestionLength(false);
    setQuestionErrorMessage("");
    setBigSourceLength(false);
    setSourceErrorMessage("")

    try {
      const response = await Service.consultRequest(serviceId, sourceValue, questionValue);
      if (response?.status == 201) {
        setSuccess(true);
        setConfirmFeedback(true);

        setQuestionValue('')
        setSourceValue('')
      }
    } catch (e: any) {
      if (e.response?.status == 400) {
        if (e.response?.data.question) {
          setBigQuestionLength(true);
          setQuestionErrorMessage(e.response?.data.question[0]);
        }
        if (e.response?.data.project_description) {
          setBigSourceLength(true);
          setSourceErrorMessage(e.response?.data.project_description[0])
        }
      }
    } finally {
      setDisabled(false);
    }



  }, [questionValue, sourceValue, disabled]);

  useDisableScrolling(openModal);
  const defaultImage = RUSSIAN_LOCALE ? defaultImageRed : defaultImageBlue;
  const imageSrc = src || defaultImage;

  return (
    <>
      <div className={classes.container}>
        <img className={classes.image} src={imageSrc} alt={alt || 'default avatar'} />
        <h4 className={classes.title}>{t("Hi! My name is {{name}}", {name: name})}</h4>
        <p className={classes.text}>{t("I am ready to help you to make the right choice. Feel free to ask me any questions about this solution")}</p>
        <Button
          onClick={(e) => {
            e.preventDefault()
            setOpenModal(true)
          }}
          theme="text"
          className={classes.button}
        >{t("Get in touch")}</Button>
      </div>
      <FeedbackModal isOpen={openModal} onClose={closeModal}>
        <div className={classes.modal}>
          {
            !confirmFeedback ?
              <>
                <div className={classes.header}>
                  <div
                    className={classes.wrapper}
                  >
                    <Logo />
                    <div role="button" onClick={closeModal} className={classes.close}>
                      <CrossIcon />
                    </div>
                  </div>
                </div>
                <h4 className={classes.modalTitle}>{t("Request for a consultation")}</h4>
                <div className={classes.modalBlock}>
                  <div className={classes.textareaCustomBig}>
                    <Textarea
                      label={t('Your question')}
                      value={questionValue}
                      error={questionErrorMessage}
                      placeholder={t('What would you like to know about this solution')}
                      onChange={setQuestionValue}
                      maxLength={700}
                    />
                    {bigQuestionLength ? (
                      <p className={classes.error}>{questionErrorMessage}</p>
                    ) : null}
                  </div>
                  <div className={classes.textareaCustomNormal}>
                    <Textarea
                      label={t('Source (optional)')}
                      value={sourceValue}
                      error={sourceErrorMessage}
                      placeholder={t('Link or a source description')}
                      onChange={setSourceValue}
                      maxLength={100}
                    />
                    {bigSourceLength ? (
                      <p className={classes.error}>{sourceErrorMessage}</p>
                    ) : null}
                  </div>
                  <p className={classes.policy}>
                    {t("By applying this form You further agree on the terms of our")}
                    {" "}
                    <a className={classes['policy__link']} href="https://kit.global/privacy-policy/" target="_blank">{t("Privacy Policy")}</a>
                  </p>
                </div>
                <Button className={classes.modalButton} theme="text" disabled={disabled} onClick={sendRequest}>{t("Send request")}</Button>
              </> :
              <div className={classes.modalFeedbackBlock}>
                <div className={classes.modalFeedbackImage}></div>
                <h4 className={classes.modalFeedbackTitle}>{t("Request sent")}</h4>
                <p className={classes.modalFeedbackText}>{t("Thank you, we’ll go back to you with more information within 2 working days")}</p>
                <Button theme="primary" className={classes.modalFeedbackButton} onClick={closeModal}>{t("Done")}</Button>
              </div>
          }
        </div>
      </FeedbackModal>
    </>
  );
};
