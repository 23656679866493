import * as React from "react"
import { SVGProps } from "react"
import { RUSSIAN_LOCALE } from "utils";


export const BasketGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M19.2727 6.5H16.1818V6.14C16.1818 3.854 14.3091 2 12 2C9.69091 2 7.81818 3.854 7.81818 6.14V6.5H4.72727C4.325 6.5 4 6.82175 4 7.22V19.28C4 19.6782 4.325 20 4.72727 20H19.2727C19.675 20 20 19.6782 20 19.28V7.22C20 6.82175 19.675 6.5 19.2727 6.5ZM9.45455 6.14C9.45455 4.74725 10.5932 3.62 12 3.62C13.4068 3.62 14.5455 4.74725 14.5455 6.14V6.5H9.45455V6.14ZM18.3636 18.38H5.63636V8.12H7.81818V10.1C7.81818 10.199 7.9 10.28 8 10.28H9.27273C9.37273 10.28 9.45455 10.199 9.45455 10.1V8.12H14.5455V10.1C14.5455 10.199 14.6273 10.28 14.7273 10.28H16C16.1 10.28 16.1818 10.199 16.1818 10.1V8.12H18.3636V18.38Z" fill="url(#paint0_linear_5600_41440)" />
    <defs>
      {RUSSIAN_LOCALE &&
        <linearGradient id="paint0_linear_5600_41440" x1="7.89744" y1="2" x2="18.8534" y2="18.8803" gradientUnits="userSpaceOnUse">
          <stop stop-color="#E47267" />
          <stop offset="1" stop-color="#EB5945" />
        </linearGradient>
      }
      {!RUSSIAN_LOCALE &&
        <linearGradient id="paint0_linear_5600_41440" x1="0.560002" y1="2.32" x2="18.88" y2="23.84" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6E3CFF" />
          <stop offset="1" stop-color="#4BA5FF" />
        </linearGradient>
      }
    </defs>
  </svg>
);