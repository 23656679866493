import React from 'react';
import { cartStore, cartProps } from 'stores/cartStore';

const CartContext = React.createContext<any>(null);

export const CartProvider = ({children}: any) => {
  return (
    <CartContext.Provider value={cartStore}>
      {children}
    </CartContext.Provider>
  );
}

export const useCartStore = () => {
  const context = React.useContext(CartContext);
  if (context === null) {
    throw new Error(
      "You have forgotten to wrap your root component with CartProvider"
    );
  }
  return context
}