import React, { FC } from 'react';
import { CardsContainer } from '../containers/CardsContainer';
import '../styles/main.scss';
import { useTranslation } from "react-i18next";

// todo add localization feature
export const MarketPlace: FC = () => {

  const {t} = useTranslation();

  return <CardsContainer title={t("Solutions")} />
}